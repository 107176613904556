import styled, { css } from 'styled-components';
import { colors } from '../../assets/styles';

export const TogglerWrapper = styled.div`
  ${({ width }) => css`
    ${width &&
      css`
        width: ${width}px;
      `};
  `}
`;

export const Label = styled.label`
  position: relative;
  display: inline-block;
  width: 32px;
  height: 16px;
  outline: none;
`;

export const LabelText = styled.div`
  font-size: 14px;
  line-height: 16px;
  margin-left: 5px;
  color: ${colors.grey6}
  left: 34px;
  position: absolute;
`;

export const Slider = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  :before {
    position: absolute;
    content: '';
    height: 12px;
    width: 12px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

export const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;

  &:checked + ${Slider} {
    background-color: ${colors.theme4};
  }
  &:focus + ${Slider} {
    box-shadow: 0 0 1px ${colors.theme4};
  }
  &:checked + ${Slider}:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }
`;
