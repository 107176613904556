import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { colors } from '../../../assets/styles';
import { INTERN, JUNIOR, MIDDLE, SENIOR } from '../../../constants';
import { FUL_HD, qHD, HD, nHD, CIF } from '../constants';

const getWidth = (sizeScreen, type) => {
  if (type === 'wrapper') {
    switch (sizeScreen) {
      case FUL_HD:
      case HD:
        return '100%';
      case qHD:
      case nHD:
      case CIF:
        return '1100px';

      default:
        return '800px';
    }
  }

  if (type === 'overflow') {
    switch (sizeScreen) {
      case FUL_HD:
        return '100%';

      default:
        return '100vw';
    }
  }
  return '';
};

export const UserTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  box-shadow: 11px 35px 50px 0 ${colors.grey11};
  padding: 28px 32px;
  background: white;
  margin-bottom: 78px;
  height: fit-content;
  ${({ sizeScreen }) => css`
    width: ${getWidth(sizeScreen, 'wrapper')};
    min-width: ${getWidth(sizeScreen, 'wrapper')};
    margin-left: ${[FUL_HD].includes(sizeScreen) ? '34px' : '0px'};
  `}
  margin: 0 auto;
`;

export const TablesWrapper = styled.div`
  overflow-x: auto;
  width: ${({ sizeScreen }) => getWidth(sizeScreen, 'overflow')};
  padding: 0 34px;
  box-sizing: border-box;
`;

export const UsersBody = styled.div`
  margin-top: 10px;
  ${({ isBur }) =>
    isBur &&
    css`
      filter: blur(2px);
    `}
`;

export const Loading = styled.div`
  position: absolute;
  top: calc(50% - ${({ isMin }) => (isMin ? 25 : 200)}px);
  left: calc(50% - ${({ isMin }) => (isMin ? 25 : 200)}px);
`;

export const UserWrapper = styled.div`
  border-bottom: 1px solid ${colors.grey11};
  padding: 32px 0px;
  display: flex;
  align-items: center;
`;

export const HeaderWrapper = styled.div`
  border-bottom: 1px solid ${colors.grey11};
  padding-bottom: 18px;
  display: flex;
  align-items: center;
`;

export const FieldBlock = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  ${({ maxWidth, positionType, paddingL, isSort }) => css`
  ${isSort &&
    css`
      cursor: pointer;
    `}
    ${paddingL &&
      css`
        padding-left: ${paddingL}px;
      `}
    ${maxWidth &&
      css`
        max-width: ${maxWidth || 0}px;
        min-width: ${maxWidth || 0}px;
      `};}
    ${css`
      justify-content: ${positionType || 'center'};
    `};}
  `};
`;

export const PaginationWrapper = styled.div`
  display: flex;
  margin-top: 37px;
  justify-content: center;
`;
export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  & span {
    color: ${colors.grey11};
    font-size: 16px;
    font-family: OpenSansSemibold;
  }
  & p {
    margin-left: 20px;
    border-radius: 9px;
    padding: 0 10px;
    height: 18px;
    line-height: 18px;
    color: ${colors.grey12};
    font-size: 16px;
    font-family: OpenSansSemibold;
    background: ${colors.blue5};
  }
`;

export const UserNameBlock = styled(Link)`
  display: flex;
  flex: 1;
  align-items: center;
  text-decoration: none;
  & .user_name {
    display: inline-block;
    margin-left: 26px;
    color: ${colors.blue7};
    font-size: 18px;
    font-family: OpenSansSemibold;
  }
  & img {
    width: 88px;
    height: 88px;
    border-radius: 32px;
    object-fit: ${({ isContain }) => (isContain ? 'contain' : 'cover')};
  }
  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth || 0}px;
      min-width: ${maxWidth || 0}px;
    `}
`;
export const getStyle = ({ level }) => {
  switch (level) {
    case INTERN:
      return css`
        background-color: ${colors.green1};
      `;
    case JUNIOR:
      return css`
        background-color: ${colors.yellow};
      `;
    case MIDDLE:
      return css`
        background-color: ${colors.pink2};
      `;
    case SENIOR:
      return css`
        background-color: ${colors.blue6};
      `;
    default:
      return css``;
  }
};

export const LevelEllipse = styled.div`
  width: 108px;
  height: 45px;
  display: flex;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  font-family: OpenSansSemibold;
  color: ${colors.blue7};
  ${getStyle}
`;

export const CourseBlock = styled.div`
  width: 180px;
  max-width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth || 0}px;
      min-width: ${maxWidth || 0}px;
    `};
`;

export const OptionImg = styled.img`
  width: 45px;
  height: 45px;
  border-radius: 10px;
  margin: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`;

export const HeaderText = styled.span`
  display: inline-block;
  color: ${colors.blue8};
  font-size: 16px;
`;
export const OrderByArrow = styled.div`
  position: relative;
  height: 13px;
  ${({ orderBy }) => css`
    &::after,
    &::before {
      content: '';
      position: absolute;
      left: 10px;
      border: 5px solid transparent;
    }
    &::after {
      bottom: -10px;
      border-top: 5px solid ${colors[orderBy === 'desc' ? 'blue5' : 'blue8']};
    }
    &::before {
      bottom: 2px;
      border-bottom: 5px solid ${colors[orderBy === 'asc' ? 'blue5' : 'blue8']};
    }
  `}
`;

export const TextField = styled.div`
  font-family: OpenSansSemibold;
  color: ${colors.blue7};
  font-size: 16px;
`;

export const TableWrapper = styled.div`
  position: relative;
`;

export const NotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0px;
`;
export const NotFoundTitle = styled.div`
  margin-top: 30px;
  font-size: 50px;
  line-height: 76px;
  color: ${colors.blue7};
  font-weight: bold;
`;
export const NotFoundDescription = styled.div`
  color: ${colors.blue8};
  font-size: 30px;
  line-height: 46px;
  font-weight: bold;
`;
