/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
// Core
import React, { useState, useRef, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { graphql } from '@apollo/react-hoc';
import PropTypes from 'prop-types';

// Components
import Checkbox from '../../../components/Checkbox';

// Other
import compose from '../../../utils/compose';
import COURSES_QUERY from '../../../gql/query/coursesRecruiting';
import { FUL_HD, nHD, qHD, HD } from '../constants';

// Styles and Assets
import {
  OptionsWrapper,
  BoxIcon,
  FilterWrapper,
  FilterItemWrapper,
  TitleText,
  Label,
  OptionText,
  OptionImg,
  OptionWrapper,
  ArrowIcon,
  OptionsBlock,
  FilterBlock,
} from './styles';

import html from '../../../assets/images/courses/logo-html-css.png';
import java from '../../../assets/images/courses/logo-java-c.png';
import node from '../../../assets/images/courses/logo-Node-js.png';
import qa from '../../../assets/images/courses/logo-qa.png';
import react from '../../../assets/images/courses/logo-react-n.png';
import cSharp from '../../../assets/images/courses/st_logo-c-sharp.png';
import js from '../../../assets/images/courses/logo-js.png';
import user from '../../../assets/images/recruiting/icon-user-graduate.svg';
import code from '../../../assets/images/recruiting/icon-code.svg';
import iconArrow from '../../../assets/images/arrow/arrow-down.png';
import time from '../../../assets/images/recruiting/icon-ios-time.svg';

// LEVEL
import { INTERN, JUNIOR, MIDDLE, SENIOR } from '../../../constants';

export const coursesImage = {
  1: js,
  2: react,
  3: node,
  34: java,
  42: html,
  46: qa,
  50: cSharp,
};

export const optionsCourse = [
  { value: 1, title: 'js' },
  { value: 3, title: 'node' },
  { value: 42, title: 'html' },
  { value: 2, title: 'react' },
  { value: 50, title: 'cSharp' },
  { value: 34, title: 'java' },
  { value: 46, title: 'qa' },
];

export const optionsLevel = [
  { value: INTERN, title: 'Intern' },
  { value: JUNIOR, title: 'Junior' },
  { value: MIDDLE, title: 'Middle' },
  { value: SENIOR, title: 'Senior' },
];

export const optionsActivity = [
  { value: 1, title: 'today' },
  { value: 7, title: 'lessTanWeek' },
  { value: 31, title: 'lessThanMonth' },
  { value: 186, title: 'lessSixMonths' },
  { value: 360, title: 'lessThanYear' },
];

const Option = ({ values = [], isMultiSelect, value, title, handlerSelect }) => {
  const currentValue = values?.find(item => item === value);

  return (
    <OptionWrapper
      isMultiSelect={isMultiSelect}
      checked={currentValue}
      onClick={() => handlerSelect(!currentValue)}
    >
      {isMultiSelect && <Checkbox currentTheme="CHECKBOX_GREEN" checked={currentValue} />}
      <span>{title}</span>
    </OptionWrapper>
  );
};

Option.propTypes = {
  values: PropTypes.array,
  isMultiSelect: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
  handlerSelect: PropTypes.func,
};

const Labels = ({ values, images, options, placeholder }) => {
  if (!values?.length) {
    return <OptionText>{placeholder}</OptionText>;
  }

  if (Object.values(images)?.length) {
    return values
      .filter((_, index) => index <= 2)
      .map((value, index) => <OptionImg isFirst={index === 0} src={images[value]} />);
  }

  return values
    .filter((_, index) => index <= 1)
    .map((value, index, array) => {
      const currentValue = options.find(option => option?.value === value);

      return (
        currentValue && (
          <OptionText>
            {`${currentValue?.title}${array?.length !== index + 1 ? ',' : ''}`}
          </OptionText>
        )
      );
    });
};

Labels.propTypes = {
  values: PropTypes.array,
  images: PropTypes.object,
  options: PropTypes.array,
  placeholder: PropTypes.string,
};

const FilterItem = ({
  icon,
  images = {},
  label,
  values = [],
  placeholder,
  options = [],
  onChange,
  isMultiSelect,
  withoutBorder,
  sizeScreen,
  isCenterFilter,
  setHeight,
  height,
}) => {
  const [filterOpen, setOpen] = useState(false);

  const optionsRef = useRef(null);
  const handleOpen = () => {
    if (!filterOpen && optionsRef?.current) {
      const { clientHeight } = optionsRef?.current;
      setHeight(clientHeight);
    } else {
      setHeight(0);
    }
    setOpen(!filterOpen);
  };

  useEffect(() => {
    if (height) {
      setOpen(true);
    }
  }, [optionsRef, height]);

  const handlerSelect = value => checked => {
    if (values?.some(item => item === value)) {
      if (!isMultiSelect) {
        onChange([]);
        return;
      }
      if (!checked) {
        onChange(values?.filter(item => value !== item));
        return;
      }
    }

    if (!values?.length || !isMultiSelect) {
      onChange([value]);
      return;
    }

    if (isMultiSelect) {
      onChange([...values, value]);
    }
  };

  let showArrow = true;
  if ([qHD, HD].includes(sizeScreen) && !isCenterFilter) {
    showArrow = false;
  }

  return (
    <FilterItemWrapper sizeScreen={sizeScreen} withoutBorder={withoutBorder}>
      <BoxIcon onClick={handleOpen} sizeScreen={sizeScreen}>
        <img src={icon} title="" alt="" />
      </BoxIcon>
      <OptionsWrapper>
        <Label sizeScreen={sizeScreen} onClick={handleOpen}>
          {label}
        </Label>
        <div onClick={handleOpen}>
          <Labels values={values} options={options} images={images} placeholder={placeholder} />
        </div>
        <OptionsBlock height={height}>
          <OptionsWrapper filterOpen={filterOpen} height={height} ref={optionsRef}>
            {options.map(option => (
              <Option
                key={`${option?.value}--${option?.label}`}
                isMultiSelect={isMultiSelect}
                values={values}
                handlerSelect={handlerSelect(option.value)}
                {...option}
              />
            ))}
          </OptionsWrapper>
        </OptionsBlock>
      </OptionsWrapper>
      {showArrow && (
        <ArrowIcon
          sizeScreen={sizeScreen}
          isUp={!filterOpen}
          onClick={handleOpen}
          src={iconArrow}
        />
      )}
    </FilterItemWrapper>
  );
};

FilterItem.propTypes = {
  icon: PropTypes.string,
  images: PropTypes.object,
  label: PropTypes.string,
  values: PropTypes.array,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  isMultiSelect: PropTypes.bool,
  withoutBorder: PropTypes.bool,
  sizeScreen: PropTypes.string,
  isCenterFilter: PropTypes.bool,
  setHeight: PropTypes.func,
  height: PropTypes.number,
};

const Filters = ({ t, sizeScreen, courses, loading, locale, filters, changeFilters }) => {
  const [{ heightSelectActivity, heightSelectCourse, heightSelectLevel }, setHeight] = useState({
    heightSelectActivity: 0,
    heightSelectCourse: 0,
    heightSelectLevel: 0,
  });

  const setHeightFilter = key => value => {
    if ([qHD, HD].includes(sizeScreen)) {
      const maxHeight = value
        ? [heightSelectActivity, heightSelectCourse, heightSelectLevel, value].reduce(
            (result, item) => (item > result ? item : result)
          )
        : 0;
      setHeight({
        heightSelectActivity: maxHeight,
        heightSelectCourse: maxHeight,
        heightSelectLevel: maxHeight,
        [key]: value,
      });
    } else {
      setHeight({
        heightSelectActivity,
        heightSelectCourse,
        heightSelectLevel,
        [key]: value,
      });
    }
  };

  const handleChange = key => values => {
    changeFilters({
      ...filters,
      [key]: values,
    });
  };

  let optionsCoursePrepared = optionsCourse;
  if (!loading) {
    optionsCoursePrepared = courses.map(({ title, id }) => ({ value: id, title: title[locale] }));
  }

  return (
    <FilterBlock>
      <FilterWrapper sizeScreen={sizeScreen}>
        <TitleText>{t('Recruiting/searchParams')}</TitleText>
        <FilterItem
          sizeScreen={sizeScreen}
          withoutBorder={![FUL_HD, nHD].includes(sizeScreen)}
          icon={user}
          placeholder={t('Recruiting/selectLevel')}
          values={filters?.selectedLevel || []}
          onChange={handleChange('selectedLevel')}
          options={optionsLevel}
          label={t('TopQuestion/level')}
          isMultiSelect
          height={heightSelectLevel}
          setHeight={setHeightFilter('heightSelectLevel')}
        />
        <FilterItem
          isCenterFilter
          sizeScreen={sizeScreen}
          withoutBorder={![FUL_HD, nHD].includes(sizeScreen)}
          icon={code}
          placeholder={t('Recruiting/selectCourse')}
          values={filters?.selectedCourse || []}
          onChange={handleChange('selectedCourse')}
          options={optionsCoursePrepared}
          label={t('Recruiting/language')}
          images={coursesImage}
          isMultiSelect
          height={heightSelectCourse}
          setHeight={setHeightFilter('heightSelectCourse')}
        />
        <FilterItem
          sizeScreen={sizeScreen}
          withoutBorder
          icon={time}
          placeholder={t('Recruiting/selectActivity')}
          values={filters?.selectedActivity || []}
          onChange={handleChange('selectedActivity')}
          options={optionsActivity.map(item => ({
            ...item,
            title: t(`Recruiting/${item?.title}`),
          }))}
          label={t('Recruiting/lastActivity')}
          height={heightSelectActivity}
          setHeight={setHeightFilter('heightSelectActivity')}
        />
      </FilterWrapper>
    </FilterBlock>
  );
};

Filters.propTypes = {
  loading: PropTypes.bool,
  t: PropTypes.func, // i18n translation func
  courses: PropTypes.array,
  locale: PropTypes.string,
  filters: PropTypes.object,
  sizeScreen: PropTypes.string,
  changeFilters: PropTypes.func,
};

const usersQuery = graphql(COURSES_QUERY, {
  props: ({ data: { courses = [], loading } }) => ({
    courses,
    loading,
  }),
  options: () => ({ fetchPolicy: 'cache-and-network' }),
});

const enhancer = compose(
  withTranslation(),
  usersQuery
);
export default enhancer(Filters);
