// Core
import styled, { css } from 'styled-components';

// Other
import { colors } from '../../assets/styles';

const getStyle = type => ({ currentTheme }) => {
  if (type === 'container') {
    if (currentTheme === 'CHECKBOX_GREEN') {
      return css`
        background: white;
        border: 2px solid ${({ checked }) => colors[checked ? 'green2' : 'blue9']};
        border-radius: 8px;
        width: 29px;
        height: 29px;
      `;
    }
    return css`
      background: ${({ checked }) => (checked ? colors.theme1 : colors.white)};
      border: 1px solid ${colors.theme1};
      border-radius: 3px;
      width: 18px;
      height: 18px;
    `;
  }
  if (type === 'wrapper') {
    if (currentTheme === 'CHECKBOX_GREEN') {
      return css`
        max-height: 29px;
      `;
    }
    return css`
      max-height: 18px;
    `;
  }
  if (type === 'icon') {
    if (currentTheme === 'CHECKBOX_GREEN') {
      return css`
        stroke: ${colors.green2};
        top: 2px;
        position: relative;
        stroke-width: 5px;
      `;
    }
    return css`
      stroke: white;
      stroke-width: 3px;
    `;
  }
  return '';
};

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const Icon = styled.svg`
  fill: none;
  ${getStyle('icon')}
`;

export const StyledCheckbox = styled.div`
  display: inline-block;
  transition: all 150ms;
  align-items: center;
  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
  ${getStyle('container')}
`;

export const CheckboxContainer = styled.div`
  display: inline-block;
  ${getStyle('wrapper')}
`;
