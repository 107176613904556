import styled, { css } from 'styled-components';
import { colors } from '../../assets/styles';
import { FUL_HD, HD, qHD, nHD, CIF } from './constants';

const getWidth = (size, type) => {
  const params = {};

  if ([FUL_HD, HD].includes(size)) {
    params.widthImg = 577;
    params.widthBottomImg = 65;
    params.fontSize = 80;
    params.flexDirection = 'row';
  }
  if ([qHD].includes(size)) {
    params.widthImg = 400;
    params.widthBottomImg = 40;
    params.fontSize = 60;
    params.flexDirection = 'row';
  }
  if ([nHD].includes(size)) {
    params.widthImg = 350;
    params.widthBottomImg = 30;
    params.fontSize = 40;
    params.flexDirection = 'column';
  }
  if (CIF === size) {
    params.flexDirection = 'column';
    params.widthBottomImg = 17;
  }
  return params[type];
};

export const Header = styled.div`
  width: 100vw;
  height: 444px;
  position: relative;
  background-image: linear-gradient(257deg, ${colors.gradient1} 30%, ${colors.gradient2} 80%);
  padding: 55px 69px 0px 80px;
  display: flex;
  flex-direction: column;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to bottom, ${colors.grey15}, ${colors.white});
    width: 100%;
    opacity: 0.5;
    height: 36px;
  }
`;

export const LogoAndUser = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${({ sizeScreen }) => getWidth(sizeScreen, 'flexDirection')};
`;

export const Logo = styled.img`
  width: 113px;
  height: 50px;
`;

export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const RecruitingWrapper = styled.div`
  flex: 1;
`;

export const Title = styled.h1`
  font-family: OpenSans;
  font-weight: bold;
  font-size: ${({ sizeScreen }) => getWidth(sizeScreen, 'fontSize')}px;
  line-height: 108px;
  word-wrap: normal;
  white-space: nowrap;
  color: ${colors.white};
`;

export const TitleBlock = styled.div`
  display: flex;
  flex: 2;
  letter-spacing: -3.87px;
`;
export const ImageBlock = styled.div`
  display: flex;
  flex: 8;
  justify-content: center;
`;

export const ImageFind = styled.img`
  width: ${({ sizeScreen }) => getWidth(sizeScreen, 'widthImg')}px;
  position: absolute;
  bottom: -${({ sizeScreen }) => getWidth(sizeScreen, 'widthBottomImg')}px;
  z-index: 20;
`;

export const TitleAndImageWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex: 1;
  margin-bottom: 105px;
`;

export const Body = styled.div`
  background: ${colors.grey16};
  height: calc(100% - 444px);
  width: 100vw;
  max-width: 100vw;
  position: relative;
  ${'' /* padding: 78px 52px 0px 51px; */}
  padding: 78px 0px;
  display: flex;
  ${({ sizeScreen }) => css`
    flex-direction: ${[FUL_HD].includes(sizeScreen) ? 'row' : 'column'};
    ${'' /* align-items: ${[FUL_HD].includes(sizeScreen) ? 'flex-start' : 'center'}; */}
  `}
  box-sizing: border-box;
`;

export const Tabs = styled.div`
  position: absolute;
  top: -72px;
  width: 100%;
  height: 72px;
  display: flex;
`;

export const Tab = styled.div`
  height: 72px;
  width: 282px;
  color: ${colors.blue7};
  font-family: OpenSans;
  font-weight: bolder;
  font-size: 18px;
  border-radius: 24px 24px 0 0;
  text-align: center;
  background: ${colors.grey16};
  line-height: 72px;
`;

export const LogoWrapper = styled.div`
  display: flex;
`;

export const LogoText = styled.div`
  border-left-width: 4px;
  border-left-style: solid;
  border-color: ${colors.white};
  color: white;
  font-family: OpenSans;
  font-size: 34px;
  margin-left: 10px;
  padding-left: 10px;
  text-transform: uppercase;
`;

export const IconLogo = styled.img`
  margin-left: 15px;
`;

export const UserName = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${colors.grey13};
  margin-right: 9px;
`;

export const Avatar = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 50%;
`;
