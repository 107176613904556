import i18n, { LOCALES } from '../../../external/i18n';

i18n.addResources(LOCALES.RU, 'translation', {
  'Theme/themeListSwitch': 'Темы',
  'Theme/topQuestionSwitch': 'Вопросы на интервью',
  'Theme/createNew': 'Создать новую тему',
  'Theme/nameTheme': 'Название темы',
  'Theme/nameThemeEn': 'Название на английском',
  'Theme/deleteTitle': 'Вы уверены что хотите удалить данную тему?',
  'TopQuestion/level': 'Уровень',
  'TopQuestion/descriptionEn': 'Описание Eng',
  'TopQuestion/description': 'Описание',
});

i18n.addResources(LOCALES.EN, 'translation', {
  'Theme/themeListSwitch': 'Themes',
  'Theme/topQuestionSwitch': 'Interview questions',
  'Theme/createNew': 'Create new theme',
  'Theme/nameTheme': 'Name theme',
  'Theme/nameThemeEn': 'Name on english',
  'Theme/deleteTitle': 'Are you sure you want to delete the theme?',
  'TopQuestion/level': 'Level',
  'TopQuestion/descriptionEn': 'Description Eng',
  'TopQuestion/description': 'Description',
});
