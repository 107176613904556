import { units } from './enum';

/**
 * declination in quantity
 * @param {string | number} from quantity entity (unit)
 * @param {string} unit possible units, see units line 1
 * @param {object} i18n  translation
 */
export const getUnit = (from = 0, unit, t, reduction = false) => {
  // We take the last 2 numbers
  const preparedFrom = +from.toString().slice(-2);
  // if the last 2 numbers are greater than 20, used unit for up to ten
  const formatFrom = t.locale === 'ru' && preparedFrom > 20 ? +from.toString().slice(-1) : +from;

  if (!units.includes(unit)) {
    return unit;
  }

  let quantityWord = 'many';
  if (reduction) quantityWord = 'reduction';
  if (formatFrom === 1) quantityWord = 'singular';
  if (formatFrom >= 2 && formatFrom < 5) quantityWord = 'few';
  return t(`InternshipUpdate/${unit}/${quantityWord}`);
};
