// Core
import styled from 'styled-components';
import { rem } from 'polished';

// Other
import { colors } from '../../assets/styles';

export const ChosenImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const PickerLabel = styled.label`
  cursor: pointer;
  ${({ disabled }) => disabled && 'cursor: default;'}
`;

export const PickerContent = styled.div`
  width: 168px;
  height: 168px;
  padding: 15px;
  overflow: hidden;
  border-radius: 17px;
  border: 2px dashed ${colors.grey7};
  position: relative;
`;

export const TextLabelWrapper = styled.div`
  height: 40%;
  padding: 0 15px 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
`;

export const TextLabel = styled.p`
  font-size: ${rem(12)};
  line-height: 18px;
  text-align: center;
  color: ${colors.grey7};
`;

export const PlusIcon = styled.img`
  width: 21px;
  height: 21px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
