// Core
import React from 'react';
import ApexChart from 'react-apexcharts';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// Other
import { optionsForTypeChart, getSizeChart } from './helpers';

// Styles and assets
import { Wrapper, ChartWrapper, OverallTimeWrapper } from './styles';

export const types = {
  pic: 'donut',
  basicBar: 'bar',
  timeChart: 'donut',
  radar: 'radar',
};

const Chart = ({
  type = 'pic',
  series,
  totalAnswer,
  sizeScreen,
  backgroundImage,
  labels,
  bottomValue,
  t,
}) => (
  <Wrapper key={type} id={type}>
    <ChartWrapper backgroundImage={backgroundImage}>
      <ApexChart
        width={getSizeChart(sizeScreen)[type]}
        options={optionsForTypeChart[type](labels, totalAnswer)}
        series={series || []}
        type={types[type]}
      />
    </ChartWrapper>
    {bottomValue && (
      <OverallTimeWrapper>
        <span>{t('Profile/overlay')}</span>
        <p>{bottomValue}</p>
      </OverallTimeWrapper>
    )}
  </Wrapper>
);

Chart.propTypes = {
  type: PropTypes.string,
  sizeScreen: PropTypes.string,
  totalAnswer: PropTypes.number,
  backgroundImage: PropTypes.string,
  bottomValue: PropTypes.string,
  series: PropTypes.array,
  labels: PropTypes.array,
  t: PropTypes.func, // i18n translation func
};

export default withTranslation()(Chart);
