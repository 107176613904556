// Core
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import GradientButton from '../../../components/Buttons/GradientButton';

// Styles and assets
import { AlertBtnsRow, TextCancel, Wrapper, Title, Description } from './styles';
import './i18n';

class CopyAllQuestionModal extends PureComponent {
  handleCopyQuestion = () => {
    const { closeModal, handleButton } = this.props;
    closeModal();
    handleButton();
  };

  render() {
    const { t, closeModal } = this.props;

    return (
      <Wrapper>
        <Title>{t('CopyAllQuestionModal/title')}</Title>
        <Description>{t('CopyAllQuestionModal/description')}</Description>
        <AlertBtnsRow>
          <GradientButton transparent width={192} onClick={closeModal}>
            <TextCancel> {t('App/cancel')}</TextCancel>
          </GradientButton>
          <GradientButton width={192} onClick={this.handleCopyQuestion}>
            {t('CopyAllQuestionModal/buttonTitle')}
          </GradientButton>
        </AlertBtnsRow>
      </Wrapper>
    );
  }
}

CopyAllQuestionModal.propTypes = {
  t: PropTypes.func, // i18n translate func
  closeModal: PropTypes.func, // closes the modal
  handleButton: PropTypes.func, // handle button the modal
};

export default withTranslation()(CopyAllQuestionModal);
