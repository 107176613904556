import { gql } from 'apollo-boost';

export default gql`
  query Questions(
    $where: question_bool_exp
    $authorAggregateWhere: question_bool_exp
    $whereTheme: theme_bool_exp
    $whereCourse: course_bool_exp
    $limit: Int
    $offset: Int
  ) {
    question(where: $where, limit: $limit, offset: $offset) {
      id
      course_id
      theme_id
      details
      daily_question_date
      is_top_interview_question
      is_locked_interview_question
      level
      moderation_status
      app_user {
        name
      }
      like: user_questions_aggregate(where: { user_satisfaction: { _eq: "LIKE" } }) {
        aggregate {
          count
        }
      }
      dislike: user_questions_aggregate(where: { user_satisfaction: { _eq: "DISLIKE" } }) {
        aggregate {
          count
        }
      }
    }
    theme(where: $whereTheme) {
      id
      names(path: "ru")
    }
    course(where: $whereCourse) {
      id
      title
      has_daily_question
      questions(where: { daily_question_date: { _is_null: false } }) {
        daily_question_date
      }
    }
    question_aggregate(where: $where) {
      aggregate {
        count
      }
    }

    author_questions_aggregate: question_aggregate(where: $authorAggregateWhere) {
      aggregate {
        count
      }
    }
  }
`;
