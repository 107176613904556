// Core
import React, { PureComponent } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

// Components
import AuthBtn from './AuthBtn';
import AlertButton from '../../components/Buttons/AlertButton';

// Mobx
import { USER_STORE, APP_STORE } from '../../stores/constants';

// Other
import firebase from '../../external/firebase';
import { GOOGLE, FACEBOOK, GITHUB, ALERT_MODAL, SMALL_MODAL, ALLOWED_ROLES } from '../../constants';
import bugsnag from '../../external/bugsnag';
import readUserData from '../../utils/readUserData';
import { formatErrorText } from './helpers';

// Styles and assets
import { ContentWrapper, AuthContainer, AuthTitle, AlertBtnsRow } from './styles';

import './i18n';

const AUTH_PROVIDERS = {
  [GOOGLE]: 'GoogleAuthProvider',
  [FACEBOOK]: 'FacebookAuthProvider',
  [GITHUB]: 'GithubAuthProvider',
};

@inject(USER_STORE, APP_STORE)
@observer
class SignIn extends PureComponent {
  state = {
    loading: false,
    type: undefined,
  };

  alertOnClick = (type, provider, credential) => async () => {
    const {
      [APP_STORE]: { closeModal },
    } = this.props;
    await closeModal();

    if (type === 'ok') {
      return this.socialLogin(provider, credential);
    }
    return this.setState({ loading: false });
  };

  /**
   * SignUp/login user to the firebase and api storages
   * @param {string} type social type [FACEBOOK, GOOGLE, GITHUB]
   * @param {object} [connectCredential] credential to connect another provider with current user
   */
  socialLogin = async (type, connectCredential) => {
    const {
      [USER_STORE]: { setUserData },
      [APP_STORE]: { showModal },
      i18n,
      t,
      history,
    } = this.props;

    try {
      if (!connectCredential) {
        this.setState({ type, loading: true });
      }
      const providerName = AUTH_PROVIDERS[type];
      const provider = new firebase.auth[providerName]();
      const { user } = await firebase.auth().signInWithPopup(provider);
      if (connectCredential) {
        await firebase.auth().currentUser.linkWithCredential(connectCredential);
      }

      const userData = await readUserData(user.uid);
      if (!userData) {
        throw new Error('noUser');
      }
      if (!ALLOWED_ROLES.includes(userData?.role)) {
        throw new Error('notAdmin');
      }
      setUserData(userData);
      if (i18n.language !== userData.locale) {
        i18n.changeLanguage(userData.locale);
      }

      return this.setState({ success: true, loading: false }, () => {
        setTimeout(() => {
          history.push('/admin/courses');
        }, 1000);
      });
    } catch (authError) {
      let error = authError;
      if (authError.code === 'auth/popup-closed-by-user') {
        return this.setState({ loading: false });
      }
      if (authError.code === 'auth/account-exists-with-different-credential') {
        try {
          const { email, credential } = authError;
          const [provider] = await firebase.auth().fetchSignInMethodsForEmail(email);
          return showModal({
            type: ALERT_MODAL,
            size: SMALL_MODAL,
            onClose: this.alertOnClick('cancel'),
            modalProps: {
              title: t('SignIn/authErrorTitle'),
              description: formatErrorText(t, provider, credential.providerId),
            },
            content: (
              <AlertBtnsRow>
                <AlertButton
                  onClick={this.alertOnClick('ok', provider, credential)}
                  text={t('SignIn/signIn')}
                />
                <AlertButton
                  transparent
                  onClick={this.alertOnClick('cancel')}
                  text={t('SignIn/cancel')}
                />
              </AlertBtnsRow>
            ),
          });
        } catch (err) {
          error = err;
        }
      }
      bugsnag.notify(error, {
        description: ['noUser', 'notAdmin'].includes(error.message) && t(`SignIn/${error.message}`),
      });
    }
    return this.setState({ loading: false });
  };

  /**
   * Returns params object for social btn with provided type of social service
   * @param {string} socialType [FACEBOOK, GOOGLE, GITHUB]
   */
  getBtnParams = socialType => {
    const { loading, success, type } = this.state;
    const isBtnType = socialType === type;
    return {
      type: socialType,
      onClick: () => this.socialLogin(socialType),
      loading: isBtnType && loading,
      success: isBtnType && success,
      disabled: (loading || success) && !isBtnType,
    };
  };

  render() {
    const {
      [USER_STORE]: { userData },
      t,
    } = this.props;
    return (
      <ContentWrapper>
        <AuthContainer>
          <AuthTitle>
            {userData?.name ? `${t('SignIn/hello')} ${userData.name}` : t('SignIn/authorizeWith')}
          </AuthTitle>
          <AuthBtn {...this.getBtnParams(GOOGLE)} />
          {/* <AuthBtn {...this.getBtnParams(GITHUB)} /> */}
          <AuthBtn {...this.getBtnParams(FACEBOOK)} />
        </AuthContainer>
      </ContentWrapper>
    );
  }
}

export default withTranslation()(SignIn);
