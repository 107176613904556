// Core
import styled from 'styled-components';

// Other
import { colors } from '../../../assets/styles';

export const DatePickerButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0.6;
  color: ${colors.grey6};

  &:hover {
    opacity: 1;
  }
`;
export const CalendarIcon = styled.img`
  width: 24px;
  padding-right: 6px;
`;
