import { gql } from 'apollo-boost';

export default gql`
  query UsersData {
    app_user(order_by: { id: asc }) {
      id
      role
      locale
    }
  }
`;
