import { gql } from 'apollo-boost';

export default gql`
  query UserAuthData($where: app_user_bool_exp) {
    app_user(where: $where) {
      id
      name
      role
      email
      locale
      firebase_uid
      picture_url
      user_subscriptions {
        subscription_id
        valid_until
      }
    }
  }
`;
