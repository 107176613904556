// Core
import React, { PureComponent } from 'react';

// Styles and assets
import { ContentWrapper } from './styles';

class Settings extends PureComponent {
  render() {
    return <ContentWrapper>Settings</ContentWrapper>;
  }
}

export default Settings;
