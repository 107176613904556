// Core
import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';

// Styles and assets
import {
  WrapperInput,
  StyledInput,
  Icon,
  StyledTextarea,
  StyledLabel,
  ErrorTitle,
  customStyles,
  StyledLabelSelect,
} from './styles';

import arrowBottom from '../../assets/images/internship-screen/ic-expand-more-24-px.png';

// drop to styles
const indicatorSeparatorStyle = {
  alignSelf: 'stretch',
  backgroundColor: 'while',
};

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <Icon src={arrowBottom} />
  </components.DropdownIndicator>
);

const ClearIndicator = () => <div />;

const IndicatorSeparator = () => <span style={indicatorSeparatorStyle} />;

const Input = ({ selectProps, label, boldLabel, error, multiline, onChange, ...inputProps }) => {
  const errorMsg = error?.message;
  const InnerLabel = selectProps ? StyledLabelSelect : StyledLabel;
  return (
    <InnerLabel boldLabel={boldLabel}>
      {label}
      {multiline ? (
        <StyledTextarea isError={!!errorMsg} {...inputProps} onChange={onChange} />
      ) : (
        <WrapperInput>
          <StyledInput
            isSelect={selectProps}
            isError={!!errorMsg}
            onChange={onChange}
            {...inputProps}
          />
          {selectProps && (
            <Select
              isClearable
              isSearchable
              name="test"
              classNamePrefix="select"
              className="basic-multi-select"
              styles={customStyles}
              error={selectProps?.error}
              onChange={selectProps.onChange}
              components={{ IndicatorSeparator, DropdownIndicator, ClearIndicator }}
              options={selectProps.options}
              value={selectProps.defaultValue}
              placeholder={selectProps?.placeholder}
            />
          )}
        </WrapperInput>
      )}
      {errorMsg && <ErrorTitle>{errorMsg}</ErrorTitle>}
    </InnerLabel>
  );
};

Input.propTypes = {
  rightText: PropTypes.string, // rightText right value
  label: PropTypes.string, // label value
  boldLabel: PropTypes.bool, // is input label bold
  error: PropTypes.object, // error object
  multiline: PropTypes.bool, // uses textarea instead of simple input
  onChange: PropTypes.func, // use for change value
  selectProps: PropTypes.object, // use for change value
};

export default Input;
