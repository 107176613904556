export const INITIAL_STATE = {
  description: '',
  description_en: '',
  error: null,
  loading: false,
  solutions: [{ answer: '', isCorrect: false }],
  solutions_en: [{ answer: '', isCorrect: false }],
  explanation: '',
  explanation_en: '',
  isDailyQuestion: false,
  isInterviewQuestion: false,
  isPremiumQuestion: false,
  daily_question_date: null,
  level: null,
};

export const levelOptions = [
  { value: 'INTERN', label: 'Intern' },
  { value: 'JUNIOR', label: 'Junior' },
  { value: 'MIDDLE', label: 'Middle' },
  { value: 'SENIOR', label: 'Senior' },
];

export function getDerivedState(question, prevState) {
  const {
    details: {
      ru: {
        explanation: { text: explanation },
        solutions,
        description,
      },
      en: {
        explanation: { text: explanation_en },
        solutions: solutions_en,
        description: description_en,
      },
    },
    daily_question_date,
    is_top_interview_question,
    level,
    is_locked_interview_question,
  } = question;

  const dateDailyQuestions = daily_question_date ? new Date(daily_question_date) : null;

  return {
    ...prevState,
    description,
    description_en,
    explanation,
    explanation_en,
    solutions,
    solutions_en,
    isStateUpdated: true,
    daily_question_date: dateDailyQuestions,
    isDailyQuestion: !!daily_question_date,
    isInterviewQuestion: !!is_top_interview_question,
    isPremiumQuestion: !!is_locked_interview_question,
    level: levelOptions.find(({ value }) => value === level) || null,
  };
}
