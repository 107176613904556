// Core
import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';

// Other
import { colors } from '../../../assets/styles';

const StyledWrapperPaginate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ReactPaginateAdapter = ({
  container,
  page,
  className,
  active,
  pageLink,
  previousArrowLink,
  nextArrowLink,
  disabledBtn,
  ...props
}) => (
  <StyledWrapperPaginate className={className}>
    <ReactPaginate
      previousLinkClassName={previousArrowLink}
      nextLinkClassName={nextArrowLink}
      containerClassName={container}
      pageClassName={page}
      activeClassName={active}
      pageLinkClassName={pageLink}
      disabledClassName={disabledBtn}
      {...props}
    />
  </StyledWrapperPaginate>
);

ReactPaginateAdapter.propTypes = {
  container: PropTypes.string,
  active: PropTypes.string,
  page: PropTypes.string,
  pageLink: PropTypes.string,
  previousArrowLink: PropTypes.string,
  nextArrowLink: PropTypes.string,
  disabledBtn: PropTypes.string,
  className: PropTypes.string,
};

export const StyledPaginate = styled(ReactPaginateAdapter).attrs({
  previousArrowLink: 'previousArrowLink',
  nextArrowLink: 'nextArrowLink',
  container: 'container',
  page: 'page',
  active: 'active',
  disabledBtn: 'disabledBtn',
})`
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style-type: none;
  }
  .page {
    width: 54px;
    height: 54px;
    border: 1px solid ${colors.grey10};
    border-radius: 4px;
    margin-right: 14px;
    transition: background 0.3s ease-in-out;
    color: ${colors.blue5};
    font-size: 18px;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 54px;
      height: 54px;
      cursor: pointer;
      :focus {
        outline: none;
      }
    }
  }

  .break {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    height: 54px;
    border: 1px solid ${colors.grey10};
    margin-right: 14px;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 54px;
      height: 54px;
      cursor: pointer;
      :focus {
        outline: none;
      }
    }
  }

  .page:hover {
    opacity: 1;
  }

  .active {
    width: 54px;
    height: 54px;
    background: ${colors.blue5};
    border: none;
    color: ${colors.white};
    opacity: 1;
  }
  .pageLink {
  }

  .previousArrowLink {
    opacity: 0.4;
  }
  .previousArrowLink:hover {
    opacity: 1;
  }
  .nextArrowLink {
    opacity: 0.4;
  }
  .nextArrowLink:hover {
    opacity: 1;
  }
  .disabledBtn {
    pointer-events: none;
  }

  .previous {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    height: 54px;
    border: 1px solid ${colors.grey10};
    margin-right: 14px;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 54px;
      height: 54px;
      cursor: pointer;
      :focus {
        outline: none;
      }
    }
  }
  .next {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    height: 54px;
    border: 1px solid ${colors.grey10};
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 54px;
      height: 54px;
      cursor: pointer;
      :focus {
        outline: none;
      }
    }
  }
`;

export const IconArrow = styled.img`
  width: 12px;
  height: 12px;
  margin-top: 3px;
`;
