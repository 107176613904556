// GraphQL
import USER_AUTH_DATA_QUERY from '../gql/query/userAuthData';

// Other
import client from '../external/apollo';

/**
 * read user auth data from db
 * @param {string} uid user firebaseId or id
 * @returns user data object
 */
const readUserData = async uid => {
  const where = {
    firebase_uid: { _eq: uid },
  };

  const {
    data: {
      app_user: [userData],
    },
  } = await client.query({
    query: USER_AUTH_DATA_QUERY,

    variables: { where },
  });
  return userData;
};

export default readUserData;
