import i18n, { LOCALES } from '../../external/i18n';

i18n.addResources(LOCALES.RU, 'translation', {
  'Question/title': 'Вопрос:',
  'Question/CopyText': 'Скопировать все',
  'Question/titlePlaceholder': 'Напишите вопрос',
  'Question/AddAnswerButton': '+ Добавить ответ',
  'Question/solution': 'Варианты ответа:',
  'Question/solutionPlaceholder': 'Напишите вариант ответа',
  'Question/explanation': 'Объяснение:',
  'Question/explanationPlaceholder': 'Напишите объяснение',
  'Question/new': 'Новый вопрос',
  'Question/edit': 'ID Вопроса: ',
  'Questions/save': 'Сохранить',
  'Questions/publish': 'Опубликовать',
  'Questions/reject': 'Отклонить',
  'Questions/dailyQuestion': 'Вопрос дня',
  'Questions/interviewQuestion': 'Вопрос интервью',
  'Questions/dailyQuestion/placeHolder': 'Выберите дату',
  'Question/SelectLevel': 'Выберите уровень знаний',
  'Questions/dailyQuestion/premium': 'Премиум',
  'Questions/TitleChangeLanguageRu': 'Русский',
  'Questions/TitleChangeLanguageEn': 'English',
});

i18n.addResources(LOCALES.EN, 'translation', {
  'Question/title': 'Question:',
  'Question/CopyText': 'Copy all',
  'Question/titlePlaceholder': 'Add description to the question',
  'Question/AddAnswerButton': '+ Add answer',
  'Question/solution': 'Possible answer:',
  'Question/solutionPlaceholder': 'Write possible answer',
  'Question/explanation': 'Explanation:',
  'Question/explanationPlaceholder': 'Write explanation',
  'Question/new': 'New question',
  'Question/edit': 'Question ID: ',
  'Questions/save': 'Save',
  'Questions/reject': 'Reject',
  'Questions/publish': 'Publish',
  'Questions/dailyQuestion': 'Daily question',
  'Questions/interviewQuestion': 'Interview Question',
  'Questions/dailyQuestion/placeHolder': 'Choose a date',
  'Questions/dailyQuestion/premium': 'Premium',
  'Question/SelectLevel': 'Select knowledge level',
  'Questions/TitleChangeLanguageRu': 'Russian',
  'Questions/TitleChangeLanguageEn': 'English',
});
