// Core
import styled, { css } from 'styled-components';
import { rem } from 'polished';

import { AvatarWrapper } from '../../components/Avatar/styles';

// Other
import { colors, BREAKPOINTS } from '../../assets/styles';
import happyMan from '../../assets/images/happy-man.svg';
import superman from '../../assets/images/superman.svg';

const blurStyle = css`
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    box-shadow: 0 1px 6px 0 ${colors.whiteOpacity10};
    border: solid 1px ${colors.grey2};
    background-color: ${colors.grey8};
    opacity: 0.8;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  min-height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
`;

export const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 38px;
  margin-top: -177px;
  position: relative;
  z-index: 10;
  height: 288px;
  & ${AvatarWrapper} {
    margin-bottom: 16px;
  }
  @media (max-width: ${BREAKPOINTS.MEDIUM}) {
    flex-direction: row;
    align-items: center;
    height: 650px;
  }
  @media (max-width: ${BREAKPOINTS.SMALL}) {
    flex-direction: row;
    align-items: center;
    height: 550px;
  }
`;

export const NameContainer = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  height: 177px;
  padding: 0px 0px 19px 63px;
  position: relative;
  @media (max-width: ${BREAKPOINTS.MEDIUM}) {
    position: static;
    height: 90px;
    align-items: center;
    padding-left: 0px;
  }
`;

export const FlagIsPremium = styled.div`
  display: flex;
  width: 66px;
  height: 24px;
  background: ${colors.pink};
  color: ${colors.pink1};
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  font-family: OpenSans;
  font-size: ${rem(12)};
  text-transform: uppercase;
  position: absolute;
  top: 0px;
  left: 63px;

  @media (max-width: ${BREAKPOINTS.MEDIUM}) {
    left: calc(50% - 33px);
    top: -10px;
  }
`;

export const NameText = styled.p`
  font-size: ${rem(34)};
  font-weight: 600;
  color: ${colors.white};
  margin-left: 0px;
  text-shadow: 0 3px 6px ${colors.whiteOpacity15};
  font-family: OpenSans;
  @media (max-width: ${BREAKPOINTS.SMALL}) {
    margin-left: 16px;
  }
`;

export const EmailText = styled.p`
  font-size: ${rem(16)};
  font-weight: 600;
  color: ${colors.white};
  margin-left: 0px;
  text-shadow: 0 3px 6px ${colors.whiteOpacity15};
  font-family: OpenSans;
  @media (max-width: ${BREAKPOINTS.SMALL}) {
    margin-left: 16px;
  }
`;

export const LangsContainer = styled.div`
  display: flex;
  flex: 1;
  padding-left: 63px;
  align-items: flex-end;
  @media (max-width: ${BREAKPOINTS.MEDIUM}) {
    height: 90px;
    max-height: 90px;
    padding-left: 0px;
    align-items: center;
    justify-content: center;
    max-width: 400px;
    flex-wrap: wrap;
  }
`;

export const LangProgressBtn = styled.button`
  margin: 16px 16px 0px 0px;
  cursor: pointer;
  box-sizing: border-box;
  transition: transform, filter, opacity, 0.3s ease-in-out;
  cursor: pointer;
  filter: grayscale(1);
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.6;
  }
  &:disabled {
    filter: none;
    pointer-events: none;
    transform: scale(1.25);
  }
`;

export const TitleText = styled.h2`
  font-size: 18px;
  line-height: 26px;
  color: ${colors.blue};
  margin-bottom: 16px;
`;

export const BreakLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.grey3};
  opacity: 0.3;
  margin: 30px 0 15px;
`;

export const BlockTitle = styled.p`
  object-fit: contain;
  font-family: OpenSans;
  font-size: 18px;
  line-height: 0.72;
  color: ${colors.grey6};
  position: absolute;
  top: 16px;
  left: 24px;
`;

export const BlockUserStatistic = styled.div`
  display: flex;
  flex: ${({ flexCount }) => flexCount || 1};
  background: ${colors.white};
  border-radius: 8px;
  position: relative;
  margin: 16px;
  box-sizing: border-box;
  box-shadow: 0 2px 6px 0 ${colors.whiteOpacity10};
  justify-content: space-between;
  ${({ isBlur }) => isBlur && blurStyle}
  @media (max-width:${BREAKPOINTS.LARGE}) {
    flex: 1;
    align-self: normal;
  }
`;

export const RowBlock = styled.div`
  display: flex;
  flex: 1;
  @media (max-width: ${BREAKPOINTS.MEDIUM}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const AwardsWrapper = styled.div`
  padding: 73px 70px 48px;
`;

export const Statistic = styled.div`
  flex: 1;
  background-image: url(${happyMan});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  min-height: 300px;
  padding: 13px;
  box-sizing: border-box;
`;

export const BlockNumberInStatistic = styled.div`
  width: 309px;
  height: 32px;
  display: flex;
  margin-left: auto;
  align-items: center;
  justify-content: space-around;
  background: ${colors.orange};
  padding-left: 10px;
  border-radius: 16px;
  text-shadow: 0 2px 6px ${colors.whiteOpacity15};
  font-family: OpenSans;
  font-size: ${rem(14)};
  letter-spacing: 0.28px;
  box-sizing: border-box;
  color: ${colors.white};
  &::after {
    content: '';
    width: 44px;
    height: 51px;
    background-image: url(${superman});
    background-repeat: no-repeat;
    background-size: 44px 51px;
    background-position: right;
    margin-left: 10px;
    box-sizing: border-box;
  }
`;

export const TextAwardUserBetter = styled.span`
  font-weight: ${({ isNormal }) => (isNormal ? 'normal' : 'bolder')};
`;

export const NotificationWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  background: ${colors.orange};
  width: 100vw;
  min-height: 100px;
  padding: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 4px 9px 22px -3px;
  border: 1px solid white;
  border-bottom-width: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BottomExpander = styled.div`
  height: 200px;
`;

export const TextNotification = styled.p`
  color: white;
  font-family: OpenSans;
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
`;
