// Core
import styled from 'styled-components';
import { rem } from 'polished';
import { colors } from '../../../assets/styles';

export const ContentWrapper = styled.div`
  flex: 1;
  margin: 0px 80px;
`;

export const FormArea = styled.div`
  flex: 1;
  width: 50%;
  margin-top: 20px;
`;

export const Icon = styled.img`
  width: 12px;
  align-self: center;
  margin-left: 5px;
`;

export const HeaderSection = styled.div`
  margin-top: 30px;
  flex: 1;
  display: flex;
  justify-content: space-between;
  height: 40px;
`;

export const FormText = styled.p`
  color: ${colors.theme1};
  margin-top: 20px;
  font-size: ${rem(12)};
  margin-bottom: 4px;
  font-weight: 700;
`;

export const ScreenName = styled.p`
  color: ${colors.theme1};
  padding-left: 10px;
  font-weight: bold;
  height: 20px;
  font-weight: 700;
`;

export const ScreenNameWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-end;
`;

export const IconButton = styled.button`
  flex: 1;
`;

export const customStyles = {
  control: (styles, { selectProps: { error } }) => ({
    ...styles,
    backgroundColor: colors.white,
    border: `1px solid ${colors[error ? 'error' : 'theme4']}`,
    minHeight: 48,
  }),
  option: (options, state) => {
    return {
      ...options,
      color: colors[state.isSelected ? 'error' : 'blue'],
      padding: 10,
      fontSize: '12px',
      fontWeight: 400,
    };
  },
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
  placeholder: base => ({
    ...base,
    fontSize: '12px',
    fontWeight: 400,
    color: colors.grey14,
    paddingLeft: 10,
  }),
};
