import i18n, { LOCALES } from '../../external/i18n';

i18n.addResources(LOCALES.RU, 'translation', {
  'Header/title': 'Программируем легко!',
  'Header/logOut': 'Выход',
});

i18n.addResources(LOCALES.EN, 'translation', {
  'Header/title': 'Coding easy!',
  'Header/logOut': 'Log out',
});
