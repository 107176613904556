import { gql } from 'apollo-boost';

export default gql`
  query News($limit: Int, $offset: Int) {
    news(order_by: { updated_at: desc }, limit: $limit, offset: $offset) {
      id
      picture_url
      title
      title_en
      description
      description_en
      updated_at
    }
    news_aggregate {
      aggregate {
        count
      }
    }
  }
`;
