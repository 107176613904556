import i18n, { LOCALES } from '../../external/i18n';

i18n.addResources(LOCALES.RU, 'translation', {
  'Login/authorizeWith': 'Для того, чтобы посмотреть профиль, авторизируйтесь с помощью:',
  'Login/login': 'CodersLang · Логин',
});

i18n.addResources(LOCALES.EN, 'translation', {
  'Login/authorizeWith': 'To view a profile, log in using:',
  'Login/login': 'CodersLang · Login',
});

export default i18n;
