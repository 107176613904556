// Core
import React from 'react';
import PropTypes from 'prop-types';

// Components
import MarkdownEditor from '../../../components/MarkdownEditor';

// Styles
import { Wrapper, Icon, CopyBtn } from './styles';
import copyIcon from '../../../assets/images/questions-add-screen/copy.png';

const QuestionsMarkdownEditor = ({ label, handleCopyValue, ...props }) => (
  <Wrapper>
    <MarkdownEditor label={label} {...props} />
    <CopyBtn label={label} onClick={handleCopyValue}>
      <Icon src={copyIcon} />
    </CopyBtn>
  </Wrapper>
);

QuestionsMarkdownEditor.propTypes = {
  label: PropTypes.string,
  handleCopyValue: PropTypes.func,
};

export default QuestionsMarkdownEditor;
