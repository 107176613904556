import React from 'react';
import { graphql } from '@apollo/react-hoc';
import BounceLoader from 'react-spinners/BounceLoader';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// Components
import Select from '../Select';
import TextDate from './TextDateOnline';

// Other
import { optionsLevel, coursesImage } from '../Filter';
import USERS_FOR_RECRUITING_QUERY from '../../../gql/query/usersForRecruiting';
import compose from '../../../utils/compose';
import { getUnit } from '../../../utils/getUnit';
import { colors } from '../../../assets/styles';

import {
  UsersBody,
  PaginationWrapper,
  UserWrapper,
  UserNameBlock,
  UserTableWrapper,
  TextWrapper,
  LevelEllipse,
  CourseBlock,
  OptionImg,
  HeaderWrapper,
  FieldBlock,
  HeaderText,
  OrderByArrow,
  Loading,
  TextField,
  TableWrapper,
  NotFoundWrapper,
  NotFoundTitle,
  NotFoundDescription,
  TablesWrapper,
} from './styles';
import placeholderIcon from '../../../assets/images/avatar-placeholder.png';
import Paginate from '../Paginate';
import userNotFound from '../../../assets/images/recruiting/user_not_found.svg';
import { nHD, qHD, HD } from '../constants';

const getWidth = (sizeScreen, type) => {
  const preparedWidth = {
    name: 350,
    level: 155,
    courses: 180,
  };
  switch (sizeScreen) {
    case HD:
      preparedWidth.name = 330;
      break;
    case qHD:
      preparedWidth.name = 300;
      preparedWidth.level = 120;
      preparedWidth.courses = 120;
      break;
    case nHD:
      preparedWidth.name = 230;
      preparedWidth.level = 120;
      preparedWidth.courses = 120;
      break;

    default:
      break;
  }
  return preparedWidth[type];
};
const SELECT_OPTIONS = [10, 15, 50, 100];

const PaginationBlock = ({ t, limit, handleChangePage, currentPage, countPage }) => {
  return (
    <PaginationWrapper>
      <Select t={t} options={SELECT_OPTIONS} onChange={handleChangePage('limit')} value={limit} />
      <Paginate
        currentPage={currentPage}
        itemsCount={limit}
        countPage={countPage / limit}
        onPageChange={handleChangePage('page')}
      />
    </PaginationWrapper>
  );
};

PaginationBlock.propTypes = {
  limit: PropTypes.number,
  handleChangePage: PropTypes.func,
  currentPage: PropTypes.number,
  countPage: PropTypes.number,
  t: PropTypes.func, // i18n translation func
};

const UserBlock = ({
  id: userId,
  level,
  name,
  t,
  picture_url,
  user_courses = [],
  updated_at,
  average_response_time,
  correct_answers_percentage,
  sizeScreen,
}) => {
  const courses = user_courses.map(({ course_id }) => coursesImage[course_id]);
  const avgResponseTime = Math.round(average_response_time || 0);
  const correctAnswersPercentage = correct_answers_percentage * 100;
  const preparedPercentage = correctAnswersPercentage.toFixed(2);

  return (
    <UserWrapper>
      <UserNameBlock
        to={`profile/${userId}`}
        maxWidth={getWidth(sizeScreen, 'name')}
        isContain={!picture_url}
      >
        <img src={picture_url || placeholderIcon} alt="" />
        <span className="user_name">{name}</span>
      </UserNameBlock>
      <FieldBlock positionType="center" maxWidth={getWidth(sizeScreen, 'level')}>
        <LevelEllipse level={level}>
          {optionsLevel.find(({ value }) => value === level)?.title}
        </LevelEllipse>
      </FieldBlock>
      <CourseBlock maxWidth={getWidth(sizeScreen, 'courses')}>
        {courses?.map(img => <OptionImg key={`${userId}-${img}`} src={img} />)}
      </CourseBlock>
      <FieldBlock maxWidth={getWidth(sizeScreen, 'avgResponseTime')}>
        <TextField>{`${avgResponseTime} ${getUnit(avgResponseTime, 'second', t)}`}</TextField>
      </FieldBlock>
      <FieldBlock maxWidth={getWidth(sizeScreen, 'preparedPercentage')}>
        <TextField>
          {+preparedPercentage.split('.')[1]
            ? preparedPercentage
            : correctAnswersPercentage.toFixed(0)}
        </TextField>
      </FieldBlock>
      <FieldBlock maxWidth={getWidth(sizeScreen, 'updated_at')}>
        <TextDate t={t} date={updated_at} />
      </FieldBlock>
    </UserWrapper>
  );
};

UserBlock.propTypes = {
  t: PropTypes.func, // i18n translation func
  level: PropTypes.string,
  sizeScreen: PropTypes.string,
  name: PropTypes.string,
  picture_url: PropTypes.string,
  user_courses: PropTypes.array,
  updated_at: PropTypes.string,
  average_response_time: PropTypes.number,
  correct_answers_percentage: PropTypes.number,
  id: PropTypes.number,
};

const Header = ({ orderBy, t, handleChangePage, sizeScreen }) => {
  const handlerSort = field => () => {
    handleChangePage('order')(field);
  };

  return (
    <HeaderWrapper>
      <FieldBlock
        isSort
        onClick={handlerSort('name')}
        positionType="flex-start"
        paddingL={20}
        maxWidth={getWidth(sizeScreen, 'name')}
      >
        <HeaderText>{t('App/name')}</HeaderText>
        <OrderByArrow orderBy={orderBy?.name} />
      </FieldBlock>
      <FieldBlock isSort onClick={handlerSort('level')} maxWidth={getWidth(sizeScreen, 'level')}>
        <HeaderText>{t('TopQuestion/level')}</HeaderText>
        <OrderByArrow orderBy={orderBy?.level} />
      </FieldBlock>
      <FieldBlock maxWidth={getWidth(sizeScreen, 'courses')}>
        <HeaderText>{t('Courses/title')}</HeaderText>
      </FieldBlock>
      <FieldBlock
        maxWidth={getWidth(sizeScreen, 'avgResponseTime')}
        isSort
        onClick={handlerSort('average_response_time')}
      >
        <HeaderText>{t('App/AvgResponseTime')}</HeaderText>
        <OrderByArrow orderBy={orderBy?.average_response_time} />
      </FieldBlock>
      <FieldBlock
        maxWidth={getWidth(sizeScreen, 'preparedPercentage')}
        isSort
        onClick={handlerSort('correct_answers_percentage')}
      >
        <HeaderText>{t('Recruiting/correctAnswers')}</HeaderText>
        <OrderByArrow orderBy={orderBy?.correct_answers_percentage} />
      </FieldBlock>
      <FieldBlock
        maxWidth={getWidth(sizeScreen, 'updated_at')}
        isSort
        onClick={handlerSort('updated_at')}
      >
        <HeaderText>{t('Recruiting/lastActivity')}</HeaderText>
        <OrderByArrow orderBy={orderBy?.updated_at} />
      </FieldBlock>
    </HeaderWrapper>
  );
};

Header.propTypes = {
  t: PropTypes.func, // i18n translation func
  orderBy: PropTypes.object,
  handleChangePage: PropTypes.func,
  sizeScreen: PropTypes.string,
};

const NotFound = ({ t }) => {
  return (
    <NotFoundWrapper>
      <img src={userNotFound} alt="Not found" />
      <NotFoundTitle>{t('Recruiting/weCouldntFoundIt')}</NotFoundTitle>
      <NotFoundDescription>{t('Recruiting/notFoundDescription')}</NotFoundDescription>
    </NotFoundWrapper>
  );
};

NotFound.propTypes = {
  t: PropTypes.func, // i18n translation func
};

const TableUsers = ({
  orderBy,
  page,
  t,
  appUser,
  limit,
  loading,
  usersCount,
  sizeScreen,
  handleChangePage,
}) => {
  const isPresentUser = !!appUser?.length;
  return (
    <TablesWrapper sizeScreen={sizeScreen}>
      <UserTableWrapper sizeScreen={sizeScreen}>
        {isPresentUser && (
          <TextWrapper>
            <span>{t('Recruiting/jobSeekers')}</span>
            <p> {usersCount}</p>
          </TextWrapper>
        )}
        <TableWrapper>
          {isPresentUser ? (
            <UsersBody isBur={loading}>
              <Header
                sizeScreen={sizeScreen}
                t={t}
                orderBy={orderBy}
                handleChangePage={handleChangePage}
              />
              {appUser.map(user => (
                <UserBlock sizeScreen={sizeScreen} key={user?.id} t={t} {...user} />
              ))}
            </UsersBody>
          ) : (
            <NotFound t={t} />
          )}
          {loading && isPresentUser && (
            <Loading>
              <BounceLoader color={colors.gradient1} size={400} />
            </Loading>
          )}
        </TableWrapper>
        {isPresentUser && (
          <PaginationBlock
            t={t}
            currentPage={page}
            countPage={usersCount}
            limit={limit}
            handleChangePage={handleChangePage}
          />
        )}
      </UserTableWrapper>
    </TablesWrapper>
  );
};

TableUsers.propTypes = {
  loading: PropTypes.bool,
  t: PropTypes.func, // i18n translation func
  orderBy: PropTypes.object,
  page: PropTypes.number,
  appUser: PropTypes.array,
  limit: PropTypes.number,
  usersCount: PropTypes.number,
  sizeScreen: PropTypes.string,
  handleChangePage: PropTypes.func,
};

const usersQuery = graphql(USERS_FOR_RECRUITING_QUERY, {
  props: ({ data: { app_user = [], users_count, loading } }) => ({
    appUser: app_user,
    usersCount: users_count?.aggregate?.count,
    loading,
  }),
  options: ({
    filters: { selectedCourse, selectedLevel, selectedActivity },
    offset,
    limit,
    orderBy,
  }) => {
    let whereFields = {
      average_response_time: {
        _is_null: false,
      },
      correct_answers_percentage: {
        _is_null: false,
      },
      name: {
        _is_null: false,
      },
    };

    if (selectedCourse?.length) {
      whereFields = {
        ...whereFields,
        user_courses: {
          course_id: {
            _in: selectedCourse,
          },
        },
      };
    }
    if (selectedLevel?.length) {
      whereFields = {
        ...whereFields,
        level: {
          _in: selectedLevel,
        },
      };
    }
    if (selectedActivity?.length) {
      whereFields = {
        ...whereFields,
        updated_at: {
          _gte: moment.utc(
            moment()
              .set({
                hour: 0,
                minute: 0,
                second: 0,
              })
              .subtract(selectedActivity[0], 'days')
          ),
        },
      };
    }
    if (!selectedCourse?.length && !selectedLevel?.length && !selectedActivity?.length) {
      whereFields = {
        id: {
          _is_null: true,
        },
      };
    }
    return {
      variables: {
        orderBy,
        offset,
        limit,
        where: whereFields,
      },
      fetchPolicy: 'cache-and-network',
    };
  },
});

const enhancer = compose(
  withTranslation(),
  usersQuery
);
export default enhancer(TableUsers);
