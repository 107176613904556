// Core
import React from 'react';
import PropTypes from 'prop-types';

// Styles and assets
import arrowLeftIcon from '../../../assets/images/arrow/arrow-left.png';
import arrowRightIcon from '../../../assets/images/arrow/arrow-right.png';
import { IconArrow, StyledPaginate } from './styles';

const PaginationTheme = ({ countPage, currentPage, onPageChange, itemsCount }) => {
  const handlePage = ({ selected }) => {
    onPageChange(selected);
  };
  const currentCount = currentPage || 1 * itemsCount;
  const maxCount = countPage * itemsCount;

  return (
    maxCount >= currentCount && (
      <StyledPaginate
        pageCount={countPage}
        onPageChange={handlePage}
        forcePage={currentPage}
        previousLabel={<IconArrow src={arrowLeftIcon} />}
        nextLabel={<IconArrow src={arrowRightIcon} />}
        breakLabel={<p>...</p>}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
      />
    )
  );
};

PaginationTheme.propTypes = {
  countPage: PropTypes.number, // number count of Pages
  itemsCount: PropTypes.number, // number count of Items
  onPageChange: PropTypes.func, // open page func
  itemsPerPage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // number count of Items need to show
  currentPage: PropTypes.number, // number current Items
  selectProps: PropTypes.object, // open all items page func
};

export default PaginationTheme;
