// Core
import styled from 'styled-components';

export const ContentWrapper = styled.div`
  flex: 1,
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  margin-bottom: 15px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

export const InputWrapper = styled.div`
  margin-bottom: 15px;
`;

export const FormRow = styled.div`
  margin-top: 25px;
  width: 100%;
  display: flex;
  justify-content: center;
  & > label:not(:first-child) {
    margin-left: 20px;
  }
`;
