/* eslint-disable no-underscore-dangle */
// Core
import React from 'react';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

// Other
import { ALERT_MODAL, SMALL_MODAL } from '../../constants';
import i18n from '../i18n';

const { NODE_ENV } = process.env;

const bugsnagClient = bugsnag({
  apiKey: 'e64c42e1c152642024067243ea653e2b',
  releaseStage: NODE_ENV,
  notifyReleaseStages: ['production'],
});

bugsnagClient.use(bugsnagReact, React);

/**
 * Bugsnag client with custom notify method
 */
class Bugsnag {
  /**
   * Returns Bugsnag.Client instance
   */
  get client() {
    return bugsnagClient;
  }

  /**
   * Saves showModal handler from APP_STORE
   */
  set showModal(handler) {
    this._showModal = handler;
  }

  /**
   * Sends notification to the bugsnag and shows an alert if needed
   * @param {object} error error object
   * @param {object} [alertData] params for customize alert message
   */
  notify(error, alertData = {}) {
    if (alertData) {
      const { title, description } = alertData;
      this._showModal({
        type: ALERT_MODAL,
        size: SMALL_MODAL,
        modalProps: {
          title: title || i18n.t('Bugsnag/title'),
          description: description || i18n.t('Bugsnag/message'),
        },
      });
    }
    if (NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    return bugsnagClient.notify(error);
  }
}

export default new Bugsnag();
