// Core
import styled from 'styled-components';
import { rem } from 'polished';

// Other
import { colors } from '../../../assets/styles';

export const AlertBtn = styled.button`
  min-width: 100px;
  height: 40px;
  border-radius: 33px;
  font-size: ${rem(16)};
  margin: 0 12px;
  ${({ transparent }) => transparent && `border: 2px solid ${colors.theme1}`}
  color: ${({ transparent }) => (transparent ? colors.theme1 : colors.white)};
  background-color: ${({ transparent }) => (transparent ? 'transparent' : colors.theme1)};
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
`;
