import i18n, { LOCALES } from '../../external/i18n';

i18n.addResources(LOCALES.RU, 'translation', {
  'UserQuestion/title': 'Вопрос пользователя',
  'UserQuestion/ID': 'ID',
  'UserQuestion/course': 'Курс',
  'UserQuestion/theme': 'Tема',
  'UserQuestion/description': 'Вопрос',
  'UserQuestion/author': 'Автор',
  'UserQuestion/Date': 'Дата публикации',
});

i18n.addResources(LOCALES.EN, 'translation', {
  'UserQuestion/title': 'User question',
  'UserQuestion/ID': 'ID',
  'UserQuestion/course': 'Course',
  'UserQuestion/theme': 'Theme',
  'UserQuestion/description': 'Question',
  'UserQuestion/author': 'Author',
  'UserQuestion/Date': 'Date of publication',
});
