// Core
import styled, { css } from 'styled-components';

// Other
import { colors } from '../../../assets/styles';

export const Input = styled.input`
  width: 16px;
  height: 16px;
  border-radius: 11px;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 10px;
  line-height: 26px;
  color: ${({ checked }) => (checked ? colors.theme1 : colors.grey6)};
  padding-right: 25px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;
export const Form = styled.form`
  background-color: ${({ checked }) => (checked ? colors.theme4 : colors.grey2)};
  ${({ isError, withoutMargin }) => css`
    ${isError &&
      css`
        background-color: ${colors.error};
      `}
    ${!withoutMargin &&
      css`
        margin: 16px 16px 32px 0px;
      `}
  `};
  border-radius: 8px;
`;

export const WrapperInput = styled.div`
  padding: 4px 8px;
  display: flex;
`;

export const FlagIcon = styled.img`
  width: 24px;
  padding-right: 2px;
`;
