// Core
import styled from 'styled-components';

// Other
import { BREAKPOINTS } from '../../assets/styles';

export const LangIcon = styled.img`
  width: 53px;
  height: 53px;

  @media (min-width: ${BREAKPOINTS.SMALL}) {
    width: 70px;
    height: 70px;
  }
`;

export const ProgressWrapper = styled.div`
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;

  @media (min-width: ${BREAKPOINTS.SMALL}) {
    width: 80px;
    height: 80px;
  }
`;
