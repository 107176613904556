// Core
import React, { PureComponent } from 'react';
import { graphql } from '@apollo/react-hoc';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

// Components
import GradientButton from '../../components/Buttons/GradientButton';
import ScreenLoader from '../../components/ScreenLoader';
import Input from '../../components/Input';
import Checkbox from '../../components/Checkbox';
import MarkdownEditor from '../../components/MarkdownEditor';

// Mobx
import { APP_STORE } from '../../stores/constants';

// GraphQL
import INSERT_NEWS_MUTATION from '../../gql/mutation/news/insertNews';
import UPDATE_NEWS_MUTATION from '../../gql/mutation/news/updateNews';
import NEWS_QUERY from '../../gql/query/newsWhere';

// Other
import compose from '../../utils/compose';
import bugsnag from '../../external/bugsnag';
import { getFieldsToUpdate } from '../../utils/fieldsToUpdate';
import { apiFetch } from '../../utils/apiFetch';
import getToken from '../../utils/getFirebaseToken';

// constants
import { SEND_NEWS_EMAIL, SEND_NEWS_NOTIFICATION } from '../../constants';

// Styles and assets
import {
  ContentWrapper,
  PageTitle,
  HeaderRow,
  FormView,
  Icon,
  IconButton,
  UploadText,
  UploadWrapper,
  CheckboxText,
  Wrapper,
  InputWrapper,
  HeaderBlock,
  FlagIcon,
  FlagButton,
} from './styles';
import arrowBackIcon from '../../assets/images/arrow/arrow-back.png';
import imageIcon from '../../assets/images/news/image.png';
import ruFlagIcon from '../../assets/images/flags/ru-flag.png';
import ukFlagIcon from '../../assets/images/flags/uk-flag.png';

import './i18n';

@inject(APP_STORE)
@observer
class NewsEdit extends PureComponent {
  constructor(props) {
    super(props);
    const { t, i18n } = props;

    this.state = {
      pageTitle: `${t('News/Add/title')} / ${t('News/Add/newOne')}`,
      notifyUsers: false,
      sendPush: false,
      error: null,
      loading: false,
      title: '',
      title_en: '',
      description: '',
      description_en: '',
      pictureUrl: '',
      isRuLang: i18n.language === 'ru',
    };
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    const { news, i18n, t } = nextProps;
    const { isStateUpdated } = prevState;

    if (!isStateUpdated && news && news.length) {
      const { title, title_en, description, description_en, picture_url } = news[0];

      return {
        ...prevState,
        pageTitle: `${t('News/Add/title')} / ${i18n.language === 'ru' ? title : title_en}`,
        title,
        title_en,
        description,
        description_en,
        pictureUrl: picture_url,
        isStateUpdated: true,
      };
    }
    return prevState;
  };

  goBack = () => {
    const { history } = this.props;
    return history.goBack;
  };

  /**
   * Changes input value for provided input name
   * @param {string} name - input name
   */
  handleChangeValue = name => ({ target: { value } }) =>
    this.setState({ [name]: value, error: null });

  /**
   * Changes editor value for provided input name
   * @param {string} name - input name
   */
  handleChangeEditorValue = name => value => this.setState({ [name]: value, error: null });

  handleNotification = async (data, notifyUsers, key) => {
    if (notifyUsers) {
      const token = await getToken();
      return apiFetch({ key, method: 'POST', body: JSON.stringify(data), token });
    }
    return false;
  };

  handleSave = async () => {
    const {
      title,
      title_en,
      description,
      description_en,
      pictureUrl,
      notifyUsers,
      sendPush,
    } = this.state;
    const { insertNewsMutation, updateNewsMutation, t, news, history } = this.props;

    if (!title.trim() || !description.trim() || !title_en.trim() || !description_en.trim()) {
      return this.setState({ error: new Error(t('Error/emptyField')) });
    }
    this.setState({ loading: true });
    try {
      const newNewsData = {
        title,
        title_en,
        description,
        description_en,
        picture_url: pictureUrl,
      };
      if (!news) {
        await Promise.all([
          insertNewsMutation([newNewsData]),
          this.handleNotification({ news: newNewsData }, notifyUsers, SEND_NEWS_EMAIL),
          this.handleNotification(
            { notification: { en: title_en, ru: title } },
            sendPush,
            SEND_NEWS_NOTIFICATION
          ),
        ]);
      } else if (news && news[0].id) {
        const dataToUpdate = getFieldsToUpdate(newNewsData, news[0]);
        if (Object.keys(dataToUpdate).length) {
          await Promise.all([
            updateNewsMutation(news[0].id, dataToUpdate),
            this.handleNotification({ news: newNewsData }, notifyUsers, SEND_NEWS_EMAIL),
            this.handleNotification(
              { notification: { en: title_en, ru: title } },
              sendPush,
              SEND_NEWS_NOTIFICATION
            ),
          ]);
        }
      }
      history.goBack();
    } catch (error) {
      bugsnag.notify(error);
    }
    this.setState({ loading: false });
    return false;
  };

  /**
   * changes checkbox value
   * @param {string} check - name of bool field in the state
   */
  handleCheckboxToggle = check => () =>
    this.setState(prevState => ({
      [check]: !prevState[check],
    }));

  handleLanguageToggle = lang => () => this.setState({ isRuLang: lang });

  renderContent = () => {
    const { t } = this.props;
    const {
      error,
      pictureUrl,
      notifyUsers,
      sendPush,
      isRuLang,
      title,
      description,
      title_en,
      description_en,
    } = this.state;
    const typeDescription = isRuLang ? 'description' : 'description_en';
    const typeTitle = isRuLang ? 'title' : 'title_en';

    return (
      <FormView>
        <InputWrapper>
          <Input
            boldLabel
            value={isRuLang ? title : title_en}
            error={error}
            label={t('News/Add/name')}
            placeholder={t('News/Add/namePlaceholder')}
            onChange={this.handleChangeValue(typeTitle)}
          />
        </InputWrapper>
        <InputWrapper>
          <MarkdownEditor
            boldLabel
            key={`${typeDescription}_news`}
            error={error}
            label={t('News/Add/description')}
            value={isRuLang ? description : description_en}
            placeholder={t('News/Add/descriptionPlaceholder')}
            onChange={this.handleChangeEditorValue(typeDescription)}
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            boldLabel
            value={pictureUrl}
            label={t('News/Add/preview')}
            placeholder={t('News/Add/previewPlaceholder')}
            onChange={this.handleChangeValue('pictureUrl')}
          />
        </InputWrapper>
        <UploadWrapper>
          <Icon src={imageIcon} />
          <UploadText>{t('News/Add/photoUpload')}</UploadText>
        </UploadWrapper>
        <Wrapper>
          <Checkbox checked={notifyUsers} onClick={this.handleCheckboxToggle('notifyUsers')} />
          <CheckboxText>{t('News/Add/notifyUsers')}</CheckboxText>
        </Wrapper>
        <Wrapper>
          <Checkbox checked={sendPush} onClick={this.handleCheckboxToggle('sendPush')} />
          <CheckboxText>{t('News/Add/sendPush')}</CheckboxText>
        </Wrapper>
      </FormView>
    );
  };

  render() {
    const { t, history, match, news } = this.props;
    const { pageTitle, loading, isRuLang, error } = this.state;
    if (loading) {
      return <ScreenLoader />;
    }
    if (news && match.params.id && !news.length) {
      history.push('/404');
    }
    return (
      <ContentWrapper>
        <HeaderRow>
          <Wrapper>
            <IconButton onClick={this.goBack()}>
              <Icon src={arrowBackIcon} />
            </IconButton>
            <PageTitle>{pageTitle}</PageTitle>
          </Wrapper>
          <HeaderBlock>
            <div>
              <FlagButton
                onClick={this.handleLanguageToggle(true)}
                isToggled={isRuLang}
                isError={!isRuLang && error}
              >
                <FlagIcon src={ruFlagIcon} />
              </FlagButton>
              <FlagButton
                onClick={this.handleLanguageToggle(false)}
                isToggled={!isRuLang}
                isError={isRuLang && error}
              >
                <FlagIcon src={ukFlagIcon} />
              </FlagButton>
            </div>
            <GradientButton onClick={this.handleSave} width={200}>
              {t('News/Add/save')}
            </GradientButton>
          </HeaderBlock>
        </HeaderRow>
        {this.renderContent()}
      </ContentWrapper>
    );
  }
}

const newsQuery = graphql(NEWS_QUERY, {
  props: ({ data: { news, loading } }) => ({
    news,
    loading,
  }),
  options: ({ match }) => {
    const { id } = match.params;
    return {
      variables: {
        where: {
          id: { _eq: id },
        },
      },
      fetchPolicy: 'network-only',
    };
  },
  skip: ({ match }) => !match.params.id,
});

const insertNewsMutate = graphql(INSERT_NEWS_MUTATION, {
  props: ({ mutate }) => ({
    insertNewsMutation: async objects =>
      mutate({
        variables: { objects },
      }),
  }),
  options: {
    refetchQueries: ['News'],
  },
});

const updateNewsMutate = graphql(UPDATE_NEWS_MUTATION, {
  props: ({ mutate }) => ({
    updateNewsMutation: async (id, set) =>
      mutate({
        variables: { id, set },
      }),
  }),
  options: {
    refetchQueries: ['News'],
  },
});

const enhancer = compose(
  insertNewsMutate,
  updateNewsMutate,
  newsQuery,
  withTranslation()
);

NewsEdit.propTypes = {
  news: PropTypes.array, // for news edit
  history: PropTypes.object,
  match: PropTypes.object,
  insertNewsMutation: PropTypes.func, // creates news
  updateNewsMutation: PropTypes.func, // updates news
  t: PropTypes.func, // i18n translate func
  i18n: PropTypes.object,
};

export default enhancer(NewsEdit);
