// Stores
import userStore from './userStore';
import appStore from './appStore';

// Constants
import { USER_STORE, APP_STORE } from './constants';

export default {
  [USER_STORE]: userStore,
  [APP_STORE]: appStore,
};
