// Core
import React, { PureComponent } from 'react';
import { graphql } from '@apollo/react-hoc';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

// Components
import GradientButton from '../../components/Buttons/GradientButton';
import ScreenLoader from '../../components/ScreenLoader';
import Input from '../../components/Input';
import Checkbox from '../../components/Checkbox';

// Mobx
import { APP_STORE } from '../../stores/constants';

// GraphQL
import USERS_QUERY from '../../gql/query/users';

// Other
import compose from '../../utils/compose';
import bugsnag from '../../external/bugsnag';

// Styles and assets
import {
  ContentWrapper,
  PageTitle,
  HeaderRow,
  FormView,
  CheckboxText,
  Wrapper,
  ButtonsBlock,
  FlagButton,
  InputWrapper,
  FlagIcon,
} from './styles';
import ukFlagIcon from '../../assets/images/united-kingdom-flat-icon.png';
import ruFlagIcon from '../../assets/images/russia-flat-icon.png';

import './i18n';
import { ALLOWED_ROLES } from '../../constants';

@inject(APP_STORE)
@observer
class Notification extends PureComponent {
  constructor(props) {
    super(props);
    const { t, i18n } = props;

    this.state = {
      pageTitle: t('Notifications/Add/title'),
      title: '',
      description: '',
      title_en: '',
      description_en: '',
      notifyUsers: false,
      notifyAdmins: false,
      error: null,
      isRuLang: i18n.language === 'ru',
      loading: false,
    };
  }

  goBack = () => {
    const { history } = this.props;
    return history.goBack;
  };

  /**
   * Changes input value for provided input name
   * @param {string} name - input name
   */
  handleChangeValue = name => ({ target: { value } }) =>
    this.setState({ [name]: value, error: null });

  handleCheckboxToggle = (field1, value) => () => {
    this.setState({
      [field1]: !value,
    });
  };

  renderContent = () => {
    const { t } = this.props;
    const {
      title,
      description,
      error,
      notifyUsers,
      notifyAdmins,
      isRuLang,
      title_en,
      description_en,
    } = this.state;
    return (
      <FormView>
        <InputWrapper>
          <Input
            boldLabel
            value={isRuLang ? title : title_en}
            error={error}
            label={t('Notifications/Add/name')}
            placeholder={t('Notifications/Add/namePlaceholder')}
            onChange={this.handleChangeValue(isRuLang ? 'title' : 'title_en')}
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            boldLabel
            value={isRuLang ? description : description_en}
            error={error}
            label={t('Notifications/Add/description')}
            placeholder={t('Notifications/Add/descriptionPlaceholder')}
            onChange={this.handleChangeValue(isRuLang ? 'description' : 'description_en')}
          />
        </InputWrapper>
        <Wrapper>
          <Checkbox
            checked={notifyUsers}
            onClick={this.handleCheckboxToggle('notifyUsers', notifyUsers)}
          />
          <CheckboxText>{t('Notifications/Add/notifyUsers')}</CheckboxText>
        </Wrapper>
        <Wrapper>
          <Checkbox
            checked={notifyAdmins}
            onClick={this.handleCheckboxToggle('notifyAdmins', notifyAdmins)}
          />
          <CheckboxText>{t('Notifications/Add/notifyAdmins')}</CheckboxText>
        </Wrapper>
      </FormView>
    );
  };

  handleLanguageToggle = lang => () => this.setState({ isRuLang: lang });

  sendNotifications = async () => {
    const { users, t } = this.props;
    const { notifyAdmins, notifyUsers, title, description, title_en, description_en } = this.state;
    const usersWithoutNulls = users?.filter(item => {
      if (item.expo_push_tokens?.android || item.expo_push_tokens?.ios) {
        return item;
      }
      return false;
    });
    const adminsArray = usersWithoutNulls?.filter(item => ALLOWED_ROLES.includes(item?.role));

    if (!title.trim() || !description.trim() || !title_en.trim() || !description_en.trim()) {
      return this.setState({ error: new Error(t('Error/emptyField')) });
    }
    this.setState({ loading: true });
    try {
      if (notifyAdmins || notifyUsers) {
        await fetch('https://exp.host/--/api/v2/push/send', {
          mode: 'no-cors',
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: notifyAdmins
            ? JSON.stringify(
                adminsArray.map(item => {
                  return {
                    to: item.expo_push_tokens?.ios
                      ? item.expo_push_tokens.ios
                      : item.expo_push_tokens.android,
                    sound: 'default',
                    title: item.locale === 'en' ? title_en : title,
                    body: item.locale === 'en' ? description_en : description,
                  };
                })
              )
            : notifyUsers &&
              JSON.stringify(
                usersWithoutNulls.map(item => {
                  return {
                    to: item.expo_push_tokens?.ios
                      ? item.expo_push_tokens.ios
                      : item.expo_push_tokens.android,
                    sound: 'default',
                    title: item.locale === 'en' ? title_en : title,
                    body: item.locale === 'en' ? description_en : description,
                  };
                })
              ),
        });
      }
    } catch (error) {
      if (this._isMounted) {
        this.setState({ loading: false });
      }
      return bugsnag.notify(error);
    }
    return this.setState({ loading: false });
  };

  render() {
    const { t, history, match, news } = this.props;
    const { pageTitle, loading, isRuLang, error } = this.state;
    if (loading) {
      return <ScreenLoader />;
    }
    if (news && match.params.id && !news.length) {
      history.push('/404');
    }
    return (
      <ContentWrapper>
        <HeaderRow>
          <PageTitle>{pageTitle}</PageTitle>
          <ButtonsBlock>
            <Wrapper>
              <FlagButton
                onClick={this.handleLanguageToggle(true)}
                isToggled={isRuLang}
                isError={!isRuLang && error}
              >
                <FlagIcon src={ruFlagIcon} />
              </FlagButton>
              <FlagButton
                onClick={this.handleLanguageToggle(false)}
                isToggled={!isRuLang}
                isError={isRuLang && error}
              >
                <FlagIcon src={ukFlagIcon} />
              </FlagButton>
              <GradientButton width={200} onClick={this.sendNotifications}>
                {t('Notifications/Add/send')}
              </GradientButton>
            </Wrapper>
          </ButtonsBlock>
        </HeaderRow>
        {this.renderContent()}
      </ContentWrapper>
    );
  }
}

const getUsers = graphql(USERS_QUERY, {
  props: ({ data: { app_user, loading } }) => ({
    users: app_user,
    loading,
  }),
  options: {
    fetchPolicy: 'cache-and-network',
  },
});

const enhancer = compose(
  getUsers,
  withTranslation()
);

Notification.propTypes = {
  news: PropTypes.array, // for news edit
  history: PropTypes.object,
  match: PropTypes.object,
  users: PropTypes.array,
  t: PropTypes.func, // i18n translate func
  i18n: PropTypes.object,
};

export default enhancer(Notification);
