// Core
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { rem } from 'polished';
import { TableCell } from '../../components/Table';

// Other
import { colors } from '../../assets/styles';

export const ContentWrapper = styled.div`
  flex: 1;
  padding: 30px;
`;

export const PageTitle = styled.h1`
  font-weight: 700;
  font-size: ${rem(20)};
  line-height: ${rem(26)};
  color: ${colors.theme1};
`;

export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
`;

export const Icon = styled.img`
  width: 11px;
  margin-right: 5px;
`;

export const IdAndLogo = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid ${colors.theme4};
  padding-top: 10px;
  font-size: ${rem(12)};
  padding-bottom: 10px;
  text-align: left;
  color: ${colors.theme5};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Image = styled.img`
  width: 37px;
  height: 37px;
  border-radius: 50%;
`;

export const Link = styled(NavLink)`
  text-decoration: none;
`;

export const LinkAndButton = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  opacity: 0.5;
`;

export const IdOfInternshipProject = styled.p`
  font-weight: 700;

  align-self: center;
  color: ${colors.theme1};
`;

export const NameOfInternshipProject = styled.p`
  display: inline-block;
`;

export const ButtonsTd = styled.td`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: 20px;
  font-size: ${rem(12)};
  padding-bottom: 10px;
  text-align: right;
  border-bottom: 1px solid ${colors.theme4};
`;

export const StyledTableCell = styled(TableCell)`
  padding: 20px 10px;
`;
