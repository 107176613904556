// Core
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/react-hoc';

// GraphQL
import DELETE_THEME_MUTATION from '../../../gql/mutation/theme/deleteTheme';
import DELETE_QUESTION_MUTATION from '../../../gql/mutation/question/deleteQuestion';

// Components
import AlertButton from '../../../components/Buttons/AlertButton';

// Other
import compose from '../../../utils/compose';
import bugsnag from '../../../external/bugsnag';

// Styles and assets
import { AlertBtnsRow } from './styles';

class DeleteThemeModal extends PureComponent {
  state = {
    loading: false,
  };

  deleteTheme = async () => {
    const { deleteThemeMutation, deleteQuestionsMutation, closeModal, theme } = this.props;
    this.setState({ loading: true });
    try {
      const { id } = theme;
      await deleteQuestionsMutation(id);
      await deleteThemeMutation(id);
      closeModal();
    } catch (error) {
      bugsnag.notify(error);
    }
  };

  render() {
    const { t, closeModal } = this.props;
    const { loading } = this.state;
    return (
      <AlertBtnsRow>
        <AlertButton loading={loading} onClick={this.deleteTheme} text={t('App/yes')} />
        <AlertButton transparent onClick={closeModal} text={t('App/cancel')} />
      </AlertBtnsRow>
    );
  }
}

const deleteThemeMutate = graphql(DELETE_THEME_MUTATION, {
  props: ({ mutate }) => ({
    deleteThemeMutation: async id =>
      mutate({
        variables: { where: { id: { _eq: id } } },
      }),
  }),
  options: {
    refetchQueries: ['CourseWithThemes', 'Themes'],
  },
});

const deleteQuestionsMutate = graphql(DELETE_QUESTION_MUTATION, {
  props: ({ mutate }) => ({
    deleteQuestionsMutation: async id =>
      mutate({
        variables: { where: { theme_id: { _eq: id } } },
      }),
  }),
});

const enhancer = compose(
  withTranslation(),
  deleteThemeMutate,
  deleteQuestionsMutate
);

DeleteThemeModal.propTypes = {
  t: PropTypes.func, // i18n translate func
  deleteThemeMutation: PropTypes.func, // deletes current theme
  deleteQuestionsMutation: PropTypes.func, // deletes all questions connected with current theme
  closeModal: PropTypes.func, // closes the modal
  theme: PropTypes.object, // object with cur theme
};

export default enhancer(DeleteThemeModal);
