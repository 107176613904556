import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Label, TogglerWrapper, Input, Slider, LabelText } from './styles';

export default class Toggler extends Component {
  handleChange = () => {
    const { isChecked, onChange } = this.props;
    onChange(!isChecked);
  };

  render() {
    const { label, isChecked, width } = this.props;
    return (
      <TogglerWrapper width={width}>
        <Label>
          <Input type="checkbox" checked={isChecked} onChange={this.handleChange} />
          <Slider />
          <LabelText>{label}</LabelText>
        </Label>
      </TogglerWrapper>
    );
  }
}

Toggler.propTypes = {
  width: PropTypes.number,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
};
