// Core
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// Other
import {
  COURSES_ROUTE,
  INTERNSHIP_ROUTE,
  NEWS_ROUTE,
  // while we have no settings this field is commented
  // SETTINGS_ROUTE,
  USER_QUESTION,
  NOTIFICATION_ROUTE,
} from '../../constants';

// Styles and assets
import {
  SidebarContainer,
  SideBarItemWrapper,
  ItemTitle,
  SideBarIcon,
  ItemMarker,
  StyledLink,
} from './styles';

import coursesIcon from '../../assets/images/sidebar/courses@3x.png';
import coursesActiveIcon from '../../assets/images/sidebar/courses-active@3x.png';
import internshipIcon from '../../assets/images/sidebar/internship@3x.png';
import internshipActiveIcon from '../../assets/images/sidebar/internship-active@3x.png';
import newsIcon from '../../assets/images/sidebar/news@3x.png';
import newsActiveIcon from '../../assets/images/sidebar/news-active@3x.png';
// while we have no settings this field is commented
// import settingsIcon from '../../assets/images/sidebar/settings@3x.png';
// import settingsActiveIcon from '../../assets/images/sidebar/settings-active.png';
import notificationsIcon from '../../assets/images/sidebar/ic_notifications.png';
import notificationsActiveIcon from '../../assets/images/sidebar/ic-notifications-24-px@3x.png';
import userQuestion from '../../assets/images/sidebar/user-question.png';

import './i18n';

const SCREENS = [
  { name: COURSES_ROUTE, icon: coursesIcon, activeIcon: coursesActiveIcon },
  { name: USER_QUESTION, icon: userQuestion, activeIcon: userQuestion },
  { name: INTERNSHIP_ROUTE, icon: internshipIcon, activeIcon: internshipActiveIcon },
  { name: NEWS_ROUTE, icon: newsIcon, activeIcon: newsActiveIcon },
  // while we have no settings this field is commented
  // { name: SETTINGS_ROUTE, icon: settingsIcon, activeIcon: settingsActiveIcon },
  { name: NOTIFICATION_ROUTE, icon: notificationsIcon, activeIcon: notificationsActiveIcon },
];

const Sidebar = ({ activeScreen, t }) => {
  return (
    <SidebarContainer>
      {SCREENS.map(({ name, icon, activeIcon }) => {
        const isActive = activeScreen === name;
        return (
          <StyledLink key={name} disabled={isActive} to={`/admin/${name}`}>
            <SideBarItemWrapper active={isActive}>
              {isActive && <ItemMarker />}
              <SideBarIcon src={isActive ? activeIcon : icon} alt="" />
              <ItemTitle active={isActive}>{t(`SideBar/${name}`)}</ItemTitle>
            </SideBarItemWrapper>
          </StyledLink>
        );
      })}
    </SidebarContainer>
  );
};

Sidebar.propTypes = {
  activeScreen: PropTypes.string, // current active screen
  t: PropTypes.func, // i18n translate func
};

export default withTranslation()(Sidebar);
