import { gql } from 'apollo-boost';

export default gql`
  query UsersForRecruiting(
    $offset: Int!
    $limit: Int!
    $where: app_user_bool_exp
    $orderBy: [app_user_order_by!]
  ) {
    app_user(limit: $limit, offset: $offset, where: $where, order_by: $orderBy) {
      id
      name
      level
      updated_at
      picture_url
      average_response_time
      correct_answers_percentage
      user_courses {
        course_id
      }
    }
    users_count: app_user_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;
