import i18n, { LOCALES } from '../../external/i18n';

i18n.addResources(LOCALES.RU, 'translation', {
  'Pagination/SelectTextStart': 'Показать',
  'Pagination/SelectTextEnd': 'строк',
});

i18n.addResources(LOCALES.EN, 'translation', {
  'Pagination/SelectTextStart': 'Display',
  'Pagination/SelectTextEnd': 'rows',
});

export default i18n;
