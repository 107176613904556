import i18n, { LOCALES } from '../../external/i18n';

i18n.addResources(LOCALES.RU, 'translation', {
  'SignIn/authorizeWith': 'Авторизируйтесь с помощью:',
  'SignIn/hello': 'Привет, ',
  'SignIn/signIn': 'Войти',
  'SignIn/cancel': 'Отмена',
  'SignIn/authErrorTitle': 'Ошибка авторизации',
  'SignIn/authErrorText':
    'Пользователь с таким email уже зарегистрирован через {{formattedExistentProvider}}. Войдите через {{formattedExistentProvider}}, чтобы присоединить к нему {{formattedConnectProvider}}',
  'SignIn/notAdmin': 'Вы не имеете прав доступа администратора',
  'SignIn/noUser': 'Такого пользователя нет в базе данных',
});

i18n.addResources(LOCALES.EN, 'translation', {
  'SignIn/authorizeWith': 'Sign in with:',
  'SignIn/hello': 'Hello, ',
  'SignIn/signIn': 'Sign in',
  'SignIn/cancel': 'Cancel',
  'SignIn/authErrorTitle': 'Authentication error',
  'SignIn/authErrorText':
    'User with this email is already registered with {{formattedExistentProvider}}. Sign in with {{formattedExistentProvider}}, to connect {{formattedConnectProvider}}',
  'SignIn/notAdmin': "You don't have administrator privileges",
  'SignIn/noUser': 'There is no user in database',
});

export default i18n;
