// Core
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { graphql } from '@apollo/react-hoc';
import PropTypes from 'prop-types';

// Components
import AlertButton from '../../../components/Buttons/AlertButton';

// GraphQL
import DELETE_NEWS_MUTATION from '../../../gql/mutation/news/deleteNews';

// Other
import compose from '../../../utils/compose';
import bugsnag from '../../../external/bugsnag';

// Styles and assets
import { AlertBtnsRow } from './styles';

class DeleteNewsModal extends PureComponent {
  state = {
    loading: false,
  };

  deleteNews = async () => {
    const { deleteNewsMutation, closeModal, newsData } = this.props;
    this.setState({ loading: true });
    try {
      const { id } = newsData;
      await deleteNewsMutation(id);
      closeModal();
    } catch (error) {
      bugsnag.notify(error);
    }
  };

  render() {
    const { t, closeModal } = this.props;
    const { loading } = this.state;
    return (
      <AlertBtnsRow>
        <AlertButton loading={loading} onClick={this.deleteNews} text={t('App/yes')} />
        <AlertButton transparent onClick={closeModal} text={t('App/cancel')} />
      </AlertBtnsRow>
    );
  }
}

const deleteNewsMutate = graphql(DELETE_NEWS_MUTATION, {
  props: ({ mutate }) => ({
    deleteNewsMutation: async id =>
      mutate({
        variables: { id },
      }),
  }),
  options: {
    refetchQueries: ['News'],
  },
});

const enhancer = compose(
  withTranslation(),
  deleteNewsMutate
);

DeleteNewsModal.propTypes = {
  newsData: PropTypes.object,
  t: PropTypes.func, // i18n translate func
  deleteNewsMutation: PropTypes.func, // creates new News item
  closeModal: PropTypes.func, // closes the modal
};

export default enhancer(DeleteNewsModal);
