import React from 'react';

export const COLOR = 'COLOR';
export const BOLD = 'BOLD';

export default (text, components) => {
  const { [COLOR]: ColorComponent, [BOLD]: BoldComponent } = components;
  return text
    .split('*')
    .map((string, index, array) => {
      if (BOLD === array[index - 1] && BOLD === array[index + 1] && BoldComponent) {
        return <BoldComponent key={string}>{string}</BoldComponent>;
      }
      if (COLOR === array[index - 1] && COLOR === array[index + 1] && ColorComponent) {
        return <ColorComponent key={string}>{string}</ColorComponent>;
      }
      return string;
    })
    .filter(string => ![COLOR, BOLD].includes(string));
};
