import inviterIcon1 from '../../../assets/images/awards/inviter-1.png';
import inviterIcon2 from '../../../assets/images/awards/inviter-2.png';
import inviterIcon3 from '../../../assets/images/awards/inviter-3.png';

import friendlyIcon1 from '../../../assets/images/awards/friendly-1.png';
import friendlyIcon2 from '../../../assets/images/awards/friendly-2.png';
import friendlyIcon3 from '../../../assets/images/awards/friendly-3.png';

import starter from '../../../assets/images/awards/starter.png';

import streakIcon1 from '../../../assets/images/awards/streak-1.png';
import streakIcon2 from '../../../assets/images/awards/streak-2.png';
import streakIcon3 from '../../../assets/images/awards/streak-3.png';

import topRatedIcon1 from '../../../assets/images/awards/top-rated-1.png';
import topRatedIcon2 from '../../../assets/images/awards/top-rated-2.png';
import topRatedIcon3 from '../../../assets/images/awards/top-rated-3.png';

import liker_1 from '../../../assets/images/awards/like1.png';
import liker_2 from '../../../assets/images/awards/like2.png';
import liker_3 from '../../../assets/images/awards/like3.png';

// AWARDS
export const INVITER = 'inviter';
export const FRIENDLY = 'friendly';
export const STARTER = 'starter';
export const STREAK = 'streak';
export const LIKER = 'liker';
export const TOP_RATED = 'topRated';

const AWARDS = {
  [STREAK]: {
    images: [streakIcon1, streakIcon2, streakIcon3],
    titles: ['runner', 'sprinter', 'unstoppable'],
  },
  [INVITER]: {
    images: [inviterIcon1, inviterIcon2, inviterIcon3],
    titles: ['bro', 'mentor', 'chief'],
  },
  [FRIENDLY]: {
    images: [friendlyIcon1, friendlyIcon2, friendlyIcon3],
    titles: ['friendly', 'sociable', 'party'],
  },
  [STARTER]: {
    images: [starter],
    titles: ['starter'],
  },
  [TOP_RATED]: {
    images: [topRatedIcon1, topRatedIcon2, topRatedIcon3],
    titles: ['young', 'pro', 'champion'],
  },
  [LIKER]: {
    images: [liker_1, liker_2, liker_3],
    titles: ['highFive', 'trueExpert', 'bigFan'],
  },
};

/** Returns TOP_RATED award lvl
 * @param {number} coursesCount count of passed courses
 */
const getCurrentTopRatedLvl = coursesCount => {
  if (coursesCount >= 5) return 3;
  if (coursesCount >= 3) return 2;
  if (coursesCount >= 1) return 1;
  return 0;
};

/** returns a level of the icon, depending on the number of user's friends
 * @param {number} friendsCount  - user's friends count
 */
const getCurrentInviterLvl = friendsCount => {
  if (friendsCount >= 10) return 3;
  if (friendsCount >= 5) return 2;
  if (friendsCount >= 3) return 1;
  return 0;
};

/** returns a level of the icon, depending on the number of user's friends
 * @param {number} friendsCount - user's friends count
 */
const getCurrentFriendlyLvl = friendsCount => {
  if (friendsCount >= 20) return 3;
  if (friendsCount >= 10) return 2;
  if (friendsCount >= 5) return 1;
  return 0;
};

/**
 * Get all user's achieved awards data
 * @param {string} userAwards user achieved awards data
 * @param {string} friendsCount user`s friends count
 */
export const prepareAwardsData = (userAwards = {}, friendsCount) => {
  let awards = [];
  if (userAwards.STARTER) {
    awards = [{ type: STARTER, level: 1 }];
  }
  if (friendsCount >= 3) {
    awards = [...awards, { type: INVITER, level: getCurrentInviterLvl(friendsCount) }];
  }
  if (friendsCount >= 5) {
    awards = [
      ...awards,
      {
        type: FRIENDLY,
        level: getCurrentFriendlyLvl(friendsCount),
      },
    ];
  }
  if (userAwards.STREAK?.level) {
    const { level } = userAwards.STREAK;
    awards = [
      ...awards,
      {
        type: STREAK,
        level,
      },
    ];
  }
  if (userAwards.TOP_RATED?.length) {
    const topRatedCount = userAwards.TOP_RATED.length;
    awards = [
      ...awards,
      {
        type: 'topRated',
        level: getCurrentTopRatedLvl(topRatedCount),
      },
    ];
  }

  if (userAwards.LIKER?.level) {
    awards = [
      ...awards,
      {
        type: 'liker',
        level: userAwards.LIKER?.level,
      },
    ];
  }
  const currentAwards = [];
  awards.forEach(({ type, level }) => {
    const { titles, images } = AWARDS[type];
    images.forEach((image, index) => {
      if (index + 1 <= level)
        currentAwards.push({
          level,
          title: titles[index],
          description: `${type}Description${index}`,
          image,
        });
    });
  });
  const awardsChunks = [];
  for (let i = 0; i < currentAwards.length; i += 2) {
    awardsChunks.push(currentAwards.slice(i, i + 2));
  }
  return awardsChunks;
};
