// Core
import styled, { css } from 'styled-components';
import { rem } from 'polished';

// Components
import { StyledButton } from '../../components/Buttons/GradientButton/styles';

// Other
import { colors } from '../../assets/styles';

export const ContentWrapper = styled.div`
  flex: 1;
  padding: 30px;
`;

export const IconDefault = styled.img`
  margin-right: 5px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  ${StyledButton} {
    margin-right: 10px;
  }
`;

export const WrapperDatePicker = styled.div`
  margin: 10px 16px 22px 0px;
`;

export const WrapperRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${({ firstElem }) => (firstElem ? 30 : 16)}px;
  ${({ spaceBetween }) =>
    spaceBetween &&
    css`
      justify-content: space-between;
    `}
`;

export const Icon = styled.img`
  width: 24px;
  padding-right: 2px;
`;

export const CopyBtn = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  opacity: 0.4;
  margin-right: 44px;

  &:hover {
    opacity: 1;
  }
`;

export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledLabel = styled.label`
  font-weight: 700;
  font-size: ${rem(12)};
  color: ${colors.theme1};
`;

export const PageTitle = styled.h1`
  font-weight: 700;
  font-size: ${rem(20)};
  line-height: ${rem(26)};
  color: ${colors.theme1};
`;

export const ThemeBtn = styled.button`
  cursor: pointer;
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }
  &:disabled {
    pointer-events: none;
  }
`;

export const AddAnswerBtn = styled.button`
  border: 1px solid ${colors.theme1};
  border-radius: 33px;
  cursor: pointer;
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }
`;

export const AddAnswerText = styled.h1`
  font-weight: bolder;
  font-size: ${rem(12)};
  padding: 10px 25px;
  color: ${colors.theme1};
  cursor: pointer;
`;

export const CopyText = styled.h1`
  font-weight: bold;
  font-size: 10px;
  line-height: 18px;
  color: ${colors.grey6};
`;

export const CheckboxBtn = styled.button`
  margin: 0px 14px;
  cursor: pointer;
`;

export const WrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px 40px;
`;

export const DeleteBtn = styled.button`
  position: absolute;
  top: ${({ firstElem }) => (firstElem ? 30 : 18)}px;
  right: 60px;
  cursor: pointer;
  z-index: 1;
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }
  &:disabled {
    pointer-events: none;
  }
`;

export const FormView = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FormWrapper = styled.div`
  width: 60%;
`;

export const InputWrapper = styled.div`
  margin-bottom: 14px;
  ${({ withLeftMargin, withPaddingRight }) => css`
    ${withLeftMargin &&
      css`
        margin-left: 55px;
      `}}
    ${withPaddingRight &&
      css`
        padding-right: 40px;
      `}}
  `}
`;

export const CreateBtnIcon = styled.img`
  width: 12px;
`;
