export const CIF = 'CIF';
export const nHD = 'nHD';
export const qHD = 'qHD';
export const HD = 'HD';
export const FUL_HD = 'FUL_HD';

export const BREAKPOINTS = Object.freeze({
  CIF: 352,
  nHD: 640,
  qHD: 960,
  HD: 1280,
  FUL_HD: 1920,
  QHD: 3400,
});
