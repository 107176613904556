// Core
import styled, { css } from 'styled-components';
import { colors } from '../../../assets/styles';
import { TableCell } from '../../../components/Table';

export const ContentWrapper = styled.div`
  flex: 1;
  padding: 0 30px 30px;
`;

export const CreateBtnIcon = styled.img`
  ${({ marginRight }) =>
    marginRight &&
    css`
      margin-right: 8px;
    `}
  width: ${({ width }) => width || '12px'};
  margin-left: 10px;
`;

export const BodySection = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
`;

export const ThemeBtn = styled.button`
  cursor: pointer;
  opacity: 0.4;
  &:hover {
    opacity: 1;
  }
  &:disabled {
    pointer-events: none;
  }
`;

export const TextNumberTheme = styled.p`
  color: ${colors.theme1};
  font-weight: bold;
`;

export const StyledTableCell = styled(TableCell)`
  justify-content: center;
  align-items: center
    ${({ color }) =>
      color &&
      css`
        color: ${color};
        text-align: center;
        width: 100%;
        font-weight: bold;
      `};
`;
