/**
 * Composes many HOCs in one HOC for the Component
 * @param  {function[]} args list of HOCs
 * @returns {function} composed HOC function
 */
const compose = (...args) => {
  const hocs = args.reverse();

  return Component => {
    return hocs.slice(1).reduce((result, func) => func(result), hocs[0](Component));
  };
};

export default compose;
