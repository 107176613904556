import i18n from 'i18next';

// Other
import AWARDS_STAT_AGGREGATION from '../../gql/query/awardsStartAggregation';
import { BREAKPOINTS } from '../../assets/styles';
import { getUnit } from '../../utils/getUnit';
import client from '../../external/apollo';

export const SVG_SIZE = 350;
export const CHART_SIZE = SVG_SIZE - 50;
export const NUMBER_OF_SCALES = 5;

const t = i18n.t.bind(i18n);

export const formatTime = seconds => {
  const hour = Math.floor(seconds / 3600);
  const minute = Math.ceil((seconds - hour * 3600) / 60);
  const totalMinute = Math.floor((seconds - hour * 3600) / 60);
  const second = Math.floor(seconds - totalMinute * 60);

  const time = {
    hour,
    minute,
  };
  if (!seconds) {
    return `${seconds} ${getUnit(0, 'minute', t, true)}`;
  }
  return Object.keys(time)
    .filter(key => time[key])
    .map(key => {
      if (key === 'minute' && !totalMinute && second) {
        return `< ${time[key]} ${getUnit(time[key], key, t, true)}`;
      }
      return `${time[key]} ${getUnit(time[key], key, t, true)}`;
    })
    .join(' ');
};

export const getCurrentSizeScreen = () => {
  return Object.keys(BREAKPOINTS).find(key => {
    return window.innerWidth < +BREAKPOINTS[key].replace('px', '');
  });
};
export const countUserQuestion = questions =>
  questions.reduce((count, { user_questions = [] }) => count + user_questions.length, 0);

/**
 * Counts user_questions score from questions list
 * @param {object[]} questions list of questions to count their user_questions score
 * @param {string} field list of questions to count their user_questions score
 * @returns {number}
 */
export const countUserQuestionsField = (questions, field) =>
  questions.reduce((count, { user_questions = [] }) => {
    const currentUserQuestion = user_questions[0];
    if (field === 'total_time_spent' && currentUserQuestion && currentUserQuestion[field]) {
      return count + (currentUserQuestion[field] || currentUserQuestion.spent_time || 0);
    }
    if (currentUserQuestion && currentUserQuestion[field]) {
      return count + (currentUserQuestion[field] || 0);
    }
    return count;
  }, 0);

/**
 * count X coordinate for place value point
 * @param {number} angle - arrow angle
 * @param {number} distance - value
 */
export const polarToX = (angle, distance) => Math.cos(angle - Math.PI / 2) * distance;

/**
 * count Y coordinate for place value point
 * @param {number} angle - arrow angle
 * @param {number} distance - value
 */
export const polarToY = (angle, distance) => Math.sin(angle - Math.PI / 2) * distance;

/**
 * get point coordinates
 * @param {array} points - array of x and y coordinates of a point to draw a line
 */
export const getPoints = points =>
  points.map(point => `${point[0].toFixed(4)},${point[1].toFixed(4)}`).join(' ');

/**
 * Calculates theme passed percent (float)
 * @param {object} theme theme data
 * @returns {number} percent value
 */
const getThemePassedPercent = theme => {
  const themeScore = countUserQuestionsField(theme.questions, 'score');
  return (themeScore / theme.questions.length) * 100;
};

/**
 * Prepares themes data for chart
 * @param {object[]} themes user themes list
 * @param {string} locale user's locale
 */
export const getPreparedChartData = (themes, locale) => {
  let labels = [];
  let values = [];
  let valuesTime = [];
  let images = [];
  let correctAnswer = 0;
  let allTime = 0;
  let wrongAnswers = 0;

  themes.forEach(theme => {
    labels = [...labels, theme.names[`${locale}`]];
    // if theme doesn't contain any question - don't render it
    images = [...images, theme.picture_url];
    values = [
      ...values,
      theme.questions.length
        ? Math.round(getThemePassedPercent(theme)) // count percent score
        : 0,
    ];
    valuesTime = [...valuesTime, countUserQuestionsField(theme.questions, 'total_time_spent')];
    correctAnswer += countUserQuestionsField(theme.questions, 'successful_attempts');
    wrongAnswers += countUserQuestionsField(theme.questions, 'failed_attempts');
    allTime += countUserQuestionsField(theme.questions, 'total_time_spent');
  });

  return {
    labels,
    values,
    images,
    valuesTime,
    correctAnswer,
    wrongAnswers,
    allTime: formatTime(allTime),
  };
};

export const getAwardsUserStat = async awards_score => {
  const {
    data: { lower, total },
  } = await client.query({
    query: AWARDS_STAT_AGGREGATION,
    variables: { awardsScore: awards_score },
  });
  return Math.round((lower.aggregate.count / total.aggregate.count) * 100);
};
