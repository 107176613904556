// Core
import styled from 'styled-components';
import { rem } from 'polished';

// Other
import { colors } from '../../assets/styles';

/**
 * @param {number | undefined} fontSize
 */
export const AlertTitle = styled.h2`
  font-weight: 700;
  font-size: ${({ fontSize }) => rem(fontSize || 20)};
  color: ${colors.theme2};
  text-align: center;
`;

export const AlertDescription = styled.p`
  font-size: ${rem(18)};
  color: ${colors.grey4};
  text-align: center;
  margin-top: 10px;
`;

export const CloseBtn = styled.button`
  color: ${colors.grey3};
  font-size: ${rem(16)};
  line-height: ${rem(16)};
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }

  &:active {
    opacity: 0.8;
  }
`;
