// Core
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { colors } from '../../assets/styles';

export const ContentWrapper = styled.div`
  flex: 1;
  padding: 30px;
`;

export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PageTitle = styled.h1`
  font-weight: 700;
  font-size: ${rem(20)};
  line-height: ${rem(26)};
  color: ${colors.theme1};
`;

export const CreateBtnIcon = styled.img`
  ${({ marginRight }) =>
    marginRight &&
    css`
      margin-right: 8px;
    `}
  width: ${({ width }) => width || '12px'};
  margin-left: 10px;
`;

export const IconArrowBack = styled.img`
  margin-right: 5px;
`;

export const ThemeBtn = styled.button`
  cursor: pointer;
  opacity: 0.4;
  &:hover {
    opacity: 1;
  }
  &:disabled {
    pointer-events: none;
  }
`;

export const LastDailyQuestion = styled.div`
  color: ${colors.theme1};
  font-size: 12px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-left: 30px;
  margin-top: 30px;
  font-size: 12px;
`;

export const SwitchButton = styled.button`
  color: ${colors.theme1};
  font-size: 12px;
  border-color: ${colors.theme1};
  border-style: solid;
  border-width: 0;
  font-weight: bold;
  ${({ firstButton, isActive }) => css`
    ${firstButton &&
      css`
        margin-right: 10px;
      `}
    color: ${colors[isActive ? 'theme1' : 'gradient1']};
    border-bottom-width: ${isActive ? 2 : 0}px;
  `}
`;
