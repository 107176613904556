// Core
import React from 'react';
import PropTypes from 'prop-types';
import ClipLoader from 'react-spinners/ClipLoader';

// Other
import { FACEBOOK, GOOGLE, GITHUB } from '../../../constants';

// Styles and assets
import { StyledBtn, SocialIcon } from './styles';
import { colors } from '../../../assets/styles';
import facebookIcon from '../../../assets/images/auth/facebook.png';
import googleIcon from '../../../assets/images/auth/google.png';
import githubIcon from '../../../assets/images/auth/github.png';
import successIcon from '../../../assets/images/auth/checked.png';

const BTN_PROPS = {
  [FACEBOOK]: {
    title: 'Facebook',
    icon: facebookIcon,
  },
  [GOOGLE]: {
    title: 'Google',
    icon: googleIcon,
  },
  [GITHUB]: {
    title: 'GitHub',
    icon: githubIcon,
  },
};

const AuthBtn = ({ type, success, loading, disabled, onClick }) => {
  const { title, icon } = BTN_PROPS[type];
  return (
    <StyledBtn
      social={type}
      success={success}
      disabled={loading || disabled}
      withOpacity={disabled}
      onClick={onClick}
    >
      <SocialIcon src={success ? successIcon : icon} />
      {loading ? <ClipLoader size={30} color={colors.white} /> : title}
    </StyledBtn>
  );
};

AuthBtn.propTypes = {
  success: PropTypes.bool, // is authenticated with success
  loading: PropTypes.bool, // show loader or not
  disabled: PropTypes.bool, // is btn disabled
  onClick: PropTypes.func,
  type: PropTypes.oneOf([FACEBOOK, GOOGLE, GITHUB]), // btn type
};

export default AuthBtn;
