import { gql } from 'apollo-boost';

export default gql`
  query News($where: news_bool_exp) {
    news(where: $where) {
      id
      picture_url
      title
      title_en
      description
      description_en
      updated_at
    }
  }
`;
