// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Components
import Pagination from '../../../components/Pagination';
import ScreenLoader from '../../../components/ScreenLoader';

// Other
import enhancer from './enhancer';
import { COURSES_ROUTE, ITEMS_PER_PAGE } from '../../../constants';

// Styles and assets
import {
  ContentWrapper,
  CreateBtnIcon,
  BodySection,
  TextNumberTheme,
  ThemeBtn,
  StyledTableCell,
} from './styles';
import { Table, Thead, Tbody, TableRow, TableHeadCell, TableCell } from '../../../components/Table';

import deleteIcon from '../../../assets/images/courses/trash-active.png';
import editIcon from '../../../assets/images/courses/pencil-active.png';
import downwardIcon from '../../../assets/images/arrow/arrow-downward.png';
import upwardIcon from '../../../assets/images/arrow/arrow-upward.png';
import activeIcon from '../../../assets/images/active/active.png';
import activeHideIcon from '../../../assets/images/active/activeHide.png';

import './i18n';

class Themes extends PureComponent {
  handleThemeSwap = (type, theme) => e => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    const { updateThemeMutation, allThemes } = this.props;
    allThemes.forEach((item, index) => {
      if (item.id === theme.id) {
        if (type === 'up') {
          updateThemeMutation(item.id, { sort_order: allThemes[index - 1].sort_order });
          updateThemeMutation(allThemes[index - 1].id, { sort_order: item.sort_order });
        } else {
          updateThemeMutation(item.id, { sort_order: allThemes[index + 1].sort_order });
          updateThemeMutation(allThemes[index + 1].id, { sort_order: item.sort_order });
        }
      }
    });
  };

  handleElemTable = (courserId, themeId) => () => {
    const { history } = this.props;
    history.push(`/admin/${COURSES_ROUTE}/${courserId}/${themeId}`);
  };

  handleThemeBtnClick = (type, theme) => e => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    const { updateThemeMutation, showModal } = this.props;
    const { active, id } = theme;

    if (type === 'active') {
      updateThemeMutation(id, { active: !active });
      return;
    }

    showModal(type, theme)();
  };

  renderThemeIcons = ({ alt, disabled, ...iconProps }) => (
    <ThemeBtn key={alt} disabled={disabled}>
      <CreateBtnIcon alt={alt} {...iconProps} />
    </ThemeBtn>
  );

  renderThemeItem = (theme, index) => {
    const {
      currentPage,
      countThemes,
      course,
      course: { themes },
    } = this.props;
    const countPage = Math.ceil(countThemes / ITEMS_PER_PAGE);
    const { names, active } = theme;
    const ICONS_THEME = [
      {
        alt: 'active',
        width: '16px',
        src: active ? activeIcon : activeHideIcon,
        onClick: this.handleThemeBtnClick('active', theme),
      },
      {
        alt: 'edit',
        src: editIcon,
        onClick: this.handleThemeBtnClick('edit', theme),
      },
      {
        alt: 'delete',
        src: deleteIcon,
        onClick: this.handleThemeBtnClick('delete', theme),
      },
      {
        alt: 'up',
        src: upwardIcon,
        onClick: this.handleThemeSwap('up', theme),
        disabled: currentPage === 0 && index === 0,
      },
      {
        alt: 'down',
        src: downwardIcon,
        onClick: this.handleThemeSwap('down', theme),
        disabled: currentPage === countPage - 1 && index === themes.length - 1,
      },
    ];
    const params = {
      isOpenInTheme: true,
      active,
    };
    return (
      <TableRow key={`${names.ru}_${index}`} onClick={this.handleElemTable(course.id, theme.id)}>
        <TableCell isOpenInTheme active={active}>
          <TextNumberTheme>
            №{index + 1 + ITEMS_PER_PAGE * (currentPage + 1) - ITEMS_PER_PAGE}
          </TextNumberTheme>
        </TableCell>
        <StyledTableCell {...params}>{names.ru}</StyledTableCell>
        <StyledTableCell {...params}>{names.en}</StyledTableCell>
        <StyledTableCell {...params} isTextRight>
          {ICONS_THEME.map(this.renderThemeIcons)}
        </StyledTableCell>
      </TableRow>
    );
  };

  renderContent = () => {
    const {
      loadingCourses,
      loadingAllThemes,
      t,
      course: { themes },
    } = this.props;

    const headTable = [
      t('Theme/themeListSwitch'),
      t('Theme/nameTheme'),
      t('Theme/nameThemeEn'),
      '',
    ];
    if (loadingCourses || loadingAllThemes) {
      return <ScreenLoader />;
    }
    return (
      <BodySection>
        <Table gridColumns="50px auto auto 120px">
          <Thead>
            <TableRow>
              {headTable.map(item => (
                <TableHeadCell key={item}>{item}</TableHeadCell>
              ))}
            </TableRow>
          </Thead>
          <Tbody>{themes.map(this.renderThemeItem)}</Tbody>
        </Table>
      </BodySection>
    );
  };

  render() {
    const {
      countThemes,
      onClickPage,
      course: { themes = [] },
      loadingCourses,
    } = this.props;
    const countPage = Math.ceil(countThemes / ITEMS_PER_PAGE);

    return (
      <ContentWrapper>
        {this.renderContent()}
        {!loadingCourses && (
          <Pagination
            countPage={countPage}
            itemsCount={themes.length}
            onClickPage={onClickPage}
            itemsPerPage={ITEMS_PER_PAGE}
          />
        )}
      </ContentWrapper>
    );
  }
}

Themes.propTypes = {
  loadingCourses: PropTypes.bool, // is course loading now
  loadingAllThemes: PropTypes.bool, // is AllThemes loading now
  updateThemeMutation: PropTypes.func, // creates new course item
  countThemes: PropTypes.number, // number count themes
  course: PropTypes.object, // object course with themes
  history: PropTypes.object, // object with history route
  t: PropTypes.func, // i18n translate func
  currentPage: PropTypes.number, // number current page
  onClickPage: PropTypes.func, // open page func
  allThemes: PropTypes.array, // array of all themes
  showModal: PropTypes.func,
};

export default enhancer(Themes);
