// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Styles and assets
import {
  PickerLabel,
  HiddenInput,
  ChosenImage,
  TextLabel,
  PickerContent,
  PlusIcon,
  TextLabelWrapper,
} from './styles';

import plusIcon from '../../assets/images/grey-plus.png';

class ImagePicker extends PureComponent {
  state = {
    chosenImgUrl: null,
  };

  onImageChosen = ({ target: { files } }) => {
    const { onImageChosen } = this.props;
    const img = files[0];
    onImageChosen(img);
    if (URL) {
      this.setState({
        chosenImgUrl: URL.createObjectURL(img),
      });
    }
  };

  renderPickerContent = () => {
    const { imageLink, textLabel } = this.props;
    const { chosenImgUrl } = this.state;
    const imgUrl = chosenImgUrl || imageLink;

    if (imgUrl) {
      return <ChosenImage src={imgUrl} alt="" />;
    }
    return (
      <>
        <PlusIcon src={plusIcon} alt="" />
        {textLabel && (
          <TextLabelWrapper>
            <TextLabel>{textLabel}</TextLabel>
          </TextLabelWrapper>
        )}
      </>
    );
  };

  render() {
    const { acceptedFormats, disabled } = this.props;

    return (
      <PickerLabel disabled={disabled}>
        <PickerContent>{this.renderPickerContent()}</PickerContent>
        <HiddenInput
          disabled={disabled}
          type="file"
          onChange={this.onImageChosen}
          accept={acceptedFormats}
        />
      </PickerLabel>
    );
  }
}

// defaultProps
ImagePicker.defaultProps = {
  onImageChosen: () => {},
  acceptedFormats: 'png',
};

// PropTypes
ImagePicker.propTypes = {
  disabled: PropTypes.bool, // is picker disabled
  imageLink: PropTypes.string, // image link to show as chosen img
  textLabel: PropTypes.string, // text label value
  acceptedFormats: PropTypes.string, // accepted formats to pick to
  onImageChosen: PropTypes.func, // image choose handle
};

export default ImagePicker;
