import styled, { css } from 'styled-components';
import { colors } from '../../../assets/styles';
import { FUL_HD, nHD, CIF, qHD, HD } from '../constants';

const getWidth = (screen, type) => {
  if (type === 'item') {
    if (screen === qHD) {
      return '260px';
    }

    if ([HD, nHD, CIF, FUL_HD].includes(screen)) {
      return '100%';
    }
    return '400px';
  }
  if (type === 'wrapper') {
    if (screen === FUL_HD) {
      return '410px';
    }
    if ([HD, nHD, CIF, qHD].includes(screen)) {
      return '100%';
    }
    return '400px';
  }
  return '';
};

export const FilterItemWrapper = styled.div`
  min-height: 64px;
  display: flex;
  box-size: border-box;
  position: relative;
  padding: 32px 0px 17px 0px;
  border-bottom: ${({ withoutBorder }) => (withoutBorder ? 0 : 1)}px solid ${colors.grey15};
  ${({ sizeScreen }) => css`
    width: ${getWidth(sizeScreen, 'item')};
    flex-direction: ${[qHD, CIF].includes(sizeScreen) ? 'column' : 'row'};
    ${[qHD, CIF].includes(sizeScreen) &&
      css`
        align-items: center;
      `}
  `}
`;

export const BoxIcon = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-image: linear-gradient(to bottom, ${colors.pink3}, ${colors.pink4});
  ${({ sizeScreen }) => css`
    width: ${[qHD, CIF].includes(sizeScreen) ? 150 : 64}px;
    margin-${[qHD, CIF].includes(sizeScreen) ? 'bottom' : 'right'}: 24px;
  `}
`;

export const FilterWrapper = styled.div`
  display: flex;
  border-radius: 24px;
  box-shadow: 11px 35px 50px 0 ${colors.grey11};
  padding: 32px 32px 28px;
  background: white;
  margin-bottom: 78px;
  height: fit-content;
  position: relative;
  ${({ sizeScreen }) => css`
    width: ${getWidth(sizeScreen, 'wrapper')};
    flex-direction: ${[FUL_HD, nHD, CIF].includes(sizeScreen) ? 'column' : 'row'};
    ${![FUL_HD, nHD].includes(sizeScreen) &&
      css`
        align-items: flex-start;
        justify-content: space-around;
      `};
  `}
`;

export const TitleText = styled.p`
  position: absolute;
  opacity: 0.3;
  font-family: OpenSans;
  font-size: 16px;
  letter-spacing: -0.5px;
  font-weight: bolder;
  color: ${colors.blue7};
  top: 15px;
`;

export const Label = styled.div`
  color: ${colors.grey11};
  font-size: 16px;
  ${({ sizeScreen }) =>
    [nHD, qHD, CIF].includes(sizeScreen) &&
    css`
      text-align: center;
    `}
`;

export const OptionText = styled.p`
  display: inline-block;
  color: ${colors.blue7};
  font-family: OpenSansSemibold;
  font-size: 24px;
  padding: 8px 6px 9px 0px;
`;
export const OptionImg = styled.img`
  width: 45px;
  height: 45px;
  border-radius: 10px;
  margin: 9px 7px 0px ${({ isFirst }) => (isFirst ? 0 : 7)}px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`;

export const OptionsWrapper = styled.div`
  transform: translateY(-${({ height, filterOpen }) => (filterOpen ? 0 : height)}px);
  transition: all 0.4s ease-in-out;
`;

export const OptionsBlock = styled.div`
  margin-top: 15px;
  height: ${({ height }) => height || 0}px;
  transition: height 0.4s ease-in-out;
  overflow: hidden;
`;

export const OptionWrapper = styled.div`
  display: flex;
  padding: 12px 0px;
  align-items: center;
  cursor: pointer;
  & span {
    margin-left: ${({ isMultiSelect }) => (isMultiSelect ? 19 : 0)}px;
    font-size: 16px;
    line-height: 25px;
    font-family: ${({ checked }) => (checked ? 'OpenSansBold' : 'OpenSansSemibold')};
  }
`;

export const ArrowIcon = styled.img`
  position: absolute;
  width: 21px;
  height: 9px;
  filter: invert(1);
  transform: rotate(${({ isUp }) => (isUp ? 0 : 180)}deg);
  transition: transform 0.4s ease-in-out;
  ${({ sizeScreen }) =>
    ![FUL_HD, nHD].includes(sizeScreen)
      ? css`
          bottom: 0px;
          right: calc(50% - 10.5px);
        `
      : css`
          top: 32px;
          right: 10px;
        `}
`;

export const FilterBlock = styled.div`
  display: flex;
  flex: 1;
  padding: 0 34px;
  box-sizing: border-box;
`;
