// Core
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

// Components
import GradientButton from '../../components/Buttons/GradientButton';
import ScreenLoader from '../../components/ScreenLoader';
import Checkbox from '../../components/Checkbox';
import QuestionsMarkdownEditor from './QuestionsMarkdownEditor';
import RadioButton from './RadioButton';
import CopyAllQuestionModal from './CopyAllQuestionModal';
import DatePickerCustomInput from './DatePickerCustomInput';
import Toggler from '../../components/Toggler';
import Select from '../../components/Select';

// Mobx
import { APP_STORE } from '../../stores/constants';

// Other
import enhancer from './enhancer';
import bugsnag from '../../external/bugsnag';
import { SMALL_MODAL } from '../../constants';
import { getFieldsToUpdate } from '../../utils/fieldsToUpdate';
import { INITIAL_STATE, getDerivedState, levelOptions } from './helpers';

// Styles and assets
import {
  ContentWrapper,
  HeaderRow,
  Wrapper,
  ThemeBtn,
  IconDefault,
  PageTitle,
  FormWrapper,
  AddAnswerBtn,
  AddAnswerText,
  InputWrapper,
  FormView,
  CreateBtnIcon,
  StyledLabel,
  WrapperRow,
  DeleteBtn,
  CheckboxBtn,
  WrapperButton,
  CopyBtn,
  Icon,
  CopyText,
  WrapperDatePicker,
} from './styles';
import backIcon from '../../assets/images/arrow/arrow-back.png';
import ruFlagIcon from '../../assets/images/flags/ru-flag.png';
import ukFlagIcon from '../../assets/images/flags/uk-flag.png';
import deleteIcon from '../../assets/images/courses/trash-active.png';
import copyIcon from '../../assets/images/questions-add-screen/copy.png';

import './i18n';

class QuestionsEditScreen extends PureComponent {
  constructor(props) {
    super(props);
    const { i18n } = props;

    this.state = {
      ...INITIAL_STATE,
      isEnLang: i18n.language === 'en',
    };
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    const {
      question,
      match: {
        params: { questionId },
      },
    } = nextProps;
    const { isStateUpdated } = prevState;

    if (!isStateUpdated && questionId && question?.details)
      return getDerivedState(question, prevState);

    return null;
  };

  showCopyAllQuestionModal = handleCopyAll => {
    const {
      [APP_STORE]: { showModal, closeModal },
    } = this.props;
    showModal({
      size: SMALL_MODAL,
      content: <CopyAllQuestionModal closeModal={closeModal} handleButton={handleCopyAll} />,
    });
  };

  handleLanguageToggle = () => this.setState(prevState => ({ isEnLang: !prevState.isEnLang }));

  handleAddNewSolutions = () =>
    this.setState(prevState => ({
      solutions: prevState.solutions.concat([{ answer: '', isCorrect: false }]),
      solutions_en: prevState.solutions_en.concat([{ answer: '', isCorrect: false }]),
    }));

  handleDeleteSolutions = index => () => {
    const { solutions, solutions_en } = this.state;
    const newSolutions = [...solutions];
    const newSolutions_en = [...solutions_en];
    newSolutions.splice(index, 1);
    newSolutions_en.splice(index, 1);
    this.setState({
      solutions: newSolutions,
      solutions_en: newSolutions_en,
    });
  };

  goBack = () => {
    const { history } = this.props;
    return history.goBack;
  };

  handleChangeIsCorrect = (name, index, isCorrect) => () => {
    const { [name]: solutions } = this.state;
    const newSolutions = [...solutions];
    newSolutions[index].isCorrect = !isCorrect;
    this.setState({
      [name]: newSolutions,
    });
  };

  handleCopyValue = (name, copyName) => () =>
    this.setState(prevState => ({
      [copyName]: prevState[name],
    }));

  copyAllQuestion = () => {
    const { isEnLang } = this.state;
    if (isEnLang)
      return this.setState(prevState => ({
        description_en: prevState.description,
        explanation_en: prevState.explanation,
        solutions_en: prevState.solutions.map(item => ({ ...item })),
      }));
    return this.setState(prevState => ({
      description: prevState.description_en,
      explanation: prevState.explanation_en,
      solutions: prevState.solutions_en.map(item => ({ ...item })),
    }));
  };

  checkSolutions = solutions => solutions.some(item => item.answer);

  handleCopyAll = () => {
    const {
      solutions,
      solutions_en,
      description,
      description_en,
      explanation,
      explanation_en,
      isEnLang,
    } = this.state;
    if (isEnLang && (this.checkSolutions(solutions_en) || description_en || explanation_en))
      return this.showCopyAllQuestionModal(this.copyAllQuestion);
    if (!isEnLang && (this.checkSolutions(solutions) || description || explanation))
      return this.showCopyAllQuestionModal(this.copyAllQuestion);
    return this.copyAllQuestion();
  };

  handleCopySolutionsValue = (name, copyName, index) => () => {
    const { [name]: solutions, [copyName]: solutionsCopy } = this.state;
    const newSolutions = [...solutions];
    const newSolutionsCopy = [...solutionsCopy];
    newSolutionsCopy[index] = { ...newSolutions[index] };

    this.setState({
      [copyName]: newSolutionsCopy,
    });
  };

  /**
   * Changes for isDailyQuestion
   * @param {string} date - input date
   */
  onChangeCalendar = date => this.setState({ daily_question_date: date });

  /**
   * Toggle input value for provided input date
   */
  toggleDailyQuestion = () =>
    this.setState(({ isDailyQuestion }) => ({
      isDailyQuestion: !isDailyQuestion,
      daily_question_date: null,
    }));

  toggleInterviewQuestion = () =>
    this.setState(({ isInterviewQuestion }) => ({
      isInterviewQuestion: !isInterviewQuestion,
    }));

  toggleIsPremium = isPremiumQuestion => this.setState({ isPremiumQuestion });

  handleLevelSelect = level => this.setState({ level });

  /**
   * Changes input value for provided input name
   * @param {string} name - input name
   */
  handleChangeValue = name => value => this.setState({ [name]: value, error: null });

  /**
   * Changes input value for provided input name
   * @param {string} name - input name
   * @param {number} index - input index.js
   */
  handleChangeValueSolutions = (name, index) => value => {
    const { [name]: solutions } = this.state;
    const newSolutions = [...solutions];
    newSolutions[index].answer = value;
    this.setState({ [name]: newSolutions, error: null });
  };

  renderSolutions = (solution, index) => {
    const { t } = this.props;
    const { isEnLang, solutions, solutions_en } = this.state;
    const solutionLang = isEnLang ? 'solutions' : 'solutions_en';
    const solutionLangCopy = !isEnLang ? 'solutions' : 'solutions_en';
    const { answer, isCorrect } = solution;
    const lastSolution =
      solutions[solutions.length - 1] === solution ||
      solutions_en[solutions_en.length - 1] === solution;
    return (
      <InputWrapper key={index}>
        <Wrapper>
          <WrapperRow firstElem={index === 0}>
            <StyledLabel>{index + 1}.</StyledLabel>
            <CheckboxBtn>
              <Checkbox
                checked={isCorrect}
                onClick={this.handleChangeIsCorrect(solutionLang, index, isCorrect)}
              />
            </CheckboxBtn>
          </WrapperRow>
          <QuestionsMarkdownEditor
            boldLabel
            isCorrectAnswer={isCorrect}
            key={`${solutionLang}_${index}_question`}
            label={index === 0 ? t('Question/solution') : undefined}
            placeholder={t('Question/solutionPlaceholder')}
            value={answer}
            handleCopyValue={this.handleCopySolutionsValue(solutionLang, solutionLangCopy, index)}
            onChange={this.handleChangeValueSolutions(solutionLang, index)}
          />
          <DeleteBtn onClick={this.handleDeleteSolutions(index)} firstElem={index === 0}>
            <CreateBtnIcon alt="delete" src={deleteIcon} />
          </DeleteBtn>
        </Wrapper>
        {lastSolution && (
          <WrapperButton>
            <AddAnswerBtn onClick={this.handleAddNewSolutions}>
              <AddAnswerText>{t('Question/AddAnswerButton')}</AddAnswerText>
            </AddAnswerBtn>
          </WrapperButton>
        )}
      </InputWrapper>
    );
  };

  renderContent = () => {
    const {
      t,
      course: { has_daily_question, questions },
    } = this.props;
    const {
      error,
      isEnLang,
      description,
      description_en,
      solutions,
      solutions_en,
      explanation,
      explanation_en,
      isDailyQuestion,
      daily_question_date,
      isInterviewQuestion,
      isPremiumQuestion,
      level,
    } = this.state;
    // Get a ref directly to the DOM DatePicker:
    const ref = React.createRef();

    const typeExplanation = isEnLang ? 'explanation' : 'explanation_en';
    const typeExplanationCopy = !isEnLang ? 'explanation' : 'explanation_en';
    const typeDescription = isEnLang ? 'description' : 'description_en';
    const typeDescriptionCopy = !isEnLang ? 'description' : 'description_en';

    const questionsDailyDate = questions?.map(
      ({ daily_question_date: dailyQuestionDate }) => dailyQuestionDate
    );
    return (
      <FormView>
        <FormWrapper>
          <InputWrapper withLeftMargin>
            <WrapperRow spaceBetween>
              <WrapperRow>
                <RadioButton
                  label={t('Questions/TitleChangeLanguageRu')}
                  icon={ruFlagIcon}
                  handleSubmit={this.handleLanguageToggle}
                  isError={!isEnLang && error}
                  checked={isEnLang}
                />
                <RadioButton
                  label={t('Questions/TitleChangeLanguageEn')}
                  icon={ukFlagIcon}
                  handleSubmit={this.handleLanguageToggle}
                  isError={isEnLang && error}
                  checked={!isEnLang}
                />
              </WrapperRow>
              <CopyBtn onClick={this.handleCopyAll}>
                <Icon src={copyIcon} />
                <CopyText>{t('Question/CopyText')}</CopyText>
              </CopyBtn>
            </WrapperRow>
            <QuestionsMarkdownEditor
              boldLabel
              error={error}
              key={`${typeDescription}_question`}
              label={t('Question/title')}
              placeholder={t('Question/titlePlaceholder')}
              value={isEnLang ? description : description_en}
              onChange={this.handleChangeValue(typeDescription)}
              handleCopyValue={this.handleCopyValue(typeDescription, typeDescriptionCopy)}
            />
          </InputWrapper>
          {isEnLang ? solutions.map(this.renderSolutions) : solutions_en.map(this.renderSolutions)}
          <InputWrapper withLeftMargin>
            <QuestionsMarkdownEditor
              boldLabel
              key={`${typeExplanation}_question`}
              label={t('Question/explanation')}
              placeholder={t('Question/explanationPlaceholder')}
              value={isEnLang ? explanation : explanation_en}
              onChange={this.handleChangeValue(typeExplanation)}
              handleCopyValue={this.handleCopyValue(typeExplanation, typeExplanationCopy)}
            />
          </InputWrapper>
          <InputWrapper withLeftMargin withPaddingRight>
            <WrapperRow>
              <Select
                options={levelOptions}
                defaultValue={level}
                onChange={this.handleLevelSelect}
                placeholder={t('Question/SelectLevel')}
              />
            </WrapperRow>
            <WrapperRow spaceBetween>
              {has_daily_question && (
                <>
                  <RadioButton
                    withoutMargin
                    type="checkbox"
                    label={t('Questions/dailyQuestion')}
                    handleSubmit={this.toggleDailyQuestion}
                    isError={error}
                    checked={isDailyQuestion}
                  />
                  {isDailyQuestion && (
                    <WrapperDatePicker>
                      <DatePicker
                        dateFormat="yyyy-MM-dd"
                        onChange={this.onChangeCalendar}
                        selected={daily_question_date}
                        dayClassName={date => {
                          const itemDate = moment(date).format('YYYY-MM-DD');
                          const highlightTheDay = questionsDailyDate.includes(itemDate);
                          return highlightTheDay ? 'dateSelectedCourses' : undefined;
                        }}
                        customInput={
                          // eslint-disable-next-line react/jsx-wrap-multilines
                          <DatePickerCustomInput
                            placeholderText={t('Questions/dailyQuestion/placeHolder')}
                            ref={ref}
                          />
                        }
                      />
                    </WrapperDatePicker>
                  )}
                </>
              )}
              <Toggler
                width={98}
                isChecked={isPremiumQuestion}
                onChange={this.toggleIsPremium}
                label={t('Questions/dailyQuestion/premium')}
              />
            </WrapperRow>
            <WrapperRow>
              <RadioButton
                withoutMargin
                type="checkbox"
                label={t('Questions/interviewQuestion')}
                handleSubmit={this.toggleInterviewQuestion}
                isError={error}
                checked={isInterviewQuestion}
              />
            </WrapperRow>
          </InputWrapper>
        </FormWrapper>
      </FormView>
    );
  };

  handlePublish = async () => {
    const {
      updateQuestionMutation,
      match: {
        params: { questionId },
      },
    } = this.props;

    try {
      await updateQuestionMutation(questionId, {
        moderation_status: null,
      });
      await this.handleSave();
    } catch (error) {
      bugsnag.notify(error);
    }
  };

  handleReject = async () => {
    const {
      updateQuestionMutation,
      history,
      match: {
        params: { questionId },
      },
    } = this.props;

    try {
      await updateQuestionMutation(questionId, {
        deleted_at: moment(),
      });
      history.goBack();
    } catch (error) {
      bugsnag.notify(error);
    }
  };

  handleSave = async () => {
    const {
      description,
      description_en,
      explanation,
      explanation_en,
      solutions,
      solutions_en,
      daily_question_date,
      isInterviewQuestion,
      isPremiumQuestion,
      level,
    } = this.state;
    const {
      insertQuestionMutation,
      updateQuestionMutation,
      t,
      question,
      history,
      match: {
        params: { questionId, themeId, courserId },
      },
    } = this.props;

    if (!description.trim() || !description_en.trim()) {
      return this.setState({ error: new Error(t('Error/emptyField')) });
    }
    this.setState({ loading: true });

    try {
      const newQuestionData = {
        course_id: courserId,
        theme_id: themeId,
        details: {
          ru: {
            explanation: {
              text: explanation,
            },
            solutions,
            description,
          },
          en: {
            explanation: {
              text: explanation_en,
            },
            solutions: solutions_en,
            description: description_en,
          },
        },
        daily_question_date: moment(daily_question_date).add(
          moment(daily_question_date).utcOffset(),
          'minute'
        ),
        is_top_interview_question: isInterviewQuestion,
        is_locked_interview_question: isPremiumQuestion,
        level: level?.value || null,
      };
      if (!questionId) {
        await insertQuestionMutation([{ ...newQuestionData, created_at: moment() }]);
      } else if (questionId) {
        const dataToUpdate = getFieldsToUpdate(newQuestionData, question);
        if (Object.keys(dataToUpdate).length) {
          await updateQuestionMutation(questionId, { ...dataToUpdate, updated_at: moment() });
        }
      }
    } catch (error) {
      bugsnag.notify(error);
    }

    history.goBack();

    return this.setState({ loading: false });
  };

  render() {
    const {
      t,
      course: { title },
      theme: { names },
      question,
      match: {
        params: { questionId },
      },
      i18n,
    } = this.props;
    const { loading } = this.state;
    if (loading) {
      return <ScreenLoader />;
    }

    const isModeration = question?.moderation_status === 'PENDING';
    return (
      <ContentWrapper>
        <HeaderRow>
          <Wrapper>
            <ThemeBtn>
              <IconDefault src={backIcon} onClick={this.goBack()} />
            </ThemeBtn>
            <PageTitle>
              {title && title[i18n.language]} / {names} /{' '}
              {questionId ? t('Question/edit') + question?.id : t('Question/new')}
            </PageTitle>
          </Wrapper>
          <Wrapper>
            {isModeration && (
              <>
                <GradientButton isRed width={136} onClick={this.handleReject}>
                  {t('Questions/reject')}
                </GradientButton>
                <GradientButton width={136} onClick={this.handlePublish}>
                  {t('Questions/publish')}
                </GradientButton>
              </>
            )}
            <GradientButton width={136} onClick={this.handleSave}>
              {t('Questions/save')}
            </GradientButton>
          </Wrapper>
        </HeaderRow>
        {this.renderContent()}
      </ContentWrapper>
    );
  }
}

QuestionsEditScreen.propTypes = {
  insertQuestionMutation: PropTypes.func, // creates ques
  updateQuestionMutation: PropTypes.func, // updates ques
  t: PropTypes.func, // i18n translate func
  history: PropTypes.object, // object with history route
  course: PropTypes.object, // object course for this questions
  theme: PropTypes.object, // object theme for this questions
  question: PropTypes.object, // object current question
  i18n: PropTypes.object,
  match: PropTypes.object,
};

export default enhancer(QuestionsEditScreen);
