// Core
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { rem } from 'polished';

// Other
import { colors } from '../../assets/styles';

export const SidebarContainer = styled.div`
  padding-top: 40px;
  min-height: 100%;
  background-color: ${colors.theme4};
`;

export const StyledLink = styled(Link)`
  text-decoration: none;

  ${({ disabled }) => disabled && 'cursor: default;'}
`;

export const SideBarItemWrapper = styled.div`
  min-height: 48px;
  padding: 0 20px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  ${({ active }) =>
    active &&
    css`
      transition: background-color 600ms;
      background-color: ${colors.theme3};
      pointer-events: none;
    `}

  &:hover {
    opacity: 0.8;
  }
`;

export const SideBarIcon = styled.img`
  width: 20px;
  margin-right: 16px;
`;

export const ItemTitle = styled.p`
  line-height: ${rem(18)};
  font-size: ${rem(16)};

  color: ${({ active }) => colors[active ? 'theme1' : 'theme2']};
  ${({ active }) =>
    active &&
    css`
      font-weight: bold;
    `}
`;

export const ItemMarker = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 8px;
  background-color: ${colors.theme1};
`;
