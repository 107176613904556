// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { observer, inject } from 'mobx-react';

// Components
import Avatar from '../Avatar';

// Mobx
import { USER_STORE } from '../../stores/constants';

// Other
import firebase from '../../external/firebase';
import bugsnag from '../../external/bugsnag';

// Styles and assets
import {
  HeaderWrapper,
  HeaderLogo,
  ContentWrapper,
  HeaderTitle,
  AvatarRow,
  NameTitle,
  ArrowIcon,
  MenuBtn,
  MenuBlock,
} from './styles';
import { colors } from '../../assets/styles';
import logoIcon from '../../assets/images/coderslang-logo.svg';
import arrowDownIcon from '../../assets/images/arrow/arrow-down.png';

import './i18n';

@inject(USER_STORE)
@observer
class Header extends PureComponent {
  state = {
    isMenuShown: false,
  };

  closeListenerEnabled = false;

  componentDidUpdate(_, prevState) {
    const { isMenuShown } = this.state;
    // if menu shown we adding listener for close menu on outside click
    if (prevState.isMenuShown !== isMenuShown) {
      window[`${isMenuShown ? 'add' : 'remove'}EventListener`]('click', this.closeMenu);
      this.closeListenerEnabled = isMenuShown;
    }
  }

  componentWillUnmount() {
    if (this.closeListenerEnabled) {
      window.removeEventListener('click', this.closeMenu);
      this.closeListenerEnabled = false;
    }
  }

  /**
   * Closes menu on outside click
   */
  closeMenu = e => {
    if (
      e.target !== this.menuRef &&
      e.target.parentElement !== this.menuRef &&
      e.target !== this.menuBtnRef &&
      e.target.parentElement !== this.menuBtnRef
    ) {
      this.setState({ isMenuShown: false });
    }
  };

  /**
   * Saves element reference to the component
   */
  getRef = name => ref => {
    this[`${name}Ref`] = ref;
  };

  /**
   * Shows or closes header menu
   */
  toggleMenuShown = () => this.setState(({ isMenuShown }) => ({ isMenuShown: !isMenuShown }));

  /**
   * Signs out the user
   */
  signOut = async () => {
    const {
      [USER_STORE]: { clearUserData },
    } = this.props;
    try {
      await firebase.auth().signOut();
      clearUserData();
      this.setState({ isMenuShown: false });
    } catch (error) {
      bugsnag.notify(error);
    }
  };

  renderHeaderProfile = () => {
    const { t, stretch } = this.props;
    return (
      <HeaderWrapper isProfile>
        <ContentWrapper isProfile stretch={stretch}>
          <HeaderLogo isProfile src={logoIcon} alt="logo" />
          <HeaderTitle>{t('Header/title')}</HeaderTitle>
        </ContentWrapper>
      </HeaderWrapper>
    );
  };

  render() {
    const {
      t,
      stretch,
      showProfile,
      currentRoute,
      [USER_STORE]: { userData },
    } = this.props;
    const { isMenuShown } = this.state;

    if (userData && currentRoute === 'profile') {
      return this.renderHeaderProfile();
    }

    return (
      <HeaderWrapper>
        <ContentWrapper stretch={stretch}>
          <HeaderLogo src={logoIcon} alt="logo" />
          <HeaderTitle>{t('Header/title')}</HeaderTitle>
          {showProfile && !!userData && (
            <AvatarRow>
              <MenuBtn ref={this.getRef('menuBtn')} onClick={this.toggleMenuShown}>
                <NameTitle>
                  {userData.name || userData.email?.split('@')[0] || t('Profile/noName')}
                </NameTitle>
                <ArrowIcon src={arrowDownIcon} reverse={isMenuShown} />
              </MenuBtn>
              <Avatar size={40} borderColor={colors.white} url={userData.picture_url} />
              {isMenuShown && (
                <MenuBlock ref={this.getRef('menu')}>
                  <MenuBtn onClick={this.signOut}>{t('Header/logOut')}</MenuBtn>
                </MenuBlock>
              )}
            </AvatarRow>
          )}
        </ContentWrapper>
      </HeaderWrapper>
    );
  }
}

Header.defaultProps = {
  stretch: true,
};

Header.propTypes = {
  currentRoute: PropTypes.string,
  showProfile: PropTypes.bool, // show user avatar and name block
  stretch: PropTypes.bool, // is header stretch in window or has max-width: 1140 px for content
  t: PropTypes.func, // i18n translation func
};

export default withTranslation()(Header);
