import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { getUnit } from '../../../../utils/getUnit';

import { TextField } from '../styles';

const TextDateOnline = ({ date, t }) => {
  const [dateAgo, setDateAgo] = useState('');
  const nowMoment = moment.utc();
  const dateMoment = moment.utc(date);
  const formatMinutesLeft = nowMoment.diff(dateMoment, 'minutes');
  const formatHoursLeft = nowMoment.diff(dateMoment, 'hours');
  const formatDaysLeft = nowMoment.diff(dateMoment, 'days');
  const formatMountsLeft = nowMoment.diff(dateMoment, 'months');
  const formatYearLeft = nowMoment.diff(dateMoment, 'years');
  const minutesOnline = 1;
  const checkDate = () => {
    if (formatMinutesLeft < minutesOnline) {
      setDateAgo(t('App/online'));
    }
    if (formatMinutesLeft >= minutesOnline && formatMinutesLeft < 60) {
      setDateAgo(`${formatMinutesLeft} ${getUnit(formatMinutesLeft, 'minute', t)}`);
    }

    if (formatHoursLeft && !formatDaysLeft) {
      setDateAgo(`${formatHoursLeft} ${getUnit(formatHoursLeft, 'hour', t)}`);
    }
    if (formatDaysLeft && formatDaysLeft < 7) {
      setDateAgo(`${formatDaysLeft} ${getUnit(formatDaysLeft, 'day', t)}`);
    }
    if (formatDaysLeft >= 7 && !formatMountsLeft) {
      const week = Math.round(formatDaysLeft / 7);
      setDateAgo(`${week} ${getUnit(week, 'week', t)}`);
    }
    if (formatMountsLeft && !formatYearLeft) {
      setDateAgo(`${formatMountsLeft} ${getUnit(formatMountsLeft, 'month', t)}`);
    }
    if (formatYearLeft) {
      setDateAgo(`${formatYearLeft} ${getUnit(formatYearLeft, 'year', t)}`);
    }
  };

  useEffect(() => {
    checkDate();
  });

  return <TextField>{dateAgo}</TextField>;
};

TextDateOnline.propTypes = {
  date: PropTypes.string,
  t: PropTypes.func,
};

export default TextDateOnline;
