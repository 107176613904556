import i18n, { LOCALES } from '../../../external/i18n';

i18n.addResources(LOCALES.RU, 'translation', {
  'ActiveCourseModal/active/title': 'Скрыть курс ',
  'ActiveCourseModal/hasDailyQuestion/title': 'Скрыть ежедневные вопросы ',
  'ActiveCourseModal/active/description': 'Вы уверены, что хотите скрыть курс ',
  'ActiveCourseModal/hasDailyQuestion/description':
    'Вы уверены, что хотите скрыть ежедневные вопросы для ',
});

i18n.addResources(LOCALES.EN, 'translation', {
  'ActiveCourseModal/active/title': 'Hide course ',
  'ActiveCourseModal/hasDailyQuestion/title': 'Hide daily questions ',
  'ActiveCourseModal/active/description': 'Are you sure you want to hide course ',
  'ActiveCourseModal/hasDailyQuestion/description':
    'Are you sure you want to hide daily questions for',
});

export default i18n;
