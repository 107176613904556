import { gql } from 'apollo-boost';

export default gql`
  query Courses {
    courses: course(order_by: { id: asc }, where: { active: { _eq: true } }) {
      id
      title
      active
      picture_url
      picture_url_placeholder
      picture_url_dark
      picture_url_placeholder_dark
      has_daily_question
    }
  }
`;
