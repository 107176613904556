import { FACEBOOK, GITHUB, GOOGLE } from '../../constants';

export const colors = {
  white: '#ffffff',
  whiteOpacity10: 'rgba(0, 0, 0, 0.1)',
  whiteOpacity15: 'rgba(0, 0, 0, 0.15)',

  // theme colors
  theme1: '#610c98',
  theme2: '#8060d7',
  theme3: '#bea9f8',
  theme4: '#e7deff',
  theme5: '#1b1532',
  theme6: '#f5f2ff',

  gradient1: '#a389d4',
  gradient2: '#899ed4',
  gradient3: '#f73d85',
  gradient4: '#610c98',

  green: '#14ca7b',
  green1: '#9DEDC5',
  green2: '#76b926',

  btnGradient1: '#00dbaa',
  btnGradient2: '#3172a1',

  blue1: '#cad5f9',
  blue2: '#536dfe',
  blue3: 'blue',
  blue4: '#f3f7fe',
  blue5: '#43425d',
  blue6: '#A7C9FD',
  blue7: '#061058',
  blue8: '#8287AB',
  blue9: '#4d4f5c',

  progressGreen: '#18b752',
  pointCircleColor: '#fafafa',

  // grey colors
  grey1: '#ececec',
  grey2: '#eaeaea',
  grey3: '#999999',
  grey4: '#555555',
  grey5: '#dad6ca',
  grey6: '#707070',
  grey7: '#cbc9c9',
  grey8: '#b1b3b6',
  grey9: '#929292',
  grey10: '#cdd0e3',
  grey11: '#cfcfd5',
  grey12: '#f5f6fa',
  grey13: '#f1f1f3',
  grey14: '#c9c9c9',
  grey15: '#f0f0f6',
  grey16: '#f8f8fb',
  grey17: '#cfcfd559',

  // black colors
  black1: '#1b1532',

  // pink
  pink: '#feeef2',
  pink1: '#f25b7f',
  pink2: '#FFC0C8',
  pink3: '#ffeae3',
  pink4: '#ffd0c2',

  orange: '#fe9903',
  yellow: '#F4E677',

  // social colors
  [FACEBOOK]: '#4361a9',
  [GITHUB]: '#000000',
  [GOOGLE]: '#d34137',

  success: '#6AC259',
  error: 'red',

  // redesign colors
  blue: '#3072e4',
};

export const BREAKPOINTS = Object.freeze({
  SMALL: '576px',
  MEDIUM: '768px',
  LARGE: '992px',
  HD: '1200px',
  FUL_HD: '1920px',
});
export const chartColors = [
  '#8cce8a',
  '#fcadad',
  '#ffda83',
  '#fc98c7',
  '#fcc0b2',
  '#c9b5ee',
  '#8eb6fc',
  '#899ed4',
  '#bde4fe',
];
