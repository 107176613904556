// Core
import React from 'react';
import PropTypes from 'prop-types';

// Styles and assets
import { AvatarWrapper, AvatarImg } from './styles';
import placeholderIcon from '../../assets/images/avatar-placeholder.png';

const Avatar = ({ url, size, borderRadius, borderColor, isBorder = true }) => (
  <AvatarWrapper
    size={size}
    borderRadius={borderRadius}
    isBorder={isBorder}
    borderColor={borderColor}
  >
    <AvatarImg src={url || placeholderIcon} isPlaceholder={!url} alt="avatar" />
  </AvatarWrapper>
);

Avatar.propTypes = {
  borderColor: PropTypes.string, // custom border color
  url: PropTypes.string, // an url to the user's avatar pic
  size: PropTypes.number, // avatar size
  borderRadius: PropTypes.number, // avatar border radius
  isBorder: PropTypes.bool,
};

export default Avatar;
