// Core
import React from 'react';
import PacmanLoader from 'react-spinners/PacmanLoader';

// Styles and assets
import { LoaderWrapper } from './styles';
import { colors } from '../../assets/styles';

const ScreenLoader = () => {
  return (
    <LoaderWrapper>
      <PacmanLoader color={colors.gradient1} size={50} />
    </LoaderWrapper>
  );
};

export default ScreenLoader;
