// Core
import styled from 'styled-components';
import { rem } from 'polished';

// Other
import { BREAKPOINTS, colors } from '../../assets/styles';

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 100px 15px 38px;
  flex: 1;
  min-height: 100%;

  @media (min-width: ${BREAKPOINTS.SMALL}) {
    padding: 100px 30px 38px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ErrorTitle = styled.h1`
  font-size: ${rem(20)};
  line-height: ${rem(26)};
  color: ${colors.grey6};
  text-align: center;
`;

export const ErrorImage = styled.img`
  width: 80%;
  margin-top: 35px;
`;
