import i18n, { LOCALES } from '../../../external/i18n';

i18n.addResources(LOCALES.RU, 'translation', {
  'NewCourseModal/nameLabel': 'Курс:',
  'NewCourseModal/namePlaceholderRu': 'Название курса на русском',
  'NewCourseModal/namePlaceholderEn': 'Название курса на английском',
  'NewCourseModal/pictureLabel': 'Загрузить логотип в формате png',
  'NewCourseModal/picturePlaceholderLabel': 'Загрузить плейсхолдер в формате png',
  'NewCourseModal/pictureLabelDark': 'Загрузить логотип в формате png, для тёмной темы',
  'NewCourseModal/picturePlaceholderLabelDark':
    'Загрузить плейсхолдер в формате png, для тёмной темы',
});

i18n.addResources(LOCALES.EN, 'translation', {
  'NewCourseModal/nameLabel': 'Course:',
  'NewCourseModal/namePlaceholderRu': 'Course name in Russian',
  'NewCourseModal/namePlaceholderEn': 'Course name in English',
  'NewCourseModal/pictureLabel': 'Upload logo in png format',
  'NewCourseModal/picturePlaceholderLabel': 'Upload logo placeholder in png format',
  'NewCourseModal/pictureLabelDark': 'Upload logo in png format for dark theme',
  'NewCourseModal/picturePlaceholderLabelDark':
    'Upload logo placeholder in png format for dark theme',
});
