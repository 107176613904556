// Core
import i18n from 'i18next';
import truncate from 'lodash/truncate';

// Other
import { chartColors, colors } from '../../../assets/styles';
import { fontStyle } from './styles';
import { formatTime } from '../helpers';
import { SMALL, MEDIUM, LARGE, HD } from '../../../constants';
import { getUnit } from '../../../utils/getUnit';

const t = i18n.t.bind(i18n);
const getTitleProgress = () => t('Profile/progress');

export const getSizeChart = size => {
  if ([LARGE, HD, MEDIUM].includes(size)) {
    return {
      timeChart: 400,
      basicBar: 500,
      pic: 400,
      radar: 600,
    };
  }
  if ([SMALL].includes(size)) {
    return {
      timeChart: 300,
      basicBar: 315,
      pic: 315,
      radar: 320,
    };
  }
  return {};
};

export const optionsPicRates = (labels, totalAnswer) => ({
  labels,
  legend: {
    ...fontStyle,
    fontSize: 14,
    fontWeight: 'normal',
    show: true,
    showForNullSeries: true,
    showForZeroSeries: true,
    position: 'bottom',
    horizontalAlign: 'center',
    offsetX: 0,
    offsetY: 0,
    formatter: (seriesName, opts) => [seriesName, ' - ', opts.w.globals.series[opts.seriesIndex]],
    labels: {
      colors: colors.grey6,
    },
    markers: {
      width: 10,
      height: 10,
      strokeWidth: 0,
      radius: 10,
    },
    itemMargin: {
      horizontal: 20,
      vertical: 10,
    },
  },
  colors: chartColors.slice(0, 2),
  chart: {
    type: 'donut',
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      expandOnClick: false,
      donut: {
        background: 'transparent',
        size: '80%',
        labels: {
          show: true,
          name: {
            ...fontStyle,
          },
          value: {
            show: true,
            ...fontStyle,
          },
          total: {
            show: true,
            label: getUnit(totalAnswer, 'answer', t),

            ...fontStyle,
          },
        },
      },
    },
  },
});

export const optionTimeChart = labels => ({
  labels,
  markers: {
    size: 0,
    strokeColors: colors.white,
    strokeWidth: 2,
    strokeOpacity: 0.9,
    strokeDashArray: 0,
    fillOpacity: 1,
    discrete: [],
    shape: 'circle',
    radius: 2,
    offsetX: 0,
    offsetY: 0,
    showNullDataPoints: true,
    hover: {
      sizeOffset: 3,
    },
  },
  tooltip: {
    style: {
      fontSize: 14,
      fontFamily: 'OpenSans',
      color: colors.grey2,
    },
    y: {
      formatter: value => formatTime(value),
    },
  },
  legend: {
    ...fontStyle,
    show: false,
    showForNullSeries: true,
    showForZeroSeries: true,
    position: 'right',
    horizontalAlign: 'center',
    fontSize: 14,
    fontWeight: 'normal',
    width: 250,
    offsetX: -35,
    offsetY: -10,
    formatter: (seriesName, opts) => {
      const value = opts.w.globals.series[opts.seriesIndex];
      const name = truncate(seriesName, {
        length: 19,
      });
      return [name, `${formatTime(value)}`];
    },
    labels: {
      colors: colors.grey6,
    },
    markers: {
      width: 12,
      height: 12,
      radius: 10,
    },
    itemMargin: {
      horizontal: 0,
      vertical: 0,
    },
  },
  colors: chartColors,
  chart: {
    type: 'donut',
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      size: 243,
      expandOnClick: false,
      donut: {
        background: 'transparent',
        size: '50%',
        labels: {
          show: true,
          name: {
            show: false,
          },
          value: {
            show: false,
          },
          total: {
            show: false,
          },
        },
      },
    },
  },
});

export const optionsBasicBar = labels => ({
  colors: chartColors,

  chart: {
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: false,
  },
  plotOptions: {
    bar: {
      endingShape: 'flat',
      distributed: true,
    },
  },
  yaxis: {
    tickAmount: 4,
    min: 0,
    max: 100,
    labels: {
      style: {
        fontSize: 12,
        colors: colors.grey6,
        fontWeight: 'normal',
        fontFamily: 'OpenSans',
      },
      formatter: value => `${value}%`,
    },
    axisBorder: {
      show: true,
      color: colors.grey2,
    },
  },
  tooltip: {
    style: {
      fontSize: 14,
      fontFamily: 'OpenSans',
      color: colors.grey2,
    },
    x: {
      fontSize: '14px',
      fontFamily: 'OpenSans',
      color: colors.grey2,
      formatter: value => `${t('NewThemeModal/nameLabel')} ${value}`,
    },
    y: {
      title: {
        formatter: getTitleProgress,
      },
    },
  },
  xaxis: {
    type: 'category',
    axisBorder: {
      show: true,
      color: colors.grey2,
    },
    categories: labels,
    labels: {
      show: false,
    },
    legend: {
      show: false,
    },
    crosshairs: {
      show: false,
    },
  },
  fill: {
    opacity: 1,
  },
});

export const optionsRadar = labels => ({
  colors: [colors.gradient1],
  chart: {
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: true,
  },
  legend: {
    show: true,
  },
  plotOptions: {
    radar: {
      size: 110,
      offsetY: 5,
      polygons: {
        strokeColor: colors.grey2,
        fill: {
          colors: [colors.blue4, colors.white],
        },
      },
    },
  },
  yaxis: {
    show: false,
  },
  tooltip: {
    style: {
      fontSize: 14,
      fontFamily: 'OpenSans',
      color: colors.grey2,
    },
    x: {
      fontSize: '14px',
      fontFamily: 'OpenSans',
      color: colors.grey2,
      formatter: value => `${t('NewThemeModal/nameLabel')} ${value}`,
    },
    y: {
      title: {
        formatter: getTitleProgress,
      },
    },
  },
  xaxis: {
    type: 'category',
    axisBorder: {
      show: true,
      color: colors.grey2,
    },
    categories: labels,
    labels: {
      show: true,
      style: {
        ...fontStyle,
        fontSize: 14,
        fontWeight: 'normal',
      },
      formatter: value => {
        const name = truncate(value, {
          length: 27,
        });
        return name;
      },
    },
    legend: {
      show: false,
    },
    crosshairs: {
      show: false,
    },
  },
  fill: {
    opacity: 0.3,
  },
  responsive: [
    {
      breakpoint: 576,
      options: {
        xaxis: {
          labels: {
            formatter: value => {
              const name = truncate(value, {
                length: 13,
              });
              return name;
            },
          },
        },
        plotOptions: {
          radar: {
            size: 80,
          },
        },
      },
    },
  ],
});

export const optionsForTypeChart = {
  pic: optionsPicRates,
  basicBar: optionsBasicBar,
  timeChart: optionTimeChart,
  radar: optionsRadar,
};
