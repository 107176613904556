// Core
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { graphql } from '@apollo/react-hoc';
import PropTypes from 'prop-types';

// Components
import AlertButton from '../../../components/Buttons/AlertButton';

// GraphQL
import DELETE_COURSE_MUTATION from '../../../gql/mutation/course/deleteCourse';
import DELETE_THEME_MUTATION from '../../../gql/mutation/theme/deleteTheme';
import DELETE_QUESTION_MUTATION from '../../../gql/mutation/question/deleteQuestion';

// Other
import compose from '../../../utils/compose';
import bugsnag from '../../../external/bugsnag';

// Styles and assets
import { AlertBtnsRow } from './styles';

class DeleteCourseModal extends PureComponent {
  state = {
    loading: false,
  };

  deleteCourse = async () => {
    const {
      deleteCourseMutation,
      deleteThemesMutation,
      deleteQuestionsMutation,
      closeModal,
      courseData,
    } = this.props;
    this.setState({ loading: true });
    try {
      const { id } = courseData;
      await deleteQuestionsMutation(id);
      await deleteThemesMutation(id);
      await deleteCourseMutation(id);
      closeModal();
    } catch (error) {
      bugsnag.notify(error);
    }
  };

  render() {
    const { t, closeModal } = this.props;
    const { loading } = this.state;
    return (
      <AlertBtnsRow>
        <AlertButton loading={loading} onClick={this.deleteCourse} text={t('App/yes')} />
        <AlertButton transparent onClick={closeModal} text={t('App/cancel')} />
      </AlertBtnsRow>
    );
  }
}

const deleteCourseMutate = graphql(DELETE_COURSE_MUTATION, {
  props: ({ mutate }) => ({
    deleteCourseMutation: async id =>
      mutate({
        variables: { where: { id: { _eq: id } } },
      }),
  }),
  options: {
    refetchQueries: ['Courses'],
  },
});

const deleteThemesMutate = graphql(DELETE_THEME_MUTATION, {
  props: ({ mutate }) => ({
    deleteThemesMutation: async id =>
      mutate({
        variables: { where: { course_id: { _eq: id } } },
      }),
  }),
});

const deleteQuestionsMutate = graphql(DELETE_QUESTION_MUTATION, {
  props: ({ mutate }) => ({
    deleteQuestionsMutation: async id =>
      mutate({
        variables: { where: { course_id: { _eq: id } } },
      }),
  }),
});

const enhancer = compose(
  withTranslation(),
  deleteCourseMutate,
  deleteQuestionsMutate,
  deleteThemesMutate
);

DeleteCourseModal.propTypes = {
  courseData: PropTypes.object,
  t: PropTypes.func, // i18n translate func
  deleteCourseMutation: PropTypes.func, // deletes current course
  deleteThemesMutation: PropTypes.func, // deletes all themes connected with current course
  deleteQuestionsMutation: PropTypes.func, // deletes all questions connected with current course
  closeModal: PropTypes.func, // closes the modal
};

export default enhancer(DeleteCourseModal);
