import { createGlobalStyle } from 'styled-components';
import OpenSansRegular from '../fonts/OpenSans-Regular.ttf';
import OpenSansSemiBold from '../fonts/OpenSans-SemiBold.ttf';
import OpenSansBold from '../fonts/OpenSans-Bold.ttf';
import { colors } from '.';
/**
 * @description TODO: We do not use the GothamPro font because changed the layouts;
 */
const GlobalStyles = createGlobalStyle`

@font-face {
  font-family: 'OpenSansSemibold';
  src: url(${OpenSansSemiBold});
  font-weight: normal;
}

@font-face {
  font-family: 'OpenSansBold';
  src: url(${OpenSansBold});
  font-weight: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url(${OpenSansSemiBold});
  font-weight: bolder;
}

@font-face {
  font-family: 'OpenSans';
  src: url(${OpenSansBold});
  font-weight: bold;
}

@font-face {
  font-family: 'OpenSans';
  src: url(${OpenSansRegular});
  font-weight: normal;
}



* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.dateSelectedCourses {
  color: white !important;
  background: #aeaeae;
  font-weight: 800;
  border-radius: 3px;
 
}

.dateSelectedCourses:hover  {
  color: black !important;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6
a,
p, 
span, 
label,
td,
th,
div,
button,
input {
  font-family: 'OpenSans', sans-serif;
}

th {
  font-weight: bold;
}

html,
body,
#root {
  font-size: 16px;
  min-width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

#root {
  overflow-y: auto;
  &::-webkit-scrollbar-track {
    width: 7px;
  }

  &::-webkit-scrollbar {
    width: 7px;
    background-color: ${colors.grey1};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors.grey5};
    height: 15px;
    border-radius: 16px;
  }
}

button,
input {
  outline: 0;
  border: none;
  background: transparent;
}

/* modal animation styles */
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

`;

export default GlobalStyles;
