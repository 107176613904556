// Core
import React, { PureComponent } from 'react';
import { graphql } from '@apollo/react-hoc';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

// Components
import ScreenLoader from '../../components/ScreenLoader';
import GradientButton from '../../components/Buttons/GradientButton';

// Mobx
import { APP_STORE } from '../../stores/constants';

// GraphQL
import INTERNSHIP_QUERY from '../../gql/query/internship';
import COURSES_QUERY from '../../gql/query/courses';
import DELETE_INTERNSHIP_MUTATION from '../../gql/mutation/internship/deleteInternship';

// Other
import './i18n';
import compose from '../../utils/compose';
import bugsnag from '../../external/bugsnag';
import { getUnit } from '../../utils/getUnit';

// Styles and assets
import {
  ContentWrapper,
  ScreenName,
  HeaderSection,
  Icon,
  LinkAndButton,
  ButtonsTd,
  NameOfInternshipProject,
  Link,
  IdOfInternshipProject,
} from './styles';
import { Table, Thead, TableHeadCell, Tbody, TableRow, TableCell } from '../../components/Table';

import plusIcon from '../../assets/images/plus.png';
import pencilIcon from '../../assets/images/internship-screen/pencil-alt.png';
import trashIcon from '../../assets/images/internship-screen/trash-alt.png';

const KNOWLEDGE_LEVELS = ['Trainee', 'Junior', 'Middle', 'Senior'];

@inject(APP_STORE)
@observer
class Internship extends PureComponent {
  /**
   * Generate text data for ListItem
   * @param {object} item internship item
   * @returns {object} all text data for ListItem
   */
  prepareForRenderListItem = item => {
    const {
      i18n: { language },
      courses,
      t,
    } = this.props;
    const {
      interns_count,
      duration: { from, units, unitsCount },
      knowledge_level,
      programming_langs,
      id,
    } = item;
    const knowledgeLevelString = knowledge_level.map(level => KNOWLEDGE_LEVELS[level]).join(', ');

    // TODO: field from for interns_count must be delete later
    const internsCountString = interns_count?.from
      ? interns_count?.from || ''
      : interns_count?.quantity || '';

    // TODO: field from for duration must be delete later
    let durationString = ``;
    if (from) {
      durationString = `${from} ${getUnit(from, units, t)}`;
    }
    if (unitsCount) {
      durationString = `${unitsCount} ${getUnit(unitsCount, units, t)}`;
    }

    const programmingLangs = courses
      ?.filter(({ id: courseId }) => programming_langs?.includes(courseId))
      .map(({ title }) => title[language])
      .join(', ');

    return { knowledgeLevelString, internsCountString, durationString, programmingLangs, id };
  };

  /**
   * Generate text data for ListItem
   * @param {int} id internship id
   */
  deleteInternship = async id => {
    const { deleteInternshipMutation } = this.props;
    try {
      await deleteInternshipMutation(id);
    } catch (error) {
      bugsnag.notify(error);
    }
  };

  renderInternshipItem = item => {
    const { name, id } = item;
    const listItemConstants = this.prepareForRenderListItem(item);
    return (
      <TableRow key={`${name}_${id}`}>
        <TableCell>
          <IdOfInternshipProject>№{id}</IdOfInternshipProject>
          <NameOfInternshipProject>{name}</NameOfInternshipProject>
        </TableCell>
        <TableCell>{listItemConstants.programmingLangs}</TableCell>
        <TableCell>{listItemConstants.knowledgeLevelString}</TableCell>
        <TableCell>{listItemConstants.internsCountString}</TableCell>
        <TableCell>{listItemConstants.durationString}</TableCell>
        <ButtonsTd>
          <LinkAndButton>
            <Link
              to={{
                pathname: `internship/${id}`,
              }}
            >
              <Icon src={pencilIcon} />
            </Link>
            <button type="button" onClick={() => this.deleteInternship(id)}>
              <Icon src={trashIcon} />
            </button>
          </LinkAndButton>
        </ButtonsTd>
      </TableRow>
    );
  };

  render() {
    const { t, internship, loading } = this.props;
    const namesOfCells = [
      t('InternshipScreen/projectName'),
      t('InternshipScreen/programmingLanguages'),
      t('InternshipScreen/knowledgeLevel'),
      t('InternshipScreen/numberOfPersons'),
      t('InternshipScreen/projectDuration'),
    ];
    return (
      <ContentWrapper>
        <HeaderSection>
          <ScreenName>{t('InternshipScreen/title')}</ScreenName>
          <Link to="internship/InsertInternship">
            <GradientButton>
              <Icon src={plusIcon} />
              {t('InternshipScreen/createProject')}
            </GradientButton>
          </Link>
        </HeaderSection>
        {loading ? (
          <ScreenLoader />
        ) : (
          <Table
            gridColumns={`
              minmax(150px, 1.3fr)
              minmax(150px, 1.5fr)
              minmax(150px, 1.3fr)
              minmax(100px, 1fr)
              minmax(70px, 1fr)
              minmax(40px, 0.1fr);
            `}
          >
            <Thead>
              <TableRow>
                {namesOfCells.map(item => (
                  <TableHeadCell>{item}</TableHeadCell>
                ))}
                <TableHeadCell />
              </TableRow>
            </Thead>
            <Tbody>{internship?.map((item, id) => this.renderInternshipItem(item, id))}</Tbody>
          </Table>
        )}
      </ContentWrapper>
    );
  }
}

const internshipQuery = graphql(INTERNSHIP_QUERY, {
  props: ({ data: { internship, loading } }) => ({
    internship,
    loading,
  }),
  options: {
    fetchPolicy: 'cache-and-network',
  },
});

const coursesQuery = graphql(COURSES_QUERY, {
  props: ({ data: { courses, loading } }) => ({
    courses,
    loading,
  }),
  options: {
    fetchPolicy: 'cache-and-network',
  },
});

const deleteInternshipMutate = graphql(DELETE_INTERNSHIP_MUTATION, {
  props: ({ mutate }) => ({
    deleteInternshipMutation: async id =>
      mutate({
        variables: { id },
      }),
  }),
  options: {
    refetchQueries: ['Internships'],
  },
});

const enhancer = compose(
  coursesQuery,
  internshipQuery,
  deleteInternshipMutate,
  withTranslation()
);

Internship.propTypes = {
  loading: PropTypes.bool, // is courses loading now
  i18n: PropTypes.object,
  internship: PropTypes.arrayOf(PropTypes.object), // list of internships
  courses: PropTypes.arrayOf(PropTypes.object), // list of courses
  deleteInternshipMutation: PropTypes.func, // func fo delete internship in database
  t: PropTypes.func, // i18n translate func
};

export default enhancer(Internship);
