// Core
import React, { PureComponent } from 'react';

// Components
import Questions from './QuestionsPages';

class QuestionsRoute extends PureComponent {
  state = {
    currentPage: 0,
  };

  onClickPage = page => {
    this.setState({
      currentPage: page.selected,
    });
  };

  render() {
    const { currentPage } = this.state;
    return <Questions {...this.props} currentPage={currentPage} onClickPage={this.onClickPage} />;
  }
}

export default QuestionsRoute;
