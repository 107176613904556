// Core
import React from 'react';
import PropTypes from 'prop-types';
import ClipLoader from 'react-spinners/ClipLoader';

// Styles and assets
import { StyledButton } from './styles';
import { colors } from '../../../assets/styles';

const GradientButton = ({ children, isRed, loading, disabled, width, onClick, transparent }) => (
  <StyledButton
    isRed={isRed}
    onClick={onClick}
    width={width}
    disabled={loading || disabled}
    transparent={transparent}
  >
    {loading ? <ClipLoader size={26} color={colors.white} /> : children}
  </StyledButton>
);

GradientButton.propTypes = {
  isRed: PropTypes.bool,
  transparent: PropTypes.bool, // is btn transparent
  disabled: PropTypes.bool, // is btn disabled
  loading: PropTypes.bool, // show loader
  width: PropTypes.number, // custom width value
  onClick: PropTypes.func, // btn onclick handler
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.string])),
  ]),
};

export default GradientButton;
