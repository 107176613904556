import { gql } from 'apollo-boost';

export default gql`
  query TopQuestions($limit: Int!, $courseId: Int!, $offset: Int!) {
    question(
      limit: $limit
      offset: $offset
      order_by: { sort_order: asc }
      where: { is_top_interview_question: { _eq: true }, course_id: { _eq: $courseId } }
    ) {
      details
      is_locked_interview_question
      level
      id
      theme_id
    }
    question_aggregate(
      where: { is_top_interview_question: { _eq: true }, course_id: { _eq: $courseId } }
    ) {
      aggregate {
        count
      }
    }
  }
`;
