import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

/**
 * App locales
 */
export const LOCALES = Object.freeze({
  EN: 'en',
  RU: 'ru',
});

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      [LOCALES.RU]: {
        translation: {
          // global translates
          'App/save': 'Сохранить',
          'App/yes': 'Да',
          'App/cancel': 'Отмена',
          'App/lastDailyQuestion': 'Последний вопрос дня',

          // error translates
          'Error/emptyField': 'Пожалуйста, заполните это поле',
          'Error/isNotCorrect': 'Неверный код подтверждения',

          // bugsnag
          'Bugsnag/title': 'Ошибка',
          'Bugsnag/message': 'Что то пошло не так. Попробуйте пожалуйста позже.',
          'App/online': 'В сети',
          'App/name': 'Имя',
          'App/show': 'Показ.',
          // do not fix, for the logo
          Recruitin: 'Recruitin',
          'App/AvgResponseTime': 'Сред.время ответа',
          'Recruiting/findTalent': 'Найдите талант',
          'Recruiting/searchReport': 'Отчет о поиске',
          'Recruiting/searchParams': 'Параметры поиска',
          'Recruiting/selectCourse': 'Выбрать курс',
          'Recruiting/selectLevel': 'Выбрать уровень',
          'Recruiting/selectActivity': 'Выбрать активность',
          'Recruiting/language': 'Язык',
          'Recruiting/lastActivity': 'Последняя активность',
          'Recruiting/today': 'Cегодня',
          'Recruiting/lessTanWeek': 'Меньше недели',
          'Recruiting/lessThanMonth': 'Меньше месяца',
          'Recruiting/lessSixMonths': 'Менее шести месяцев',
          'Recruiting/lessThanYear': 'Меньше года',
          'Recruiting/jobSeekers': 'Соискатели',
          'Recruiting/weCouldntFoundIt': 'Мы не смогли ничего найти!',
          'Recruiting/notFoundDescription': 'Сожалеем, но ваш поиск не дал результатов',
          'Recruiting/correctAnswers': '% правильных ответов',
        },
      },
      [LOCALES.EN]: {
        translation: {
          // global translates
          'App/online': 'Online',
          'App/save': 'Save',
          'App/yes': 'Yes',
          'App/cancel': 'Cancel',
          'App/lastDailyQuestion': 'Last Daily Question',

          // error translates
          'Error/emptyField': 'Please, fill the field',
          'Error/isNotCorrect': 'Invalid confirmation code',

          // bugsnag
          'Bugsnag/title': 'Error',
          'Bugsnag/message': 'Something went wrong. Please, try again later.',
          // do not fix, for the logo
          Recruitin: 'Recruitin',
          'App/name': 'Name',
          'App/show': 'Show',
          'App/AvgResponseTime': 'Avg.response time',
          'Recruiting/findTalent': 'Find talent',
          'Recruiting/searchReport': 'Search Report',
          'Recruiting/searchParams': 'Search params',
          'Recruiting/selectLevel': 'Select Level',
          'Recruiting/selectCourse': 'Select Course',
          'Recruiting/selectActivity': 'Select activity',
          'Recruiting/language': 'Language',
          'Recruiting/lastActivity': 'Last activity',
          'Recruiting/today': 'Today',
          'Recruiting/lessTanWeek': 'Less than week',
          'Recruiting/lessThanMonth': 'Less than month',
          'Recruiting/lessSixMonths': 'Less than six months',
          'Recruiting/lessThanYear': 'Less than year',
          'Recruiting/jobSeekers': 'Job seekers',
          'Recruiting/weCouldntFoundIt': 'We couldn’t found it!',
          'Recruiting/notFoundDescription': 'We are sorry, but your search didn’t have any result',
          'Recruiting/correctAnswers': '% of correct answers',
        },
      },
    },
    defaultNS: 'translation',
    fallbackLng: LOCALES.EN,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    debug: true,
  });

export * from 'react-i18next';

export default i18n;
