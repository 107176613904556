// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';
import { inject, observer } from 'mobx-react';
import filter from 'lodash/filter';

// Components
import ScreenLoader from '../../../components/ScreenLoader';
import Pagination from '../../../components/Pagination';
import DeleteQuestionModal from '../../Questions/DeleteQuestion';

// Other
import enhancer from './enhancer';
import { sortParams } from './helpers';
import {
  ALERT_MODAL,
  COURSES_ROUTE,
  ITEMS_PER_PAGE,
  SMALL_MODAL,
  INTERN,
  JUNIOR,
  MIDDLE,
  SENIOR,
} from '../../../constants';
import { APP_STORE } from '../../../stores/constants';

// Styles and assets
import {
  ContentWrapper,
  CreateBtnIcon,
  BodySection,
  TextNumberTheme,
  ThemeBtn,
  StyledTableCell,
} from './styles';
import { Table, Thead, Tbody, TableRow, TableHeadCell, TableCell } from '../../../components/Table';

import deleteIcon from '../../../assets/images/courses/trash-active.png';
import editIcon from '../../../assets/images/courses/pencil-active.png';
import { colors } from '../../../assets/styles';

@inject(APP_STORE)
@observer
class TopQuestionsPages extends Component {
  handleElemTable = (courserId, themeId, questionId) => e => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const { history } = this.props;

    history.push(`/admin/${COURSES_ROUTE}/${courserId}/${themeId}/${questionId}/edit`);
  };

  handleChangeSelector = value => {
    const {
      [APP_STORE]: { changeCountQuestionsShow },
      onClickPage,
    } = this.props;

    onClickPage({ selected: 0 });
    changeCountQuestionsShow(value);
  };

  showDeleteQuestionModal = question => e => {
    const {
      t,
      [APP_STORE]: { showModal, closeModal },
    } = this.props;
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    showModal({
      type: ALERT_MODAL,
      size: SMALL_MODAL,
      content: <DeleteQuestionModal question={question} closeModal={closeModal} />,
      modalProps: {
        title: t('Questions/deleteTitle'),
        titleFontSize: 18,
      },
    });
  };

  renderThemeIcons = ({ alt, disabled, ...iconProps }) => (
    <ThemeBtn key={alt} disabled={disabled}>
      <CreateBtnIcon alt={alt} {...iconProps} />
    </ThemeBtn>
  );

  renderThemeItem = (question, index) => {
    const { match } = this.props;

    const { courserId } = match.params;

    const { details, active, theme_id, level, is_locked_interview_question } = question;
    const ICONS_THEME = [
      {
        alt: 'edit',
        src: editIcon,
        onClick: this.handleElemTable(courserId, theme_id, question.id),
      },
      {
        alt: 'delete',
        src: deleteIcon,
        onClick: this.showDeleteQuestionModal(question),
      },
    ];

    return (
      <TableRow
        key={`${details.ru.description}_${index}`}
        onClick={this.handleElemTable(courserId, theme_id, question.id)}
      >
        <TableCell isOpenInTheme active={active}>
          <TextNumberTheme>{question.id}</TextNumberTheme>
        </TableCell>
        <StyledTableCell>{details.ru.description}</StyledTableCell>
        <StyledTableCell>{details.en.description}</StyledTableCell>
        <StyledTableCell>{capitalize(level)}</StyledTableCell>
        <StyledTableCell color={colors[is_locked_interview_question ? 'green' : 'pink1']}>
          {is_locked_interview_question ? '+' : '-'}
        </StyledTableCell>
        <StyledTableCell isTextRight>{ICONS_THEME.map(this.renderThemeIcons)}</StyledTableCell>
      </TableRow>
    );
  };

  renderContent = () => {
    const { questionList, loadingQuestion, t } = this.props;

    const headTable = [
      t('Questions/id'),
      t('TopQuestion/description'),
      t('TopQuestion/descriptionEn'),
      t('TopQuestion/level'),
      t('Questions/dailyQuestion/premium'),
      '',
    ];
    if (loadingQuestion) {
      return <ScreenLoader />;
    }

    return (
      <BodySection>
        <Table gridColumns="50px auto auto 120px 70px 120px">
          <Thead>
            <TableRow>
              {headTable.map(item => (
                <TableHeadCell key={item}>{item}</TableHeadCell>
              ))}
            </TableRow>
          </Thead>
          <Tbody>
            {[
              ...filter(questionList, { level: INTERN }).sort(sortParams),
              ...filter(questionList, { level: JUNIOR }).sort(sortParams),
              ...filter(questionList, { level: MIDDLE }).sort(sortParams),
              ...filter(questionList, { level: SENIOR }).sort(sortParams),
            ].map(this.renderThemeItem)}
          </Tbody>
        </Table>
      </BodySection>
    );
  };

  render() {
    const {
      [APP_STORE]: {
        countQuestionsShow: { value },
        countQuestionsShow,
      },
      countQuestions,
      onClickPage,
      currentPage,
      loadingQuestion,
    } = this.props;
    const countPage = Math.ceil(countQuestions / value);

    const SELECT_OPTIONS = [
      { value: 10, label: '10' },
      { value: ITEMS_PER_PAGE, label: ITEMS_PER_PAGE },
      { value: 50, label: '50' },
      { value: 100, label: '100' },
      { value: countQuestions, label: 'ALL' },
    ];
    return (
      <ContentWrapper>
        {this.renderContent()}
        {!loadingQuestion && (
          <Pagination
            countPage={countPage}
            itemsPerPage={value}
            itemsCount={countQuestions}
            onClickPage={onClickPage}
            currentPage={currentPage}
            selectProps={{
              options: SELECT_OPTIONS,
              onChange: this.handleChangeSelector,
              defaultValue: countQuestionsShow,
            }}
          />
        )}
      </ContentWrapper>
    );
  }
}

TopQuestionsPages.propTypes = {
  loadingQuestion: PropTypes.bool, // is questions loading now
  countQuestions: PropTypes.number, // number count themes
  questionList: PropTypes.array, // object course with themes
  history: PropTypes.object, // object with history route
  t: PropTypes.func, // i18n translate func
  currentPage: PropTypes.number, // number current page
  onClickPage: PropTypes.func, // open page func
  showModal: PropTypes.func,
  match: PropTypes.object,
};

export default enhancer(TopQuestionsPages);
