// Core
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import headerBackground from '../../assets/images/bg-chart.svg';

// Other
import { colors, BREAKPOINTS } from '../../assets/styles';

export const HeaderTitle = styled.h2`
  font-size: 10px;
  color: ${colors.white};
`;

const profileHeaderStyle = css`
  height: 273px;
  background: linear-gradient(to bottom, ${colors.gradient1} 38%, ${colors.gradient2});
  &::after {
    content: '';
    background-image: url(${headerBackground});
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    top: 0px;
    left: 2px;
  }
`;

const profileLogoStyle = css`
  width: 113.3px;
  height: auto;
`;

const profileContentStyle = css`
  position: absolute;
  top: 32px;
  right: 48px;
  flex-direction: column;
  width: 113px;
  z-index: 10;
  & ${HeaderTitle} {
    margin-left: 0px;
  }
`;

export const HeaderWrapper = styled.header`
  display: flex;
  justify-content: center;
  padding: 7px 15px;
  width: 100%;
  position: relative;
  background: linear-gradient(268deg, ${colors.gradient1} 38%, ${colors.gradient2});
  ${({ isProfile }) => isProfile && profileHeaderStyle}
  @media (min-width: ${BREAKPOINTS.SMALL}) {
    padding: 7px 30px;
  }
`;

export const HeaderLogo = styled.img`
  width: 97.3px;
  height: 42.3px;
  ${({ isProfile }) => isProfile && profileLogoStyle}
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  & ${HeaderTitle} {
    margin-left: 9px;
  }
  ${({ stretch }) => !stretch && 'max-width: 1140px;'}
  ${({ isProfile }) => isProfile && profileContentStyle}
`;

export const AvatarRow = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`;

export const NameTitle = styled.p`
  font-size: ${rem(12)};
  color: ${colors.white};
  margin-right: 10px;
`;

export const ArrowIcon = styled.img`
  margin-top: 2px;
  width: 12px;
  transition: transform 300ms;
  ${({ reverse }) => reverse && 'transform: rotate(180deg);'}
  margin-right: 10px;
`;

export const MenuBtn = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${colors.theme1};
  font-size: ${rem(14)};
  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
`;

export const MenuBlock = styled.div`
  background-color: ${colors.theme4};
  position: absolute;
  right: 0;
  bottom: -7px;
  transform: translateY(100%);
  z-index: 10;
  padding: 20px;
  min-width: 180px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
