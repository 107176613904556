import { gql } from 'apollo-boost';

export default gql`
  query LastDailyQuestion($where: question_bool_exp) {
    question(where: $where, order_by: { daily_question_date: desc }, limit: 1) {
      id
      daily_question_date
    }
  }
`;
