// Core
import React from 'react';
import PropTypes from 'prop-types';

// Styles and Assets
import { StyledSimpleMDE, StyledLabel, ErrorTitle, Wrapper } from './styles';

const MarkdownEditor = ({ boldLabel, label, error, isCorrectAnswer, ...props }) => {
  const errorMsg = error?.message;
  return (
    <StyledLabel boldLabel={boldLabel}>
      {label}
      <Wrapper isCorrectAnswer={isCorrectAnswer}>
        <StyledSimpleMDE
          options={{
            toolbar: [
              'bold',
              'italic',
              'strikethrough',
              '|',
              'link',
              'code',
              'image',
              '|',
              'preview',
            ],
            minHeight: '30px',
            spellChecker: false,
            toolbarTips: false,
            blockStyles: { code: '`', bold: '**', italic: '*' },
            insertTexts: {
              image: ['<CODE><SNIPPET>', '<SNIPPET><CODE>'],
            },
          }}
          {...props}
        />
        {errorMsg && <ErrorTitle>{errorMsg}</ErrorTitle>}
      </Wrapper>
    </StyledLabel>
  );
};

MarkdownEditor.propTypes = {
  boldLabel: PropTypes.bool, // is input label bold
  isCorrectAnswer: PropTypes.bool,
  label: PropTypes.string,
  error: PropTypes.object, // error object
};

export default MarkdownEditor;
