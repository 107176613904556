// Core
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import GradientButton from '../../../components/Buttons/GradientButton';

// Styles and assets
import { AlertBtnsRow, TextCancel, Wrapper, Title, Description } from './styles';
import './i18n';

class ActiveCourseModal extends PureComponent {
  handleCopyQuestion = () => {
    const { closeModal, handleButton } = this.props;
    closeModal();
    handleButton();
  };

  render() {
    const {
      t,
      i18n: { language },
      closeModal,
      courseData: { title },
      type,
    } = this.props;
    return (
      <Wrapper>
        <Title>
          {t(`ActiveCourseModal/${type}/title`)}
          {title[language]}
        </Title>
        <Description>
          {t(`ActiveCourseModal/${type}/description`)}
          {title[language]}?
        </Description>
        <AlertBtnsRow>
          <GradientButton transparent width={192} onClick={closeModal}>
            <TextCancel> {t('App/cancel')}</TextCancel>
          </GradientButton>
          <GradientButton width={192} onClick={this.handleCopyQuestion}>
            {t(`ActiveCourseModal/${type}/title`)}
          </GradientButton>
        </AlertBtnsRow>
      </Wrapper>
    );
  }
}

ActiveCourseModal.propTypes = {
  t: PropTypes.func, // i18n translate func
  i18n: PropTypes.object, // i18n translate object
  closeModal: PropTypes.func, // closes the modal
  handleButton: PropTypes.func, // handle button the modal
  courseData: PropTypes.object,
  type: PropTypes.string, // type of content inside the modal
};

export default withTranslation()(ActiveCourseModal);
