import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  OptionWrapper,
  TextOption,
  SelectWrapper,
  OptionsWrapper,
  BlocSelected,
  ArrowWrapper,
} from './styles';

import { Icon } from '../../../components/Input/styles';
import arrowBottom from '../../../assets/images/internship-screen/ic-expand-more-24-px.png';

const Option = ({ value, option, onChange, handleOpenOptions, lastItem, firstItem }) => {
  const handleChange = () => {
    handleOpenOptions();
    onChange(option);
  };

  return (
    <OptionWrapper
      firstItem={firstItem}
      lastItem={lastItem}
      onClick={handleChange}
      isActive={value === option}
    >
      <TextOption isActive={value === option}>{option}</TextOption>
    </OptionWrapper>
  );
};

Option.propTypes = {
  value: PropTypes.number,
  option: PropTypes.number,
  onChange: PropTypes.func,
  handleOpenOptions: PropTypes.func,
  lastItem: PropTypes.bool,
  firstItem: PropTypes.bool,
};

const Select = ({ options, t, value, onChange, placeholder }) => {
  const [isOpen, setOpened] = useState(false);
  const handleOpenOptions = () => {
    setOpened(!isOpen);
  };

  return (
    <SelectWrapper>
      {isOpen && (
        <OptionsWrapper>
          {options.map((option, index, array) => (
            <Option
              handleOpenOptions={handleOpenOptions}
              onChange={onChange}
              value={value}
              option={option}
              firstItem={index === 0}
              lastItem={index + 1 === array?.length}
            />
          ))}
        </OptionsWrapper>
      )}
      <BlocSelected isOpen={isOpen} onClick={handleOpenOptions}>
        <TextOption>{`${t('App/show')} ${value}` || placeholder}</TextOption>
      </BlocSelected>
      <ArrowWrapper onClick={handleOpenOptions}>
        <Icon isDown={isOpen} src={arrowBottom} />
      </ArrowWrapper>
    </SelectWrapper>
  );
};

Select.propTypes = {
  options: PropTypes.array,
  value: PropTypes.number,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  t: PropTypes.func, // i18n translation func
};

export default Select;
