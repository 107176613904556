// Core
import React, { PureComponent } from 'react';
import { graphql } from '@apollo/react-hoc';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

// Components
import ScreenLoader from '../../components/ScreenLoader';
import GradientButton from '../../components/Buttons/GradientButton';
import EditCourseModal from './EditCourseModal';
import DeleteCourseModal from './DeleteCourseModal';
import ActiveCourseModal from './ActiveCourseModal';

// Mobx
import { APP_STORE } from '../../stores/constants';

// GraphQL
import UPDATE_COURSE_MUTATION from '../../gql/mutation/course/updateCourse';
import UPDATE_THEME_MUTATION from '../../gql/mutation/theme/updateCurCourseTheme';
import COURSES_QUERY from '../../gql/query/courses';

// Other
import compose from '../../utils/compose';
import { SMALL_MODAL, ALERT_MODAL, COURSES_ROUTE } from '../../constants';

// Styles and assets
import {
  ContentWrapper,
  PageTitle,
  HeaderRow,
  CreateBtnIcon,
  CourseItem,
  CourseIcon,
  CourseBtnsContainer,
  CourseBtn,
  CourseBtnIcon,
  StyledLink,
} from './styles';

import plusIcon from '../../assets/images/plus.png';
import deleteIcon from '../../assets/images/courses/trash-active.png';
import editIcon from '../../assets/images/courses/pencil-active.png';
import activeIcon from '../../assets/images/active/active.png';
import dailyQuesNoActiveIcon from '../../assets/images/courses/daily-question-noactive.png';
import dailyQuesActiveIcon from '../../assets/images/courses/daily-question-active.png';
import activeHideIcon from '../../assets/images/active/activeHide.png';

import './i18n';

@inject(APP_STORE)
@observer
class Courses extends PureComponent {
  showModal = (type, courseData) => () => {
    const {
      t,
      [APP_STORE]: { showModal, closeModal },
      updateCourseMutation,
    } = this.props;
    switch (type) {
      case 'delete':
        return showModal({
          type: ALERT_MODAL,
          size: SMALL_MODAL,
          content: <DeleteCourseModal courseData={courseData} closeModal={closeModal} />,
          modalProps: {
            title: t('Courses/deleteTitle'),
          },
        });
      case 'edit':
        return showModal({
          content: (
            <EditCourseModal
              courseData={courseData}
              closeModal={closeModal}
              updateCourseMutation={updateCourseMutation}
            />
          ),
          size: SMALL_MODAL,
        });
      case 'active':
        return showModal({
          content: (
            <ActiveCourseModal
              type="active"
              courseData={courseData}
              closeModal={closeModal}
              handleButton={this.changeCourseActive(courseData)}
            />
          ),
          size: SMALL_MODAL,
        });
      case 'hasDailyQuestion':
        return showModal({
          content: (
            <ActiveCourseModal
              type="hasDailyQuestion"
              courseData={courseData}
              closeModal={closeModal}
              handleButton={this.changeCourseDailyQuesActive(courseData)}
            />
          ),
          size: SMALL_MODAL,
        });
      default:
        return null;
    }
  };

  changeCourseActive = ({ id, active }) => () => {
    const { updateCourseMutation, updateThemeMutation } = this.props;
    updateCourseMutation(id, { active: !active });
    updateThemeMutation(id, { active: !active });
  };

  changeCourseDailyQuesActive = ({ id, has_daily_question }) => () => {
    const { updateCourseMutation } = this.props;
    updateCourseMutation(id, { has_daily_question: !has_daily_question });
  };

  handleCourseBtnClick = (type, courseData) => e => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    const { active, has_daily_question } = courseData;

    if (type === 'active' && !active) {
      this.changeCourseActive(courseData)();
      return;
    }
    if (type === 'hasDailyQuestion' && !has_daily_question) {
      this.changeCourseDailyQuesActive(courseData)();
      return;
    }
    this.showModal(type, courseData)();
  };

  renderCourseIcons = ({ alt, ...iconProps }) => (
    <CourseBtn key={alt}>
      <CourseBtnIcon alt={alt} {...iconProps} />
    </CourseBtn>
  );

  renderCourseItem = courseData => {
    const { picture_url, picture_url_placeholder, id, active, has_daily_question } = courseData;
    const ICONS_COURSE = [
      {
        alt: 'hasDailyQuestion',
        width: '13px',
        src: has_daily_question ? dailyQuesActiveIcon : dailyQuesNoActiveIcon,
        onClick: this.handleCourseBtnClick('hasDailyQuestion', courseData),
      },
      {
        alt: 'active',
        width: '16px',
        src: active ? activeIcon : activeHideIcon,
        onClick: this.handleCourseBtnClick('active', courseData),
      },
      {
        alt: 'edit',
        src: editIcon,
        onClick: this.handleCourseBtnClick('edit', courseData),
      },
      {
        alt: 'delete',
        src: deleteIcon,
        onClick: this.handleCourseBtnClick('delete', courseData),
      },
    ];

    return (
      <StyledLink key={`course_${id}`} to={`/admin/${COURSES_ROUTE}/${id}`}>
        <CourseItem active={active}>
          <CourseBtnsContainer>{ICONS_COURSE.map(this.renderCourseIcons)}</CourseBtnsContainer>
          <CourseIcon src={active ? picture_url : picture_url_placeholder} />
        </CourseItem>
      </StyledLink>
    );
  };

  renderContent = () => {
    const { courses, loading } = this.props;
    if (loading) {
      return <ScreenLoader />;
    }
    return <div>{courses.map(this.renderCourseItem)}</div>;
  };

  render() {
    const { t } = this.props;
    return (
      <ContentWrapper>
        <HeaderRow>
          <PageTitle>{t('Courses/title')}</PageTitle>
          <GradientButton onClick={this.showModal('edit')}>
            <CreateBtnIcon src={plusIcon} />
            {t('Courses/createNew')}
          </GradientButton>
        </HeaderRow>
        {this.renderContent()}
      </ContentWrapper>
    );
  }
}

const coursesQuery = graphql(COURSES_QUERY, {
  props: ({ data: { courses, loading } }) => ({
    courses,
    loading,
  }),
  options: {
    fetchPolicy: 'network-only',
  },
});

const updateCourseMutate = graphql(UPDATE_COURSE_MUTATION, {
  props: ({ mutate }) => ({
    updateCourseMutation: async (id, set) =>
      mutate({
        variables: { id, set },
      }),
  }),
  options: {
    refetchQueries: ['Courses'],
  },
});

const updateThemeMutate = graphql(UPDATE_THEME_MUTATION, {
  props: ({ mutate }) => ({
    updateThemeMutation: async (id, set) =>
      mutate({
        variables: { id, set },
      }),
  }),
  options: {
    refetchQueries: ['Courses'],
  },
});

const enhancer = compose(
  coursesQuery,
  updateCourseMutate,
  updateThemeMutate,
  withTranslation()
);

Courses.propTypes = {
  loading: PropTypes.bool, // is courses loading now
  courses: PropTypes.arrayOf(PropTypes.object), // list of courses
  updateCourseMutation: PropTypes.func, // updates a course item
  updateThemeMutation: PropTypes.func, // updates a theme items
  t: PropTypes.func, // i18n translate func
};

export default enhancer(Courses);
