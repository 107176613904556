// socials
export const FACEBOOK = 'facebook.com';
export const GOOGLE = 'google.com';
export const GITHUB = 'github.com';

// Pages
export const ITEMS_PER_PAGE = 15;

// Modal types
export const ALERT_MODAL = 'alert';

// Modal sizes
export const SMALL_MODAL = 'small';
export const MEDIUM_MODAL = 'medium';
export const LARGE_MODAL = 'large';

export const MODAL_CLOSE_TIMEOUT = 300;

// Public routes
export const SIGN_IN_ROUTE = 'signIn';
export const LOGIN_ROUTE = 'login';
export const PROFILE_ROUTE = 'profile';
export const NOT_FOUND_ROUTE = 'notFound';

// Admin routes
export const COURSES_ROUTE = 'courses';
export const INTERNSHIP_ROUTE = 'internship';
export const INTERNSHIP_COURSE_ROUTE = 'internshipPage';
export const INSERT_INTERNSHIP_ROUTE = 'insertInternshipPage';
export const NEWS_ROUTE = 'news';
export const SETTINGS_ROUTE = 'settings';
export const RECRUITING_ROUTE = 'RECRUITING_ROUTE';
export const ADD_ROUTE = 'add';
export const EDIT_ROUTE = 'edit';
export const NOTIFICATION_ROUTE = 'notification';
export const USER_QUESTION = 'user-question';

// API
export const API_URL = 'https://api.coderslang.com';

// API keys
export const SEND_NEWS_EMAIL = 'sendNewsEmail';
export const COURSE_IMAGE_UPLOAD = 'courseImageUpload';
export const THEME_IMAGE_UPLOAD = 'themeImageUpload';
export const SEND_NEWS_NOTIFICATION = 'sendNewsNotification';

// API all routes
export const API = {
  [SEND_NEWS_NOTIFICATION]: `${API_URL}/notifications/sendPushNotification`,
  [SEND_NEWS_EMAIL]: `${API_URL}/mail/newsNotification`,
  [COURSE_IMAGE_UPLOAD]: `${API_URL}/courses/getUploadUrl`,
  [THEME_IMAGE_UPLOAD]: `${API_URL}/themes/getUploadUrl`,
};

export const SMALL = 'SMALL';
export const MEDIUM = 'MEDIUM';
export const LARGE = 'LARGE';
export const HD = 'HD';

export const ALLOWED_ROLES = ['editor', 'admin'];

// LEVEL
export const INTERN = 'INTERN';
export const JUNIOR = 'JUNIOR';
export const MIDDLE = 'MIDDLE';
export const SENIOR = 'SENIOR';

export const THEME_LIST = 'theme-list';
export const TOP_QUESTION = 'top-question';
