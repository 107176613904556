// Core
import styled, { css } from 'styled-components';

// Other
import { colors, BREAKPOINTS } from '../../../assets/styles';

export const fontStyle = {
  color: colors.grey6,
  fontFamily: 'OpenSans',
  fontWeight: 'bolder',
  fontSize: 18,
};

export const ChartWrapper = styled.div`
  position: relative;
  ${({ backgroundImage }) =>
    backgroundImage &&
    css`
      &::after {
        content: '';
        background-image: url(${backgroundImage});
        width: 130px;
        height: 130px;
        position: absolute;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        top: calc(50% - 66px);
        left: calc(50% - 65px);
        z-index: 0;
        @media (max-width: ${BREAKPOINTS.SMALL}) {
          width: 100px;
          height: 100px;
          top: calc(50% - 50px);
          left: calc(50% - 49px);
        }
      }
    `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 30px 24px;
  min-height: 300px;
  box-sizing: border-box;
  @media (max-width: ${BREAKPOINTS.SMALL}) {
    padding: 50px 16px;
  }
`;

export const OverallTimeWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  font-family: OpenSans;
  font-size: 14px;
  align-self: center;
  & span {
    color: ${colors.grey6};
    margin-right: 5px;
  }
  & p {
    color: ${colors.grey9};
  }
`;
