import i18n, { LOCALES } from '../../external/i18n';

i18n.addResources(LOCALES.RU, 'translation', {
  'News/title': 'Новости',
  'News/createNews': 'Создать новость',
  'News/deleteTitle': 'Вы уверены что хотите удалить эту новость?',
  'News/ID': 'ID',
  'News/titleRu': 'Название',
  'News/titleEn': 'Title',
  'News/description': 'Oписание',
  'News/descriptionEn': 'Description',
  'News/Date': 'Дата',
});

i18n.addResources(LOCALES.EN, 'translation', {
  'News/title': 'News',
  'News/createNews': 'Create news',
  'News/deleteTitle': 'Are you sure you want to delete this news?',
  'News/ID': 'ID',
  'News/titleRu': 'Title',
  'News/titleEn': 'Title En',
  'News/description': 'Description',
  'News/descriptionEn': 'Description En',
  'News/Date': 'Date',
});
