// Core
import React, { PureComponent } from 'react';
import { graphql } from '@apollo/react-hoc';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { inject, observer } from 'mobx-react';

// Components
import Avatar from '../../components/Avatar';
import CircularLangProgress from '../../components/CircularLangProgress';
import AwardsList from './Awards';
import Progress from './Progress';
import ScreenLoader from '../../components/ScreenLoader';
import Chart from './Chart';

// GraphQL
import PROFILE_QUERY from '../../gql/query/profile';

// Other
import {
  getCurrentSizeScreen,
  countUserQuestionsField,
  getPreparedChartData,
  getAwardsUserStat,
} from './helpers';
import compose from '../../utils/compose';
import textTransform, { BOLD } from '../../utils/textTransform';
import { colors } from '../../assets/styles';
import { USER_STORE } from '../../stores/constants';
import { getUnit } from '../../utils/getUnit';

// Styles and assets
import {
  ContentWrapper,
  UserContainer,
  NameText,
  LangsContainer,
  LangProgressBtn,
  NameContainer,
  RowBlock,
  RightContainer,
  EmailText,
  FlagIsPremium,
  AwardsWrapper,
  Statistic,
  BlockNumberInStatistic,
  TextAwardUserBetter,
  NotificationWrapper,
  BottomExpander,
  TextNotification,
} from './styles';
import './i18n';

@inject(USER_STORE)
@observer
class Profile extends PureComponent {
  constructor(props) {
    super(props);
    // const { id } = props.match.params;

    // const locale = localStorage.getItem(`coderslang-web-profile:${id}`);
    // if (Object.values(LOCALES).includes(locale)) {
    //   props.i18n.changeLanguage(locale);
    // }
    this.state = {
      currentCourseIndex: 0,
      betterUsersAwards: 0,
      sizeScreen: getCurrentSizeScreen(),
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
  }

  async componentDidUpdate(prevProps) {
    const { loading, profile, i18n, history } = this.props;
    if (prevProps.loading && !loading) {
      if (!profile) {
        // if no such profile or profile has no active premium subscription we redirecting user to the "not found" page
        history.push('/404');
      } else if (profile.locale !== i18n.language) {
        i18n.changeLanguage(profile.locale);
        localStorage.setItem(`coderslang-web-profile:${profile.id}`, profile.locale);
      } else if (profile?.awards_score) {
        const betterUsersAwards = await getAwardsUserStat(profile.awards_score);
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ betterUsersAwards });
      }
    }
  }

  resize = () => {
    const { sizeScreen } = this.state;
    const currentSizeScreen = getCurrentSizeScreen();
    if (sizeScreen !== currentSizeScreen) {
      this.setState({ sizeScreen: currentSizeScreen });
    }
  };

  /**
   * Changes course info in progress block
   */
  changeShownCourse = currentCourseIndex => () => this.setState({ currentCourseIndex });

  /**
   * Renders course circular progress icon
   */
  renderCircularProgress = ({ course_id, course: { themes = [], picture_url } }, index) => {
    const { currentCourseIndex } = this.state;

    const allCourseQuestions = themes.reduce(
      (courseQuestions, { questions }) => [...courseQuestions, ...questions],
      []
    );
    const courseProgress = countUserQuestionsField(allCourseQuestions, 'score');

    return (
      <LangProgressBtn
        key={course_id}
        disabled={currentCourseIndex === index}
        onClick={this.changeShownCourse(index)}
      >
        <CircularLangProgress
          courseImg={picture_url}
          courseNumber={course_id}
          progress={courseProgress}
          maxValue={allCourseQuestions.length}
        />
      </LangProgressBtn>
    );
  };

  renderContent = () => {
    const {
      loading,
      [USER_STORE]: {
        userData: { id: userId, user_subscriptions: mySubscription },
      },
      profile,
      t,
    } = this.props;
    const { currentCourseIndex, betterUsersAwards, sizeScreen } = this.state;

    if (loading || !profile) {
      return <ScreenLoader />;
    }
    const {
      picture_url,
      name,
      email,
      user_courses,
      locale,
      friends_count,
      awards,
      user_subscriptions,
      id: openedUserId,
    } = profile;

    const isPremiumUser = !!user_subscriptions?.length;
    let showNotification = false;
    let showProgress = false;
    if (!mySubscription?.length && openedUserId === userId) {
      showProgress = true;
      showNotification = true;
    }
    if (user_subscriptions?.length) {
      showProgress = true;
      showNotification = false;
    }

    const {
      course: { themes, picture_url: courseImg },
    } = user_courses[currentCourseIndex];
    const chartData = getPreparedChartData(themes, locale);

    const { labels, values, valuesTime, correctAnswer, wrongAnswers, allTime } = chartData;

    return (
      <>
        <UserContainer>
          <RowBlock>
            {['SMALL', 'MEDIUM'].includes(sizeScreen) && (
              <NameContainer>
                {isPremiumUser && <FlagIsPremium>Premium</FlagIsPremium>}
                <NameText>{name || email?.split('@')[0] || t('Profile/noName')}</NameText>
                {openedUserId === userId && <EmailText>{email}</EmailText>}
              </NameContainer>
            )}
            <Avatar
              isBorder={false}
              borderRadius={8}
              size={272}
              borderColor={colors.white}
              url={picture_url}
            />
            <RightContainer>
              {['LARGE', 'HD'].includes(sizeScreen) && (
                <NameContainer>
                  {isPremiumUser && <FlagIsPremium>Premium</FlagIsPremium>}
                  <NameText>{name || email?.split('@')[0] || t('Profile/noName')}</NameText>
                  {openedUserId === userId && <EmailText>{email}</EmailText>}
                </NameContainer>
              )}
              <LangsContainer>{user_courses.map(this.renderCircularProgress)}</LangsContainer>
            </RightContainer>
          </RowBlock>
        </UserContainer>

        <RowBlock>
          <Progress flexCount={6} t={t} title={t('Profile/timeStats')} isPremiumUser={showProgress}>
            <Chart
              sizeScreen={sizeScreen}
              labels={labels}
              series={valuesTime}
              type="timeChart"
              bottomValue={allTime}
              backgroundImage={courseImg}
            />
          </Progress>
          <Progress
            flexCount={5}
            t={t}
            title={t('Profile/themeProgress')}
            isPremiumUser={showProgress}
          >
            <Chart
              type="basicBar"
              sizeScreen={sizeScreen}
              labels={labels}
              series={[
                {
                  data: values,
                },
              ]}
            />
          </Progress>
        </RowBlock>
        <RowBlock>
          <Progress t={t} flexCount={4} title={t('Profile/rates')} isPremiumUser={showProgress}>
            <Chart
              sizeScreen={sizeScreen}
              type="pic"
              labels={[getUnit(correctAnswer, 'right', t), getUnit(wrongAnswers, 'wrong', t)]}
              series={[correctAnswer, wrongAnswers]}
              totalAnswer={correctAnswer + wrongAnswers}
            />
          </Progress>
          <Progress flexCount={6} t={t} title={t('Profile/radar')} isPremiumUser={showProgress}>
            <Chart
              sizeScreen={sizeScreen}
              type="radar"
              labels={labels}
              series={[
                {
                  data: values,
                },
              ]}
            />
          </Progress>
        </RowBlock>

        <Progress t={t} title={t('Profile/awardsTitle')} isPremiumUser={showProgress}>
          <AwardsWrapper>
            <AwardsList awards={awards} friendsCount={friends_count.aggregate.count} />
          </AwardsWrapper>
          <Statistic>
            <BlockNumberInStatistic>
              <TextAwardUserBetter isNormal>
                {textTransform(t('Profile/betterUsers', { count: betterUsersAwards }), {
                  [BOLD]: TextAwardUserBetter,
                })}
              </TextAwardUserBetter>
            </BlockNumberInStatistic>
          </Statistic>
        </Progress>
        {showNotification && (
          <>
            <BottomExpander />

            <NotificationWrapper>
              <TextNotification>{t('Profile/notification')}</TextNotification>
            </NotificationWrapper>
          </>
        )}
      </>
    );
  };

  render() {
    const { t, profile } = this.props;
    const name = profile?.name || profile?.email?.split('@')[0];

    return (
      <ContentWrapper>
        <Helmet>
          <title>
            {t('Profile/title')} {name ? `- ${name}` : ''}
          </title>
          <meta
            name="description"
            content={`${t('Profile/description')} ${name ? `- ${name}` : ''}`}
          />
        </Helmet>
        {this.renderContent()}
      </ContentWrapper>
    );
  }
}

const profileQuery = graphql(PROFILE_QUERY, {
  props: ({ data: { app_user = [{}], loading } }) => ({
    profile: app_user[0],
    loading,
  }),
  options: ({ match }) => {
    const { id } = match.params;
    return {
      variables: {
        where: {
          id: { _eq: id },
          is_onboarding: { _eq: true },
        },
      },
      fetchPolicy: 'cache-and-network',
    };
  },
});

Profile.propTypes = {
  loading: PropTypes.bool, // is profile data loading
  // match: PropTypes.object, // data from react-navigation
  history: PropTypes.object, // navigation history object
  profile: PropTypes.object, // profile data
  i18n: PropTypes.object, // i18next instance
  t: PropTypes.func, // i18n translation func
};

const enhancer = compose(
  withTranslation(),
  profileQuery
);

export default enhancer(Profile);
