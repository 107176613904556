import { gql } from 'apollo-boost';

export default gql`
  query awardsStatQuery($awardsScore: Int) {
    lower: app_user_aggregate(where: { awards_score: { _lt: $awardsScore } }) {
      aggregate {
        count
      }
    }
    total: app_user_aggregate {
      aggregate {
        count
      }
    }
  }
`;
