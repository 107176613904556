import i18n, { LOCALES } from '../../../external/i18n';

i18n.addResources(LOCALES.RU, 'translation', {
  'NewThemeModal/nameLabel': 'Тема:',
  'NewThemeModal/nameLabelEn': 'Тема на английском:',
  'NewThemeModal/namePlaceholder': 'Название темы',
  'NewThemeModal/namePlaceholderEn': 'Название темы на английском',
  'NewThemeModal/pictureLabelDark': 'Загрузить логотип в формате png, для тёмной темы',
  'NewThemeModal/pictureLabel': 'Загрузить логотип в формате png',
});

i18n.addResources(LOCALES.EN, 'translation', {
  'NewThemeModal/nameLabel': 'Theme:',
  'NewThemeModal/nameLabelEn': 'Theme in English:',
  'NewThemeModal/namePlaceholder': 'Theme name',
  'NewThemeModal/namePlaceholderEn': 'Theme title in English',
  'NewThemeModal/pictureLabelDark': 'Upload logo in png format for dark theme',
  'NewThemeModal/pictureLabel': 'Upload logo in png format',
});
