import i18n, { LOCALES } from '../../external/i18n';

i18n.addResources(LOCALES.RU, 'translation', {
  'InternshipScreen/title': 'Стажировка',
  'InternshipScreen/createProject': 'Создать проект',
  'InternshipScreen/days': 'дней',
  'InternshipScreen/weeks': 'недель',
  'InternshipScreen/projectName': 'Название проекта',
  'InternshipScreen/knowledgeLevel': 'Уровень знаний',
  'InternshipScreen/numberOfPersons': 'К-во человек',
  'InternshipScreen/projectDuration': 'Длительность',
  'InternshipScreen/programmingLanguages': 'Языки программирования',
  'InternshipUpdate/InternshipNewProject': 'Стажировка / Новый проект',
  'InternshipUpdate/Project': 'Проект:',
  'InternshipUpdate/ProgrammingLanguages': 'Языки программирования:',
  'InternshipUpdate/KnowledgeLevel': 'Уровень знаний:',
  'InternshipUpdate/InternsCount': 'Количество человек:',
  'InternshipUpdate/Duration': 'Длительность:',
  'InternshipUpdate/FillInAllFields': 'заполните все поля пожалуйста',
  'InternshipUpdate/Save': 'Сохранить',
  'InternshipUpdate/NameProject': 'Напишите название проекта',
  'InternshipUpdate/ChooseLanguage': 'Выберите язык программирования',
  'InternshipUpdate/ChooseLevelKnowledge': 'Выберите уровень знаний',
  'InternshipUpdate/WriteNumberPeople': 'Напишите количество человек',
  'InternshipUpdate/WriteDurationProject': 'Напишите длительность проекта',

  'InternshipUpdate/day/singular': 'день',
  'InternshipUpdate/day/few': 'дня',
  'InternshipUpdate/day/many': 'дней',

  'InternshipUpdate/week/singular': 'нeделя',
  'InternshipUpdate/week/few': 'нeдели',
  'InternshipUpdate/week/many': 'нeдель',

  'InternshipUpdate/month/singular': 'месяц',
  'InternshipUpdate/month/few': 'месяца',
  'InternshipUpdate/month/many': 'месяцев',

  'InternshipUpdate/year/singular': 'год',
  'InternshipUpdate/year/few': 'года',
  'InternshipUpdate/year/many': 'лет',

  'InternshipUpdate/minute/singular': 'минута',
  'InternshipUpdate/minute/few': 'минуты',
  'InternshipUpdate/minute/many': 'минут',
  'InternshipUpdate/minute/reduction': 'мин.',
  'InternshipUpdate/hour/singular': 'час',
  'InternshipUpdate/hour/few': 'часа',
  'InternshipUpdate/hour/many': 'часов',
  'InternshipUpdate/hour/reduction': 'ч.',
  'InternshipUpdate/second/singular': 'секунда',
  'InternshipUpdate/second/few': 'секунды',
  'InternshipUpdate/second/many': 'секунд',
  'InternshipUpdate/second/reduction': 'сек.',
  'InternshipUpdate/right/singular': 'Правильный',
  'InternshipUpdate/right/few': 'Правильных',
  'InternshipUpdate/right/many': 'Правильных',
  'InternshipUpdate/wrong/singular': 'Ошибка',
  'InternshipUpdate/wrong/few': 'Ошибки',
  'InternshipUpdate/wrong/many': 'Ошибок',
  'InternshipUpdate/answer/singular': 'Ответ',
  'InternshipUpdate/answer/few': 'Ответа',
  'InternshipUpdate/answer/many': 'Ответов',
});

i18n.addResources(LOCALES.EN, 'translation', {
  'InternshipScreen/title': 'Internship',
  'InternshipScreen/createProject': 'Create project',
  'InternshipScreen/days': 'days',
  'InternshipScreen/weeks': 'weeks',
  'InternshipScreen/projectName': 'Project name',
  'InternshipScreen/knowledgeLevel': 'Grade',
  'InternshipScreen/numberOfPersons': 'Team size',
  'InternshipScreen/projectDuration': 'Project duration',
  'InternshipScreen/programmingLanguages': 'Programming languages',
  'InternshipUpdate/InternshipNewProject': 'Internship / New project',
  'InternshipUpdate/Project': 'Project:',
  'InternshipUpdate/ProgrammingLanguages': 'Programming languages:',
  'InternshipUpdate/KnowledgeLevel': 'KnowledgeLevel:',
  'InternshipUpdate/InternsCount': 'Team size:',
  'InternshipUpdate/Duration': 'Project duration:',
  'InternshipUpdate/FillInAllFields': 'fill in all fields please',
  'InternshipUpdate/Save': 'Save',
  'InternshipUpdate/NameProject': 'Write the name of the project',
  'InternshipUpdate/ChooseLanguage': 'Choose a programming language',
  'InternshipUpdate/ChooseLevelKnowledge': 'Choose a level of knowledge',
  'InternshipUpdate/WriteNumberPeople': 'Write the number of people',
  'InternshipUpdate/WriteDurationProject': 'Write the duration of the project',
  'InternshipUpdate/day/singular': 'day',
  'InternshipUpdate/day/few': 'days',
  'InternshipUpdate/day/many': 'days',
  'InternshipUpdate/week/singular': 'week',
  'InternshipUpdate/week/few': 'weeks',
  'InternshipUpdate/week/many': 'weeks',
  'InternshipUpdate/month/singular': 'month',
  'InternshipUpdate/month/few': 'months',
  'InternshipUpdate/month/many': 'months',
  'InternshipUpdate/year/singular': 'year',
  'InternshipUpdate/year/few': 'year',
  'InternshipUpdate/year/many': 'years',
  'InternshipUpdate/minute/singular': 'minute',
  'InternshipUpdate/minute/few': 'minutes',
  'InternshipUpdate/minute/many': 'minutes',
  'InternshipUpdate/minute/reduction': 'min.',
  'InternshipUpdate/hour/singular': 'hour',
  'InternshipUpdate/hour/few': 'hours',
  'InternshipUpdate/hour/many': 'hours',
  'InternshipUpdate/hour/reduction': 'h.',
  'InternshipUpdate/second/singular': 'second',
  'InternshipUpdate/second/few': 'seconds',
  'InternshipUpdate/second/many': 'seconds',
  'InternshipUpdate/second/reduction': 'sec.',
  'InternshipUpdate/right/singular': 'Correct',
  'InternshipUpdate/right/few': 'Correct',
  'InternshipUpdate/right/many': 'Correct',
  'InternshipUpdate/wrong/singular': 'Wrong',
  'InternshipUpdate/wrong/few': 'Wrong',
  'InternshipUpdate/wrong/many': 'Wrong',
  'InternshipUpdate/answer/singular': 'Answer',
  'InternshipUpdate/answer/few': 'Answers',
  'InternshipUpdate/answer/many': 'Answers',
});
