import i18n, { LOCALES } from '../../external/i18n';

i18n.addResources(LOCALES.RU, 'translation', {
  'Notifications/Add/title': 'Уведомления / Новое уведомление',
  'Notifications/Add/send': 'Отправить',
  'Notifications/Add/name': 'Название:',
  'Notifications/Add/namePlaceholder': 'Напишите название новости',
  'Notifications/Add/description': 'Описание:',
  'Notifications/Add/descriptionPlaceholder': 'Добавьте описание новости',
  'Notifications/Add/preview': 'Превью:',
  'Notifications/Add/previewPlaceholder': 'Вставьте ссылку',
  'Notifications/Add/photoUpload': 'Загрузите фото',
  'Notifications/Add/notifyUsers': 'Отправить всем',
  'Notifications/Add/notifyAdmins': 'Отправить админам',
});

i18n.addResources(LOCALES.EN, 'translation', {
  'Notifications/Add/title': 'Notifications / Create new',
  'Notifications/Add/send': 'Send',
  'Notifications/Add/name': 'Title:',
  'Notifications/Add/namePlaceholder': 'Type the name of the Notifications',
  'Notifications/Add/description': 'Description:',
  'Notifications/Add/descriptionPlaceholder': 'Add description to the Notifications',
  'Notifications/Add/preview': 'Preview:',
  'Notifications/Add/previewPlaceholder': 'Paste the link',
  'Notifications/Add/photoUpload': 'Photo upload',
  'Notifications/Add/notifyUsers': 'Send to everyone',
  'Notifications/Add/notifyAdmins': 'Send to admins',
});
