// Core
import styled, { css } from 'styled-components';
import { rem } from 'polished';

// Other
import { colors } from '../../assets/styles';

export const StyledInput = styled.input`
  height: 48px;
  width: 100%;
  border-radius: 8px;
  border: solid 1px ${({ isError }) => (isError ? colors.error : colors.theme4)};
  margin-top: 3px;
  padding: 0 20px;
  color: ${colors.black1};
  ${({ isSelect }) =>
    isSelect &&
    css`
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    `}
  &::placeholder {
    color: ${colors.grey7};
  }
`;

export const StyledTextarea = styled.textarea`
  height: 80px;
  max-width: 100%;
  min-width: 100%;
  border-radius: 8px;
  border: solid 1px ${({ isError }) => (isError ? colors.error : colors.theme4)};
  margin-top: 3px;
  padding: 15px 20px;
  color: ${colors.grey3};
  outline: 0;

  &::placeholder {
    color: ${colors.grey7};
  }
`;

const commonLabelStyle = css`
  font-weight: ${({ boldLabel }) => (boldLabel ? 'bolder' : 'bold')};
  font-size: ${rem(12)};
  color: ${colors.theme1};
  width: 100%;
  position: relative;
`;

export const StyledLabel = styled.label`
  ${commonLabelStyle}
`;

export const StyledLabelSelect = styled.div`
  ${commonLabelStyle}
`;

export const ErrorTitle = styled.p`
  font-size: ${rem(12)};
  color: ${colors.error};
  position: absolute;
  right: 20px;
  bottom: 0;
  transform: translateY(50%);
  background-color: ${colors.white};
  padding: 3px;
`;

export const WrapperInput = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled.img`
  width: 12px;
  align-self: center;
  margin-left: 5px;
  transition: transform 0.2s ease-in-out;
  transform: rotate(${({ isDown }) => (isDown ? 0 : 180)}deg);
`;

export const customStyles = {
  control: (styles, { selectProps: { error } }) => ({
    ...styles,
    backgroundColor: colors.white,
    border: `1px solid ${colors[error ? 'error' : 'theme4']}`,
    minHeight: 48,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    width: 100,
    marginTop: 3,
  }),

  option: (options, state) => {
    return {
      ...options,
      color: state.isSelected ? colors.white : colors.blue,
      backgroundColor: state.isSelected ? colors.blue1 : colors.white,
      padding: 10,
      fontSize: '12px',
      fontWeight: 400,
    };
  },
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
  placeholder: base => ({
    ...base,
    fontSize: '12px',
    fontWeight: 400,
    color: colors.grey14,
    paddingLeft: 10,
  }),
};
