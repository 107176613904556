import i18n, { LOCALES } from '../../../external/i18n';

i18n.addResources(LOCALES.RU, 'translation', {
  'Awards/title': 'Награды',
  'Awards/share': 'Рассказать друзьям',
  'Awards/youAreCooler': 'Вы круче, чем ',
  'Awards/ofUsers': ' пользователeй!',
  'Awards/inviterDescription0': 'Отправлено 3 инвайта',
  'Awards/inviterDescription1': 'Отправлено 5 инвайтов',
  'Awards/inviterDescription2': 'Отправлено 10 инвайтов',
  'Awards/friendlyDescription0': 'Добавлено 1 друзей',
  'Awards/friendlyDescription1': 'Добавлено 3 друзей',
  'Awards/friendlyDescription2': 'Добавлено 5 друзей',
  'Awards/starterDescription0': 'Вы прошли стартовый тест',
  'Awards/streakDescription0': 'Сохраняйте ударный темп в течении 3 дней',
  'Awards/streakDescription1': 'Сохраняйте ударный темп в течении 7 дней',
  'Awards/streakDescription2': 'Сохраняйте ударный темп в течении 14 дней',
  'Awards/topRatedDescription0': 'Пройден 1 курс',
  'Awards/topRatedDescription1': 'Пройдено 3 курса',
  'Awards/topRatedDescription2': 'Пройдено 5 курсов',
  'Awards/likerDescription0': 'Поставлено 20 лайков вопросам',
  'Awards/likerDescription1': 'Поставлено 100 лайков вопросам',
  'Awards/likerDescription2': 'Поставлено 250 лайков вопросам',
  'Awards/bro': 'Бро',
  'Awards/mentor': 'Наставник',
  'Awards/chief': 'Вождь',
  'Awards/friendly': 'Дружелюбный',
  'Awards/sociable': 'Общительный',
  'Awards/party': 'Душа компании',
  'Awards/runner': 'Бегун',
  'Awards/sprinter': 'Спринтер',
  'Awards/unstoppable': 'Неудержимый',
  'Awards/young': 'Юное дарование',
  'Awards/pro': 'Про',
  'Awards/champion': 'Чемпион',
  'Awards/starter': 'Первый шаг',
  'Awards/highFive': 'Дай пять!',
  'Awards/trueExpert': 'Истинный ценитель',
  'Awards/bigFan': 'Большой фанат',
});

i18n.addResources(LOCALES.EN, 'translation', {
  'Awards/title': 'All awards',
  'Awards/share': 'Share with friends',
  'Awards/youAreCooler': 'you are cooler than ',
  'Awards/ofUsers': ' of users!',
  'Awards/inviterDescription0': 'Sent 3 invites',
  'Awards/inviterDescription1': 'Sent 5 invites',
  'Awards/inviterDescription2': 'Sent 10 invites',
  'Awards/friendlyDescription0': 'Added 1 friends',
  'Awards/friendlyDescription1': 'Added 3 friends',
  'Awards/friendlyDescription2': 'Added 5 friends',
  'Awards/starterDescription0': 'You passed the first test',
  'Awards/streakDescription0': 'Keep up the good pace for 3 days',
  'Awards/streakDescription1': 'Keep up the good pace for 7 days',
  'Awards/streakDescription2': 'Keep up the good pace for 14 days',
  'Awards/likerDescription0': 'Like 20 questions',
  'Awards/likerDescription1': 'Like 100 questions',
  'Awards/likerDescription2': 'Like 250 questions',
  'Awards/topRatedDescription0': 'Completed 1 course',
  'Awards/topRatedDescription1': 'Completed 3 courses',
  'Awards/topRatedDescription2': 'Completed 5 courses',
  'Awards/bro': 'Bro',
  'Awards/mentor': 'Mentor',
  'Awards/chief': 'Chief',
  'Awards/friendly': 'Friendly',
  'Awards/sociable': 'Sociable',
  'Awards/party': 'Life of\nthe party',
  'Awards/runner': 'Runner',
  'Awards/sprinter': 'Sprinter',
  'Awards/unstoppable': 'Unstoppable',
  'Awards/young': 'Young\ntalent',
  'Awards/pro': 'Pro',
  'Awards/champion': 'Champion',
  'Awards/starter': 'First step',
  'Awards/highFive': 'High five!',
  'Awards/trueExpert': 'True expert',
  'Awards/bigFan': 'Big fan',
});

export default i18n;
