// Core
import styled from 'styled-components';
import { rem } from 'polished';

// Other
import { colors } from '../../../assets/styles';

export const StyledBtn = styled.button`
  width: 100%;
  height: 40px;
  padding: 0 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 33px;
  color: ${colors.white};
  font-size: ${rem(16)};
  position: relative;
  margin-bottom: 24px;
  background-color: ${({ social, success }) => (success ? colors.success : colors[social])};
  ${({ withOpacity }) => withOpacity && 'opacity: 0.6;'}
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }

  &:disabled {
    pointer-events: none;
  }
`;

export const SocialIcon = styled.img`
  height: 33px;
  width: 33px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 3.5px;
  z-index: 2;
`;
