import i18n, { LOCALES } from '../../external/i18n';
import {
  COURSES_ROUTE,
  INTERNSHIP_ROUTE,
  NEWS_ROUTE,
  SETTINGS_ROUTE,
  NOTIFICATION_ROUTE,
  USER_QUESTION,
} from '../../constants';

i18n.addResources(LOCALES.RU, 'translation', {
  [`SideBar/${COURSES_ROUTE}`]: 'Курсы, темы, тесты',
  [`SideBar/${USER_QUESTION}`]: 'Вопросы пользователей',
  [`SideBar/${INTERNSHIP_ROUTE}`]: 'Стажировка',
  [`SideBar/${NEWS_ROUTE}`]: 'Новости',
  [`SideBar/${SETTINGS_ROUTE}`]: 'Настройки',
  [`SideBar/${NOTIFICATION_ROUTE}`]: 'Уведомления',
});

i18n.addResources(LOCALES.EN, 'translation', {
  [`SideBar/${COURSES_ROUTE}`]: 'Courses, themes, tests',
  [`SideBar/${USER_QUESTION}`]: 'User questions',
  [`SideBar/${INTERNSHIP_ROUTE}`]: 'Internship',
  [`SideBar/${NEWS_ROUTE}`]: 'News',
  [`SideBar/${SETTINGS_ROUTE}`]: 'Settings',
  [`SideBar/${NOTIFICATION_ROUTE}`]: 'Notifications',
});
