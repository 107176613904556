import { gql } from 'apollo-boost';

export default gql`
  query CourseWithTitle($where: course_bool_exp) {
    course(where: $where) {
      id
      title
    }
  }
`;
