// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import camelCase from 'lodash/camelCase';

// Components
import Themes from './ThemesPages';
import GradientButton from '../../components/Buttons/GradientButton';
import EditThemeModal from './EditThemeModal';
import DeleteThemeModal from './DeleteThemeModal';
import TopQuestionsPages from './TopQuestionsPages';

// other
import { lastDailyQuestionQuery, coursesTitleQuery } from './ThemesPages/enhancer';
import compose from '../../utils/compose';
import { ALERT_MODAL, SMALL_MODAL, THEME_LIST, TOP_QUESTION } from '../../constants';
import { APP_STORE } from '../../stores/constants';

// Styles and assets
import {
  ContentWrapper,
  HeaderRow,
  PageTitle,
  CreateBtnIcon,
  LeftWrapper,
  IconArrowBack,
  ThemeBtn,
  LastDailyQuestion,
  ButtonsWrapper,
  SwitchButton,
} from './styles';
import plusIcon from '../../assets/images/plus.png';
import backIcon from '../../assets/images/arrow/arrow-back.png';
import i18n from '../../external/i18n';

const tabs = [THEME_LIST, TOP_QUESTION];
@inject(APP_STORE)
@observer
class ThemesRoute extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 0,
    };
  }

  onClickPage = page => {
    this.setState({
      currentPage: page.selected,
    });
  };

  showModal = (type, theme) => () => {
    const {
      t,
      allThemes,
      [APP_STORE]: { showModal, closeModal },
      match: { params: courserId },
      updateThemeMutation,
    } = this.props;
    if (type === 'delete') {
      showModal({
        type: ALERT_MODAL,
        size: SMALL_MODAL,
        content: <DeleteThemeModal theme={theme} closeModal={closeModal} />,
        modalProps: {
          title: t('Theme/deleteTitle'),
        },
      });
    } else {
      showModal({
        content: (
          <EditThemeModal
            theme={theme}
            closeModal={closeModal}
            currentCourserId={courserId}
            themes={allThemes}
            updateThemeMutation={updateThemeMutation}
          />
        ),
        size: SMALL_MODAL,
      });
    }
  };

  renderHeader = () => {
    const { loadingCourses, lastDailyQuestion } = this.props;
    if (loadingCourses) {
      return null;
    }
    const {
      t,
      course: { title },
      history: { goBack },
    } = this.props;

    const dailyQuestionDate =
      lastDailyQuestion && lastDailyQuestion.length
        ? lastDailyQuestion[0].daily_question_date
        : '-';
    return (
      <HeaderRow>
        <LeftWrapper>
          <ThemeBtn>
            <IconArrowBack src={backIcon} onClick={goBack} />
          </ThemeBtn>
          <PageTitle>
            {title && title[i18n.language]} / {t('Theme/themeListSwitch')}
          </PageTitle>
        </LeftWrapper>
        <LastDailyQuestion>
          {t('App/lastDailyQuestion')}: <strong>{dailyQuestionDate}</strong>
        </LastDailyQuestion>
        <GradientButton onClick={this.showModal()}>
          <CreateBtnIcon src={plusIcon} marginRight />
          {t('Theme/createNew')}
        </GradientButton>
      </HeaderRow>
    );
  };

  renderSwitchButton = (type, index) => {
    const {
      t,
      [APP_STORE]: { handleChangeTab, currentThemeTab },
    } = this.props;

    const isActive = type === currentThemeTab;
    return (
      <SwitchButton
        key={type}
        onClick={handleChangeTab(type)}
        isActive={isActive}
        firstButton={index === 0}
      >
        {t(`Theme/${camelCase(type)}Switch`)}
      </SwitchButton>
    );
  };

  renderSwitchButtons = () => {
    return <ButtonsWrapper>{tabs.map(this.renderSwitchButton)}</ButtonsWrapper>;
  };

  renderContent = () => {
    const {
      [APP_STORE]: {
        countQuestionsShow: { value },
        currentThemeTab,
      },
    } = this.props;

    const { loadingCourses, currentPage } = this.state;
    if (loadingCourses) {
      return null;
    }
    switch (currentThemeTab) {
      case THEME_LIST:
        return (
          <Themes
            {...this.props}
            showModal={this.showModal}
            currentPage={currentPage}
            onClickPage={this.onClickPage}
          />
        );
      case TOP_QUESTION:
        return (
          <TopQuestionsPages
            {...this.props}
            limit={value}
            currentPage={currentPage}
            onClickPage={this.onClickPage}
          />
        );

      default:
        return null;
    }
  };

  render() {
    return (
      <ContentWrapper>
        {this.renderHeader()}
        {this.renderSwitchButtons()}
        {this.renderContent()}
      </ContentWrapper>
    );
  }
}

ThemesRoute.propTypes = {
  loadingCourses: PropTypes.bool, // is course loading now
  loadingAllThemes: PropTypes.bool, // is AllThemes loading now
  updateThemeMutation: PropTypes.func, // creates new course item
  countThemes: PropTypes.number, // number count themes
  course: PropTypes.object, // object course with themes
  history: PropTypes.object, // object with history route
  t: PropTypes.func, // i18n translate func
  currentPage: PropTypes.number, // number current page
  onClickPage: PropTypes.func, // open page func
  allThemes: PropTypes.array, // array of all themes
  lastDailyQuestion: PropTypes.array,
};

export default compose(
  lastDailyQuestionQuery,
  coursesTitleQuery,
  withTranslation()
)(ThemesRoute);
