// Core
import React from 'react';
import { Helmet } from 'react-helmet';

// Styles and assets
import { ContentWrapper, ErrorTitle, ErrorImage } from './styles';
import notFountImg from '../../assets/images/404.png';

const NotFound = () => (
  <ContentWrapper>
    <Helmet>
      <title>СodersLang · Страница не найдена</title>
    </Helmet>
    <ErrorTitle>Извините!</ErrorTitle>
    <ErrorTitle>Мы не можем найти страницу, которую вы ищете :(</ErrorTitle>
    <ErrorImage src={notFountImg} alt="404" />
  </ContentWrapper>
);

export default NotFound;
