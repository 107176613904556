import { gql } from 'apollo-boost';

export default gql`
  query InternshipById($where: internship_bool_exp) {
    internship(where: $where) {
      id
      interns_count
      created_at
      knowledge_level
      name
      programming_langs
      duration
    }
    courses: course(order_by: { id: asc }) {
      id
      title
    }
  }
`;
