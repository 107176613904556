// Core
import styled from 'styled-components';

// Other
import { BREAKPOINTS, colors } from '../../assets/styles';

export const ContentWrapper = styled.div`
  width: 100%;
  padding: 100px 15px 38px;
  flex: 1;
  align-items: center;
  min-height: 100%;
  background: linear-gradient(268deg, ${colors.gradient1} 2%, ${colors.gradient2});

  @media (min-width: ${BREAKPOINTS.SMALL}) {
    padding: 200px 30px 38px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AuthContainer = styled.div`
  max-width: 344px;
  min-width: 300px;
`;

export const AuthTitle = styled.h1`
  max-width: 420px;
  font-size: 18px;
  color: ${colors.white};
  text-align: center;
  margin-bottom: 24px;
`;

export const AlertBtnsRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

export const HeaderLogo = styled.img`
  width: 192px;
  height: auto;
  margin-bottom: 47px;
`;
