// Core
import { withTranslation } from 'react-i18next';
import { graphql } from '@apollo/react-hoc';
import { inject, observer } from 'mobx-react';

// GraphQL
import QUESTIONS_QUERY from '../../gql/query/courseWithQuestions';
import INSERT_QUESTION_MUTATION from '../../gql/mutation/question/insertQuestion';
import UPDATE_QUESTION_MUTATION from '../../gql/mutation/question/updateQuestion';
import USER_QUESTIONS from '../../gql/query/userQuestion';
import { APP_STORE } from '../../stores/constants';

// Other
import compose from '../../utils/compose';

const questionQuery = graphql(QUESTIONS_QUERY, {
  props: ({ data: { question = [{}], theme = [{}], course = [{}], loading } }) => ({
    question: question[0],
    course: course[0],
    theme: theme[0],
    loading,
  }),
  options: ({ match }) => {
    const { courserId, themeId, questionId } = match.params;

    const where = { id: { _is_null: true } };
    if (questionId) {
      where.id = { _eq: questionId };
    }
    return {
      variables: {
        where,
        whereTheme: { id: { _eq: themeId } },
        whereCourse: { id: { _eq: courserId } },
      },
      fetchPolicy: 'network-only',
    };
  },
});

const insertQuestionMutate = graphql(INSERT_QUESTION_MUTATION, {
  props: ({ mutate }) => ({
    insertQuestionMutation: async objects =>
      mutate({
        variables: { objects },
      }),
  }),
  options: {
    refetchQueries: ['Questions'],
  },
});

const updateQuestionMutate = graphql(UPDATE_QUESTION_MUTATION, {
  props: ({ mutate }) => ({
    updateQuestionMutation: async (id, set) =>
      mutate({
        variables: { id, set },
      }),
  }),
  options: ({
    [APP_STORE]: {
      countQuestionsShow: { currentPage, value },
    },
  }) => ({
    refetchQueries: [
      {
        query: USER_QUESTIONS,
        variables: {
          limit: value,
          offset: value * currentPage,
        },
      },
      'Questions',
    ],
  }),
});

const enhancer = compose(
  inject(APP_STORE),
  observer,
  insertQuestionMutate,
  updateQuestionMutate,
  questionQuery,
  withTranslation()
);

export default enhancer;
