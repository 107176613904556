import i18n, { LOCALES } from '../../external/i18n';
import {
  COURSES_ROUTE,
  INTERNSHIP_ROUTE,
  NEWS_ROUTE,
  SETTINGS_ROUTE,
  SIGN_IN_ROUTE,
  NOT_FOUND_ROUTE,
} from '../../constants';

i18n.addResources(LOCALES.RU, 'translation', {
  [`Router/${SIGN_IN_ROUTE}`]: 'CodersLang pro · авторизация',
  [`Router/${COURSES_ROUTE}`]: 'CodersLang pro · курсы',
  [`Router/${INTERNSHIP_ROUTE}`]: 'CodersLang pro · стажировка',
  [`Router/${NEWS_ROUTE}`]: 'CodersLang pro · новости',
  [`Router/${SETTINGS_ROUTE}`]: 'CodersLang pro · настройки',
  [`Router/${NOT_FOUND_ROUTE}`]: 'CodersLang pro · страница не найдена',
});

i18n.addResources(LOCALES.EN, 'translation', {
  [`Router/${SIGN_IN_ROUTE}`]: 'CodersLang pro · sign in',
  [`Router/${COURSES_ROUTE}`]: 'CodersLang pro · courses',
  [`Router/${INTERNSHIP_ROUTE}`]: 'CodersLang pro · internship',
  [`Router/${NEWS_ROUTE}`]: 'CodersLang pro · news',
  [`Router/${SETTINGS_ROUTE}`]: 'CodersLang pro · settings',
  [`Router/${NOT_FOUND_ROUTE}`]: 'CodersLang pro · page not found',
});

export default i18n;
