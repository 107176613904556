// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// Other
import { prepareAwardsData } from './helpers';

// Styles and assets
import {
  AwardsContainer,
  AwardItem,
  AwardIcon,
  AwardTitle,
  AwardDescription,
  AwardsRow,
} from './styles';

// include localization files to the bundle
import './i18n';

class AwardsList extends Component {
  renderAward = ({ title, image, description }) => {
    const { t } = this.props;
    return (
      <AwardItem key={title}>
        <AwardIcon src={image} alt="award icon" />
        <div>
          <AwardTitle>{t(`Awards/${title}`)}</AwardTitle>
          <AwardDescription>{t(`Awards/${description}`)}</AwardDescription>
        </div>
      </AwardItem>
    );
  };

  renderAwardsRow = (awards, i) => (
    <AwardsRow key={`awards-row-${i}`}>{awards.map(this.renderAward)}</AwardsRow>
  );

  render() {
    const { awards, friendsCount } = this.props;
    const preparedAwardsChunks = prepareAwardsData(awards, friendsCount);
    return <AwardsContainer>{preparedAwardsChunks.map(this.renderAwardsRow)}</AwardsContainer>;
  }
}

AwardsList.propTypes = {
  friendsCount: PropTypes.number, // amount of user friends
  awards: PropTypes.object, // user achieved awards data
  t: PropTypes.func, // i18n translation func
};

export default withTranslation()(AwardsList);
