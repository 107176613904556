// Core
import styled, { css } from 'styled-components';
import { rem } from 'polished';

// Other
import { colors } from '../../assets/styles';

export const ContentWrapper = styled.div`
  flex: 1;
  padding: 30px;
`;

export const PageTitle = styled.h1`
  font-weight: 700;
  font-size: ${rem(20)};
  line-height: ${rem(26)};
  color: ${colors.theme1};
`;

export const FlagButton = styled.button`
  border-radius: 3px;
  padding: 3px;
  margin-right: 15px;
  cursor: pointer;
  ${({ isToggled }) =>
    isToggled &&
    css`
      border: solid 1px ${colors.theme1};
      background-color: ${colors.theme4};
    `}
  ${({ isError }) => isError && `border: solid 1px ${colors.error};`}
`;

export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
`;

export const FormView = styled.div`
  width: 42%;
`;

const FlexWrapper = styled.div`
  display: flex;
`;

export const InputWrapper = styled.div`
  margin-bottom: 5px;
`;

export const Wrapper = styled(FlexWrapper)`
  align-items: center;
  margin-top: 25px;
`;

export const ButtonsBlock = styled.div`
  display: flex;
`;

export const FlagIcon = styled.img`
  width: 20px;
`;

export const Text = styled.div`
  font-size: ${rem(12)};
  color: ${colors.black1};
`;

export const CheckboxText = styled(Text)`
  margin-left: 8px;
`;
