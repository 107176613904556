// Core
import styled, { css } from 'styled-components';
import { rem } from 'polished';

// Other
import { colors, BREAKPOINTS } from '../../../assets/styles';

const blurStyle = css`
  filter: blur(4px);
`;

export const DescriptionText = styled.p`
  color: ${colors.white};
  z-index: 10;
  font-size: ${rem(14)};
  line-height: ${rem(26)};
  letter-spacing: 0.28px;
  position: absolute;
  align-self: center;
  width: 100%;
  text-align: center;
`;

export const ProgressContainer = styled.div`
  display: flex;
  flex: 1;
  ${({ isBlur }) => isBlur && blurStyle}
  @media (max-width:${BREAKPOINTS.LARGE}) {
    flex-direction: column;
  }
`;

export const Wrapper = styled.div`
  ${({ isPremiumUser }) =>
    !isPremiumUser &&
    css`
      width: 100%;
      border-radius: 8px;
      box-shadow: 0 1px 6px 0 ${colors.whiteOpacity10};
      border: solid 1px ${colors.grey2};
      background-color: ${colors.grey8};
    `}
`;
