import styled, { css } from 'styled-components';
import { colors } from '../../../assets/styles';

export const OptionWrapper = styled.div`
  border-bottom: ${({ isLastItem }) => (isLastItem ? 0 : 1)}px solid ${colors.grey10};
  height: 54px;
  width: 150px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  cursor: pointer;
  background: ${colors.white};
  ${({ isActive, firstItem, isLastItem }) => css`
  border: 1px solid ${colors[isActive ? 'blue5' : 'grey10']};
  border-top: none;
  ${isLastItem &&
    css`
      border-bottom: none;
    `}
    ${firstItem &&
      css`
        border-top: 1px solid ${colors[isActive ? 'blue5' : 'grey10']};
        border-radius: 4px 4px 0 0;
      `}
      
    ${isActive &&
      css`
        background: ${colors.blue5};
      `}
  `}
`;

export const TextOption = styled.span`
  color: ${({ isActive }) => colors[isActive ? 'white' : 'blue5']};
  font-size: 18px;
`;

export const SelectWrapper = styled.div`
  width: 150px;
  position: relative;
  margin-right: 15px;
`;

export const OptionsWrapper = styled.div`
  position: absolute;
  bottom: 54px;
  box-shadow: 0px -16px 15px 2px ${colors.grey17};
  border-radius: 4px 4px 0 0;
`;

export const BlocSelected = styled.div`
  display: flex;
  width: 150px;
  justify-content: center;
  text-align: center;
  align-items: center;
  border: 1px solid ${colors.grey10};
  height: 54px;
  border-radius: 4px;
  cursor: pointer;
  ${({ isOpen }) =>
    isOpen &&
    css`
      border-top: 0px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    `}
`;

export const ArrowWrapper = styled.div`
  position: absolute;
  bottom: 15px;
  cursor: pointer;
  right: 15px;
`;
