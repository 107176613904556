import React from 'react';
import PropTypes from 'prop-types';

import { CheckboxContainer, HiddenCheckbox, StyledCheckbox, Icon } from './style';

const Checkbox = ({ checked, currentTheme, onClick }) => {
  const handleClick = () => {
    if (onClick) {
      onClick(!checked);
    }
  };
  return (
    <CheckboxContainer currentTheme={currentTheme}>
      <HiddenCheckbox checked={checked} readOnly />
      <StyledCheckbox currentTheme={currentTheme} checked={checked} onClick={handleClick}>
        <Icon currentTheme={currentTheme} viewBox="0 0 24 24">
          <polyline points="20 3 9 14 4 9" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  );
};

Checkbox.propTypes = {
  currentTheme: PropTypes.string,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Checkbox;
