// Core
import S3FileUpload from 'react-s3';
import { apiFetch } from '../../utils/apiFetch';
import getFirebaseToken from '../../utils/getFirebaseToken';

/**
 * Amazon S3 bucket config for uploading users avatars
 */
const S3_CONFIG = {
  bucketName: 'coderslang-course-images',
  region: 'eu-central-1',
  accessKeyId: 'AKIA2QF7ZHIYM3N5MJAT',
  secretAccessKey: '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',
};

/**
 * Extracts file name from uri string
 * @param {string} uri file uri
 */
const getFileName = uri => {
  // getting file name
  const [name] = uri.split('/').slice(-1);

  return name;
};

/**
 * Deletes a file from s3
 * @param {string} fileUrl url to the file
 */
export const deleteFromS3 = async fileUrl => {
  const name = getFileName(fileUrl);
  const { ok, status } = await S3FileUpload.deleteFile(name, S3_CONFIG);
  if (!ok) {
    throw new Error(`Failed to delete prev image. Status ${status}`);
  }
  return ok;
};

/**
 * Uploads file to s3 bucket
 * @param {object} file file (image BLOB object) for uploading to s3
 */
export const uploadToS3 = async (file, deleteUrl, key) => {
  const response = await apiFetch({
    key,
    method: 'POST',
    token: await getFirebaseToken(),
    body: JSON.stringify({ fileName: file.name }),
  });

  if (response.status !== 200) {
    throw new Error(`Failed to get upload link. Status ${response.status}`);
  }
  const {
    data: { uploadUrl, fileLink },
  } = response;

  const uploaded = await apiFetch({
    path: uploadUrl,
    method: 'PUT',
    customHeaders: {},
    body: file,
  });

  if (uploaded.status !== 200) {
    throw new Error(`Failed to upload image to S3. Status ${uploaded.status}`);
  }

  // if file uploaded successfully we returning its location on s3
  return fileLink;
};
