// Core
import styled from 'styled-components';

// Other
import { BREAKPOINTS, colors } from '../../assets/styles';

export const ContentWrapper = styled.div`
  width: 100%;
  padding: 100px 15px 38px;
  flex: 1;
  min-height: 100%;

  @media (min-width: ${BREAKPOINTS.SMALL}) {
    padding: 100px 30px 38px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AuthContainer = styled.div`
  max-width: 288px;
  min-width: 250px;
`;

export const AuthTitle = styled.h1`
  font-size: 18px;
  color: ${colors.theme1};
  margin-bottom: 47px;
  text-align: center;
`;

export const AlertBtnsRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;
