// Core
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { graphql } from '@apollo/react-hoc';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';

// Components
import GradientButton from '../../../components/Buttons/GradientButton';
import Pagination from '../../../components/Pagination';
import ScreenLoader from '../../../components/ScreenLoader';
import DeleteQuestionModal from '../DeleteQuestion';

// Mobx
import { APP_STORE } from '../../../stores/constants';

// Other
import compose from '../../../utils/compose';
import {
  ADD_ROUTE,
  ALERT_MODAL,
  COURSES_ROUTE,
  EDIT_ROUTE,
  ITEMS_PER_PAGE,
  SMALL_MODAL,
} from '../../../constants';

// GraphQL
import QUESTIONS_QUERY from '../../../gql/query/courseWithQuestions';
import { lastDailyQuestionQuery } from '../../Themes/ThemesPages/enhancer';

// Styles and assets
import {
  ContentWrapper,
  CreateBtnIcon,
  HeaderRow,
  IconDefault,
  LeftWrapper,
  PageTitle,
  ThemeBtn,
  BodySection,
  TextNumberTheme,
  WrapperEmpty,
  Link,
  TriangleUp,
  TriangleDown,
  TrianglesBlock,
  StyledTableCell,
  ExplanationMark,
} from './styles';
import { LastDailyQuestion } from '../../Themes/styles';
import { Table, Thead, Tbody, TableRow, TableHeadCell, TableCell } from '../../../components/Table';
import backIcon from '../../../assets/images/arrow/arrow-back.png';
import plusIcon from '../../../assets/images/plus.png';
import editIcon from '../../../assets/images/courses/pencil-active.png';
import deleteIcon from '../../../assets/images/courses/trash-active.png';
import thumbUp from '../../../assets/images/thumb/thumb-up.png';
import thumbDown from '../../../assets/images/thumb/thumb-down.png';
import avatarPlaceholder from '../../../assets/images/avatar-placeholder.png';

import './i18n';

const SELECT_OPTIONS = [
  { value: 10, label: '10' },
  { value: ITEMS_PER_PAGE, label: ITEMS_PER_PAGE },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 'ALL', label: 'ALL' },
];

@inject(APP_STORE)
@observer
class Questions extends PureComponent {
  state = {
    sortByValue: 0,
    sortFrom: false,
  };

  showDeleteQuestionModal = question => () => {
    const {
      t,
      [APP_STORE]: { showModal, closeModal },
    } = this.props;
    showModal({
      type: ALERT_MODAL,
      size: SMALL_MODAL,
      content: <DeleteQuestionModal question={question} closeModal={closeModal} />,
      modalProps: {
        title: t('Questions/deleteTitle'),
        titleFontSize: 18,
      },
    });
  };

  handleRouteToEdit = link => () => {
    const { history } = this.props;
    history.push(link);
  };

  renderQuestionItem = (question, index) => {
    const {
      course: { id: courseId, has_daily_question },
      theme: { id: themeId },
      isAuthor,
    } = this.props;
    const {
      app_user,
      id: questionId,
      details: {
        ru: {
          description,
          explanation: { text: explanation },
        },
        en: {
          description: descriptionEn,
          explanation: { text: explanationEn },
        },
      },
      daily_question_date,
      like: {
        aggregate: { count: countLike },
      },
      dislike: {
        aggregate: { count: countDislike },
      },
    } = question;
    const routeToEdit = `/admin/${COURSES_ROUTE}/${courseId}/${themeId}/${questionId}/${EDIT_ROUTE}`;

    const explanationExists = explanation && explanationEn && explanation !== explanationEn;
    const explanationMarkTitle = explanationExists ? 'Explanation Exists' : 'Check Explanation';

    return (
      <TableRow key={`${questionId}_${index}`} onClick={this.handleRouteToEdit(routeToEdit)}>
        <StyledTableCell visibleOverflow>
          <ExplanationMark check={!explanationExists} title={explanationMarkTitle}>
            {explanationExists ? '+' : '-'}
          </ExplanationMark>
          <TextNumberTheme>{questionId}</TextNumberTheme>
        </StyledTableCell>
        <TableCell>{description}</TableCell>
        <TableCell>{descriptionEn}</TableCell>
        {isAuthor && <TableCell>{app_user?.name}</TableCell>}
        {has_daily_question && <TableCell>{daily_question_date}</TableCell>}
        <TableCell>{countLike}</TableCell>
        <TableCell>{countDislike}</TableCell>
        <StyledTableCell>
          <ThemeBtn>
            <Link to={routeToEdit}>
              <CreateBtnIcon alt="edit" src={editIcon} />
            </Link>
          </ThemeBtn>
          <ThemeBtn>
            <CreateBtnIcon
              alt="delete"
              src={deleteIcon}
              onClick={this.showDeleteQuestionModal(question)}
            />
          </ThemeBtn>
        </StyledTableCell>
      </TableRow>
    );
  };

  changeSort = index => () => {
    this.setState(prevState => ({ sortByValue: index, sortFrom: !prevState.sortFrom }));
  };

  handleChangeSelector = value => {
    const {
      [APP_STORE]: { changeCountQuestionsShow },
      onClickPage,
    } = this.props;

    onClickPage({ selected: 0 });
    changeCountQuestionsShow(value);
  };

  renderHeaderTable = (item, index) => {
    const { sortFrom, sortByValue } = this.state;
    const {
      course: { has_daily_question },
    } = this.props;

    const indexSortLike = has_daily_question ? [3, 5] : [2, 4];
    return (
      <TableHeadCell withoutPadding={index === 0} key={`${item}_${index}`}>
        {item}
        {(index === 0 || (index > indexSortLike[0] && index <= indexSortLike[1])) && (
          <TrianglesBlock onClick={this.changeSort(index)}>
            {sortByValue === index ? (
              <>
                <TriangleUp isOpacity={sortFrom} />
                <TriangleDown isOpacity={sortFrom} />
              </>
            ) : (
              <>
                <TriangleUp isUsedNow />
                <TriangleDown isUsedNow />
              </>
            )}
          </TrianglesBlock>
        )}
      </TableHeadCell>
    );
  };

  renderContent = () => {
    const {
      loading,
      questions,
      t,
      countQuestions,
      currentPage,
      onClickPage,
      [APP_STORE]: {
        countQuestionsShow: { value },
        countQuestionsShow,
      },
      course: { has_daily_question },
      isAuthor,
    } = this.props;
    const { sortFrom, sortByValue } = this.state;

    // size table
    const gridColumns = `50px auto auto ${isAuthor ? '100px' : ''} ${
      has_daily_question ? ' 150px ' : ' '
    }   50px 50px 50px `;

    const withDailyQuestion = has_daily_question ? [t('Questions/datePublish')] : [];
    const withAuthorField = isAuthor ? [t('UserQuestion/author')] : [];

    const headTable = [
      t('Questions/id'),
      t('Questions/questionDescription'),
      t('Questions/questionDescriptionEn'),
      ...withAuthorField,
      ...withDailyQuestion,
      <IconDefault src={thumbUp} />,
      <IconDefault src={thumbDown} />,
      '',
    ];

    const countPage = Math.ceil(countQuestions / value);
    const sortQuestions = sortBy(
      questions,
      // eslint-disable-next-line no-nested-ternary
      sortByValue === 0
        ? 'id'
        : sortByValue === headTable.length - 3
        ? 'like.aggregate.count'
        : 'dislike.aggregate.count'
    );
    const finalSortQuestions = sortFrom ? reverse(sortQuestions) : sortQuestions;

    let questionsForCurrentPage;
    if (value === 'ALL') questionsForCurrentPage = finalSortQuestions;
    else {
      questionsForCurrentPage = finalSortQuestions.slice(
        currentPage * value,
        (currentPage + 1) * value
      );
    }
    if (loading) {
      return <ScreenLoader />;
    }
    if (!countQuestions) {
      return (
        <WrapperEmpty>
          <IconDefault src={avatarPlaceholder} />
          <PageTitle>{t('Questions/empty')}</PageTitle>
        </WrapperEmpty>
      );
    }

    return (
      <BodySection>
        <Table gridColumns={gridColumns}>
          <Thead>
            <TableRow>{headTable.map(this.renderHeaderTable)}</TableRow>
          </Thead>
          <Tbody>{questionsForCurrentPage.map(this.renderQuestionItem)}</Tbody>
        </Table>
        <Pagination
          countPage={countPage}
          itemsPerPage={value}
          itemsCount={questions.length}
          onClickPage={onClickPage}
          currentPage={currentPage}
          selectProps={{
            options: SELECT_OPTIONS,
            onChange: this.handleChangeSelector,
            defaultValue: countQuestionsShow,
          }}
        />
      </BodySection>
    );
  };

  render() {
    const {
      loading,
      t,
      lastDailyQuestion,
      history: { goBack },
      course: { title, id: courseId },
      theme: { names, id: themeId },
      i18n,
    } = this.props;

    const dailyQuestionDate =
      lastDailyQuestion && lastDailyQuestion.length
        ? lastDailyQuestion[0].daily_question_date
        : '-';

    if (loading) {
      return <ScreenLoader />;
    }
    return (
      <ContentWrapper>
        <HeaderRow>
          <LeftWrapper>
            <ThemeBtn>
              <IconDefault src={backIcon} onClick={goBack} />
            </ThemeBtn>
            <PageTitle>
              {title && title[i18n.language]} / {names} / {t('Questions/title')}
            </PageTitle>
          </LeftWrapper>
          <LastDailyQuestion>
            {t('App/lastDailyQuestion')}: <strong>{dailyQuestionDate}</strong>
          </LastDailyQuestion>
          <Link to={`/admin/${COURSES_ROUTE}/${courseId}/${themeId}/${ADD_ROUTE}`}>
            <GradientButton>
              <CreateBtnIcon src={plusIcon} />
              {t('Questions/createNew')}
            </GradientButton>
          </Link>
        </HeaderRow>
        {this.renderContent()}
      </ContentWrapper>
    );
  }
}

const questionsQuery = graphql(QUESTIONS_QUERY, {
  props: ({
    data: {
      question,
      theme = [{}],
      course = [{}],
      question_aggregate = { aggregate: { count: 0 } },
      author_questions_aggregate,
      loading,
    },
  }) => ({
    questions: question,
    course: course[0],
    theme: theme[0],
    isAuthor: !!author_questions_aggregate?.aggregate?.count,
    countQuestions: question_aggregate.aggregate.count,
    loading,
  }),
  options: ({ match }) => {
    const { courserId, themeId } = match.params;
    const commonVariables = {
      deleted_at: { _is_null: true },
      moderation_status: { _is_null: true },
      theme_id: { _eq: themeId },
      course_id: { _eq: courserId },
    };
    return {
      variables: {
        where: commonVariables,
        authorAggregateWhere: {
          ...commonVariables,
          author_id: { _is_null: false },
        },
        whereTheme: { id: { _eq: themeId } },
        whereCourse: { id: { _eq: courserId } },
      },
      fetchPolicy: 'network-only',
    };
  },
});

Questions.propTypes = {
  t: PropTypes.func, // i18n translate func
  history: PropTypes.object, // object with history route
  course: PropTypes.object, // object course for this questions
  theme: PropTypes.object, // object theme for this questions
  questions: PropTypes.array, // array questions
  loading: PropTypes.bool, // is loading questions
  onClickPage: PropTypes.func, // open page func
  countQuestions: PropTypes.number, // number count themes
  currentPage: PropTypes.number, // number count themes
  i18n: PropTypes.object,
  lastDailyQuestion: PropTypes.object,
  isAuthor: PropTypes.bool,
};

const enhancer = compose(
  questionsQuery,
  lastDailyQuestionQuery,
  withTranslation()
);

export default enhancer(Questions);
