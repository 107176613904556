// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { graphql } from '@apollo/react-hoc';

// Components
import GradientButton from '../../../components/Buttons/GradientButton';
import Input from '../../../components/Input';
import ImagePicker from '../../../components/ImagePicker';

// GraphQL
import INSERT_THEME_MUTATION from '../../../gql/mutation/theme/insertTheme';

// Other
import { uploadToS3 } from '../../../external/s3';
import bugsnag from '../../../external/bugsnag';
import compose from '../../../utils/compose';

// Constants
import { THEME_IMAGE_UPLOAD } from '../../../constants';

// Styles and assets
import { ContentWrapper, ButtonWrapper, InputWrapper, FormRow } from './styles';

import './i18n';
import { getFieldsToUpdate } from '../../../utils/fieldsToUpdate';

class EditThemeModal extends PureComponent {
  constructor(props) {
    super(props);
    const {
      theme: { names: { ru, en }, picture_url, picture_url_dark, sort_order } = { names: {} },
    } = props;

    this.state = {
      pictureUrl: picture_url,
      pictureUrlDark: picture_url_dark,
      name_ru: ru || '',
      name_en: en || '',
      sortOrder: sort_order || null,
      loading: false,
      error: null,
    };
  }

  /**
   * Picks picture for provided picker name
   * @param {string} name - picker name
   */
  handlePictureChosen = name => pic => this.setState({ [name]: pic });

  /**
   * Changes input value for provided input name
   * @param {string} name - input name
   */
  handleChangeValue = name => ({ target: { value } }) =>
    this.setState({ [name]: value, error: null });

  handleSubmit = async () => {
    const {
      t,
      insertThemeMutation,
      updateThemeMutation,
      theme,
      closeModal,
      themes = [],
      currentCourserId: { courserId },
    } = this.props;
    const {
      name_ru,
      name_en,
      chosenPicture,
      pictureUrl,
      chosenPictureDark,
      pictureUrlDark,
      sortOrder,
    } = this.state;

    if (!name_ru.trim() || !name_en.trim()) {
      return this.setState({ error: new Error(t('Error/emptyField')) });
    }
    this.setState({ loading: true });
    try {
      const newThemeData = {
        names: {
          ru: name_ru,
          en: name_en,
        },
        course_id: courserId,
        sort_order: sortOrder || (themes?.length ? themes[themes?.length - 1].sort_order + 1 : 1),
      };

      if (chosenPicture) {
        newThemeData.picture_url = await uploadToS3(chosenPicture, pictureUrl, THEME_IMAGE_UPLOAD);
      }
      if (chosenPictureDark) {
        newThemeData.picture_url_dark = await uploadToS3(
          chosenPictureDark,
          pictureUrlDark,
          THEME_IMAGE_UPLOAD
        );
      }

      if (!theme) {
        // if new course
        await insertThemeMutation([newThemeData]);
      } else if (theme?.id) {
        const dataToUpdate = getFieldsToUpdate(newThemeData, theme);
        // if we updating existing course
        if (Object.keys(dataToUpdate).length) {
          await updateThemeMutation(theme.id, dataToUpdate);
        }
      }

      return closeModal();
    } catch (error) {
      this.setState({ loading: false });
      return bugsnag.notify(error);
    }
  };

  renderImagePicker = ({ loading, textLabel, imageLink, onImageChosen, key }) => (
    <ImagePicker
      disabled={loading}
      textLabel={textLabel}
      imageLink={imageLink}
      onImageChosen={onImageChosen}
      key={key}
    />
  );

  render() {
    const { t } = this.props;
    const { loading, error, name_ru, name_en, pictureUrl, pictureUrlDark } = this.state;

    const pickers = [
      {
        textLabel: t('NewThemeModal/pictureLabel'),
        imageLink: pictureUrl,
        onImageChosen: this.handlePictureChosen('chosenPicture'),
        key: 'image-picker-light',
      },
      {
        textLabel: t('NewThemeModal/pictureLabelDark'),
        imageLink: pictureUrlDark,
        onImageChosen: this.handlePictureChosen('chosenPictureDark'),
        key: 'image-picker-dark',
      },
    ];

    return (
      <ContentWrapper>
        <InputWrapper>
          <Input
            disabled={loading}
            value={name_ru}
            error={error}
            boldLabel
            label={t('NewThemeModal/nameLabel')}
            placeholder={t('NewThemeModal/namePlaceholder')}
            onChange={this.handleChangeValue('name_ru')}
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            disabled={loading}
            value={name_en}
            error={error}
            boldLabel
            label={t('NewThemeModal/nameLabelEn')}
            placeholder={t('NewThemeModal/namePlaceholderEn')}
            onChange={this.handleChangeValue('name_en')}
          />
        </InputWrapper>
        <FormRow>{pickers.map(this.renderImagePicker)}</FormRow>
        <ButtonWrapper>
          <GradientButton loading={loading} width={200} onClick={this.handleSubmit}>
            {t('App/save')}
          </GradientButton>
        </ButtonWrapper>
      </ContentWrapper>
    );
  }
}

const insertThemeMutate = graphql(INSERT_THEME_MUTATION, {
  props: ({ mutate }) => ({
    insertThemeMutation: async objects =>
      mutate({
        variables: { objects },
      }),
  }),
  options: {
    refetchQueries: ['CourseWithThemes', 'Themes'],
  },
});

const enhancer = compose(
  withTranslation(),
  insertThemeMutate
);

EditThemeModal.propTypes = {
  t: PropTypes.func, // i18n translate func
  insertThemeMutation: PropTypes.func, // creates new course item
  updateThemeMutation: PropTypes.func, // creates new course item
  closeModal: PropTypes.func, // closes the modal
  theme: PropTypes.object,
  currentCourserId: PropTypes.object, // number id courser
  themes: PropTypes.array, // array themes
};

export default enhancer(EditThemeModal);
