// Core
import styled from 'styled-components';

// Other
import { colors } from '../../assets/styles';

export const ContentWrapper = styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  background-color: ${colors.blue4};
`;
