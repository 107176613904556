// Core
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { inject, observer } from 'mobx-react';

// Mobx
import { APP_STORE } from '../../stores/constants';

// Other
import compose from '../../utils/compose';
import {
  ALERT_MODAL,
  MODAL_CLOSE_TIMEOUT,
  MEDIUM_MODAL,
  SMALL_MODAL,
  LARGE_MODAL,
} from '../../constants';

// Styles and assets
import { AlertTitle, AlertDescription, CloseBtn } from './styles';

ReactModal.setAppElement('#root');

const MODAL_WIDTH = {
  [SMALL_MODAL]: 600,
  [MEDIUM_MODAL]: 800,
  [LARGE_MODAL]: 1000,
};

class Modal extends PureComponent {
  renderContent = () => {
    const {
      [APP_STORE]: { modalData },
    } = this.props;

    if (!modalData) return null;

    const { type, modalProps, content } = modalData;

    switch (type) {
      case ALERT_MODAL: {
        const { title, description, titleFontSize } = modalProps || {};
        return (
          <div>
            <AlertTitle fontSize={titleFontSize}>{title || 'Alert'}</AlertTitle>
            {description && <AlertDescription>{description}</AlertDescription>}
            {content}
          </div>
        );
      }

      default:
        return content;
    }
  };

  render() {
    const {
      [APP_STORE]: { modalShown, closeModal, modalData },
    } = this.props;
    const { onClose, size } = modalData || {};

    return (
      <ReactModal
        isOpen={modalShown}
        onRequestClose={onClose || closeModal}
        closeTimeoutMS={MODAL_CLOSE_TIMEOUT}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            zIndex: 999,
          },
          content: {
            width: '80%',
            maxWidth: MODAL_WIDTH[size || MEDIUM_MODAL],
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '8px',
          },
        }}
      >
        <CloseBtn onClick={onClose || closeModal}>✖</CloseBtn>
        {this.renderContent()}
      </ReactModal>
    );
  }
}

const enhancer = compose(
  inject(APP_STORE),
  observer
);

Modal.propTypes = {
  [APP_STORE]: PropTypes.object, // mobx app store
};

export default enhancer(Modal);
