// Core
import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import { rem } from 'polished';

// Other
import { colors } from '../../assets/styles';

const ReactPaginateAdapter = ({
  container,
  page,
  className,
  active,
  pageLink,
  previousArrowLink,
  nextArrowLink,
  disabledBtn,
  ...props
}) => (
  <StyledWrapperPaginate className={className}>
    <ReactPaginate
      previousLinkClassName={previousArrowLink}
      nextLinkClassName={nextArrowLink}
      containerClassName={container}
      pageClassName={page}
      activeClassName={active}
      pageLinkClassName={pageLink}
      disabledClassName={disabledBtn}
      {...props}
    />
  </StyledWrapperPaginate>
);

ReactPaginateAdapter.propTypes = {
  container: PropTypes.string,
  active: PropTypes.string,
  page: PropTypes.string,
  pageLink: PropTypes.string,
  previousArrowLink: PropTypes.string,
  nextArrowLink: PropTypes.string,
  disabledBtn: PropTypes.string,
  className: PropTypes.string,
};

export const StyledPaginate = styled(ReactPaginateAdapter).attrs({
  previousArrowLink: 'previousArrowLink',
  nextArrowLink: 'nextArrowLink',
  container: 'container',
  page: 'page',
  active: 'active',
  disabledBtn: 'disabledBtn',
})`
  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    a {
      cursor: pointer;
      :focus {
        outline: none;
      }
    }
  }
  .page {
    padding: 8px 8px;
    list-style-type: none;
    font-size: ${rem(14)};
    font-weight: bold;
    color: ${colors.theme1};
    opacity: 0.4;
  }
  .page:hover {
    opacity: 1;
  }
  .active {
    opacity: 1;
  }
  .pageLink {
  }
  .previousArrowLink {
    opacity: 0.4;
  }
  .previousArrowLink:hover {
    opacity: 1;
  }
  .nextArrowLink {
    opacity: 0.4;
  }
  .nextArrowLink:hover {
    opacity: 1;
  }
  .disabledBtn {
    pointer-events: none;
  }
`;

export const IconArrow = styled.img`
  width: 12px;
  height: 12px;
  margin-top: 3px;
`;

const StyledWrapperPaginate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TextSelect = styled.h1`
  font-size: ${rem(12)};
  line-height: ${rem(26)};
  color: ${colors.theme1};
`;

export const WrapperRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const control = styles => ({
  ...styles,
  backgroundColor: colors.white,
  height: 6,
  width: 70,
  fontSize: '12px',
  margin: 10,
});
