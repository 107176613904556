// Core
import { withTranslation } from 'react-i18next';
import { graphql } from '@apollo/react-hoc';

// GraphQL
import TOP_QUESTION from '../../../gql/query/topQuestions';

// Other
import compose from '../../../utils/compose';

export const topQuestionQuery = graphql(TOP_QUESTION, {
  props: ({ data: { question = [], question_aggregate, loading } }) => ({
    questionList: question,
    loadingQuestion: loading,
    countQuestions: question_aggregate?.aggregate?.count,
  }),
  options: ({ limit, match, currentPage }) => {
    const { courserId: courseId } = match.params;
    return {
      variables: {
        courseId,
        limit,
        offset: limit * currentPage,
      },
      fetchPolicy: 'network-only',
    };
  },
});

const enhancer = compose(
  topQuestionQuery,
  withTranslation()
);

export default enhancer;
