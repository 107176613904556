// Core
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import { DatePickerButton, CalendarIcon } from './styles';
import calendarIcon from '../../../assets/images/questions-add-screen/calendar.png';

const DatePickerCustomInput = React.forwardRef(({ value, onClick, placeholderText }, ref) => (
  <DatePickerButton onClick={onClick} ref={ref}>
    <CalendarIcon src={calendarIcon} />
    {value || placeholderText}
  </DatePickerButton>
));

DatePickerCustomInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string,
  placeholderText: PropTypes.string,
};

export default DatePickerCustomInput;
