/**
 * Checks is there any fields to update
 * @param {object} newData new course data
 * @param {object} existingData existing course data
 */
export const getFieldsToUpdate = (newData, existingData) =>
  Object.keys(newData).reduce((result, key) => {
    if (newData[key] !== existingData[key]) {
      return { ...result, [key]: newData[key] };
    }
    return result;
  }, {});
