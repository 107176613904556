import { gql } from 'apollo-boost';

export default gql`
  query CourseWithThemes($where: course_bool_exp, $limit: Int, $offset: Int) {
    course(where: $where) {
      id
      title
      themes(limit: $limit, offset: $offset, order_by: { sort_order: asc }) {
        id
        names
        active
        course_id
        sort_order
        picture_url
        picture_url_dark
      }
    }
  }
`;
