// Core
import styled, { css } from 'styled-components';
import { rem } from 'polished';

// Other
import { colors } from '../../../assets/styles';

export const StyledButton = styled.button`
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 33px;
  min-height: 32px;

   ${({ transparent, isRed }) =>
     transparent
       ? css`
           background: transparent;
           border: solid 2px ${colors.grey7};
         `
       : css`
           background: linear-gradient(
             255deg,
             ${colors[isRed ? 'gradient3' : 'btnGradient1']},
             ${colors[isRed ? 'gradient4' : 'btnGradient2']}
           );
           box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
         `}
  color: ${colors.white};
  font-size: ${rem(12)};
  cursor: pointer;
  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `}

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
`;
