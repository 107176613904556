import { gql } from 'apollo-boost';

export default gql`
  query UsersQuestions($limit: Int!, $offset: Int!) {
    questions: question(
      limit: $limit
      offset: $offset
      where: { deleted_at: { _is_null: true }, moderation_status: { _eq: "PENDING" } }
    ) {
      id
      details(path: "ru")
      level
      app_user {
        name
      }
      course {
        id
        title(path: "ru")
      }
      theme {
        id
        names(path: "ru")
      }
      created_at
    }

    question_aggregate(
      where: { deleted_at: { _is_null: true }, moderation_status: { _eq: "PENDING" } }
    ) {
      aggregate {
        count
      }
    }
  }
`;
