import styled from 'styled-components';
import { colors } from '../../assets/styles';

export const Wrapper = styled.div`
  position: relative;
  flex: 1;
  width: 100%;

  & > div {
    display: flex;
    flex: 1;
  }
`;

export const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  border: 1px solid ${colors.theme4};
  border-radius: 8px;
  background-color: ${colors.white};
  color: ${colors.grey6};
  font-size: 16px;
  height: 48px;
  padding: 0 24px;
  &::placeholder {
    color: ${colors.grey7};
  }

  i {
    color: ${colors.grey5};
    font-size: 24px;
  }
`;

export const OptionsWrapper = styled.div`
  z-index: 100;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  width: 100%;
  max-height: 112px;
  overflow-y: scroll;
  border-radius: 0 0 4px 4px;
  background-color: ${colors.white};
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.11), 0 2px 2px rgba(0, 0, 0, 0.11),
    0 4px 4px rgba(0, 0, 0, 0.11), 0 6px 8px rgba(0, 0, 0, 0.11), 0 8px 16px rgba(0, 0, 0, 0.11);
`;

export const Option = styled.div`
  height: 32px;
  font-size: 16px;
  line-height: 32px;
  padding: 0 10px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  background-color: ${({ selected }) => (selected ? 'rgba(120, 132, 158, 0.4)' : colors.white)};
  &:hover {
    background-color: rgba(120, 132, 158, 0.4);
  }
  &:last-child {
    border-radius: 0 0 4px 4px;
  }
`;
