import i18n, { LOCALES } from '../../external/i18n';

i18n.addResources(LOCALES.RU, 'translation', {
  'Courses/title': 'Курсы',
  'Courses/createNew': 'Создать новый курс',
  'Courses/deleteTitle': 'Вы уверены что хотите удалить данный курс?',
});

i18n.addResources(LOCALES.EN, 'translation', {
  'Courses/title': 'Courses',
  'Courses/createNew': 'Create new course',
  'Courses/deleteTitle': 'Are you sure you want to delete the course?',
});
