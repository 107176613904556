import { gql } from 'apollo-boost';

export default gql`
  query Profile($where: app_user_bool_exp) {
    app_user(where: $where) {
      id
      name
      email
      picture_url
      awards
      current_course
      locale
      awards_score
      user_courses {
        course_id
        course {
          id
          title
          picture_url
          themes(order_by: { id: asc }) {
            id
            names
            picture_url
            picture_url_dark
            questions(where: { daily_question_date: { _is_null: true } }) {
              id
              user_questions(where: { app_user: $where }) {
                user_id
                score
                successful_attempts
                failed_attempts
                spent_time
                total_time_spent
              }
            }
          }
        }
      }
      friends_count: user_friends_by_friend_id_aggregate {
        aggregate {
          count
        }
      }
      user_subscriptions {
        subscription_id
        valid_until
      }
    }
  }
`;
