import i18n, { LOCALES } from '../../external/i18n';

i18n.addResources(LOCALES.RU, 'translation', {
  'Profile/betterUsers': 'Лучше, чем *BOLD* {{count}}% *BOLD* пользователей!',
  'Profile/timeStats': 'Статистика по времени',
  'Profile/themeProgress': 'Прогресс по темам',
  'Profile/radar': 'Диаграмма',
  'Profile/overlay': 'Общее:',
  'Profile/rates': 'Коэффициент',
  'Profile/progress': 'Прогресс',
  'Profile/progressTitle': 'Прогресс знаний',
  'Profile/awardsTitle': 'Достижения',
  'Profile/noName': 'Без имени',
  'Profile/title': 'CodersLang · Профиль',
  'Profile/description': 'Профиль пользователя',
  'Profile/descriptionPremium': 'Прогресс знаний доступен только для Премиум-пользователей',
  'Profile/notification':
    'Это превью профиля и он доступен только Вам. Общий доступ к профилю доступен только для пользователей с Премиум подпиской.',
});

i18n.addResources(LOCALES.EN, 'translation', {
  'Profile/betterUsers': 'Better then *BOLD*{{count}}%*BOLD* of users!',
  'Profile/timeStats': 'Time stats',
  'Profile/themeProgress': 'Theme progress',
  'Profile/radar': 'Radar chart',
  'Profile/rates': 'Rates',
  'Profile/progress': 'Progress',
  'Profile/progressTitle': 'Knowledge progress',
  'Profile/awardsTitle': 'Achievements',
  'Profile/noName': 'Without name',
  'Profile/title': 'CodersLang · Profile',
  'Profile/overlay': 'Total:',
  'Profile/description': 'Profile of',
  'Profile/descriptionPremium': 'Progress is available only for Premium users',
  'Profile/notification':
    'This is a profile preview and it is only available to you. Public access is available only to Premium members.',
});

export default i18n;
