// Core
import React, { PureComponent } from 'react';
import { graphql } from '@apollo/react-hoc';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import moment from 'moment';

// Components
import ScreenLoader from '../../components/ScreenLoader';
import GradientButton from '../../components/Buttons/GradientButton';
import Pagination from '../../components/Pagination';
import DeleteCourseModal from './DeleteNewsModal';

// Mobx
import { APP_STORE } from '../../stores/constants';

// GraphQL
import NEWS_QUERY from '../../gql/query/news';

// Other
import { SMALL_MODAL, ALERT_MODAL, ITEMS_PER_PAGE } from '../../constants';
import { gridColumns } from './helpers';
import compose from '../../utils/compose';

// Styles and assets
import {
  ContentWrapper,
  PageTitle,
  HeaderRow,
  Icon,
  IdOfInternshipProject,
  LinkAndButton,
  ButtonsTd,
  Link,
  Image,
  IdAndLogo,
  StyledTableCell,
} from './styles';
import { Table, Thead, TableHeadCell, Tbody, TableRow } from '../../components/Table';

import plusIcon from '../../assets/images/plus.png';
import deleteIcon from '../../assets/images/courses/trash-active.png';
import editIcon from '../../assets/images/courses/pencil-active.png';
import coderslangPreview from '../../assets/images/news/coderslang-logo-preview.png';

import './i18n';

@inject(APP_STORE)
@observer
class News extends PureComponent {
  handlePageChange = page => {
    const { refetch } = this.props;
    refetch({ limit: ITEMS_PER_PAGE, offset: page.selected * ITEMS_PER_PAGE });
  };

  showModal = newsData => () => {
    const {
      t,
      [APP_STORE]: { showModal, closeModal },
    } = this.props;

    showModal({
      type: ALERT_MODAL,
      size: SMALL_MODAL,
      content: <DeleteCourseModal newsData={newsData} closeModal={closeModal} />,
      modalProps: {
        title: t('News/deleteTitle'),
      },
    });
  };

  renderListItem = item => {
    const {
      name,
      updated_at,
      id,
      title,
      description,
      title_en,
      description_en,
      picture_url,
    } = item;
    return (
      <TableRow key={`${name}_${id}`}>
        <IdAndLogo>
          <IdOfInternshipProject>{id}</IdOfInternshipProject>
          <Image src={picture_url || coderslangPreview} />
        </IdAndLogo>
        <StyledTableCell>{title}</StyledTableCell>
        <StyledTableCell>{title_en}</StyledTableCell>
        <StyledTableCell>{description}</StyledTableCell>
        <StyledTableCell>{description_en}</StyledTableCell>
        <StyledTableCell isViolet>{moment(updated_at).format('DD.MM.YYYY')}</StyledTableCell>
        <ButtonsTd>
          <LinkAndButton>
            <Link to={`/admin/news/edit/${id}`}>
              <Icon src={editIcon} />
            </Link>
            <button type="button" onClick={this.showModal(item)}>
              <Icon src={deleteIcon} />
            </button>
          </LinkAndButton>
        </ButtonsTd>
      </TableRow>
    );
  };

  render() {
    const { t, news, loading, newsCount } = this.props;
    const namesOfCells = [
      t('News/ID'),
      t('News/titleRu'),
      t('News/titleEn'),
      t('News/description'),
      t('News/descriptionEn'),
      t('News/Date'),
    ];
    if (loading) {
      return <ScreenLoader />;
    }
    const countPage = Math.ceil(newsCount / ITEMS_PER_PAGE);

    return (
      <ContentWrapper>
        <HeaderRow>
          <PageTitle>{t('News/title')}</PageTitle>
          <Link to="/admin/news/add">
            <GradientButton>
              <Icon src={plusIcon} />
              {t('News/createNews')}
            </GradientButton>
          </Link>
        </HeaderRow>
        <Table gridColumns={gridColumns}>
          <Thead>
            <TableRow>
              {namesOfCells.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableHeadCell key={`cell-${index}`}>{item}</TableHeadCell>
              ))}
              <TableHeadCell />
            </TableRow>
          </Thead>
          <Tbody>{news.map(this.renderListItem)}</Tbody>
        </Table>
        <Pagination
          itemsPerPage={ITEMS_PER_PAGE}
          countPage={countPage}
          onClickPage={this.handlePageChange}
          itemsCount={newsCount}
        />
      </ContentWrapper>
    );
  }
}

const newsQuery = graphql(NEWS_QUERY, {
  props: ({ data: { news, loading, refetch, news_aggregate = { aggregate: { count: 0 } } } }) => ({
    news,
    loading,
    refetch,
    newsCount: news_aggregate.aggregate.count,
  }),
  options: {
    variables: {
      limit: ITEMS_PER_PAGE,
      offset: 0,
    },
    fetchPolicy: 'network-only',
  },
});

const enhancer = compose(
  newsQuery,
  withTranslation()
);

News.propTypes = {
  refetch: PropTypes.func, // function to refetch news data
  loading: PropTypes.bool, // is news loading now
  newsCount: PropTypes.number, // total amount of news
  news: PropTypes.arrayOf(PropTypes.object), // list of news
  t: PropTypes.func, // i18n translate func
};

export default enhancer(News);
