// Core
import { withTranslation } from 'react-i18next';
import { graphql } from '@apollo/react-hoc';

// GraphQL
import COURSE_WITH_THEMES_QUERY from '../../../gql/query/courseWithThemes';
import COURSE_WITH_TITLE_QUERY from '../../../gql/query/courseWithTitle';
import THEMES_QUERY from '../../../gql/query/themes';
import UPDATE_THEME_MUTATION from '../../../gql/mutation/theme/updateTheme';
import LAST_DAILY_QUESTION from '../../../gql/query/lastDailyQuestion';

// Other
import compose from '../../../utils/compose';
import { ITEMS_PER_PAGE } from '../../../constants';

export const lastDailyQuestionQuery = graphql(LAST_DAILY_QUESTION, {
  props: ({ data: { question }, loading }) => ({
    loadingDaily: loading,
    lastDailyQuestion: question,
  }),
  options: ({
    match: {
      params: { courserId },
    },
  }) => ({
    variables: {
      where: { course_id: { _eq: courserId }, daily_question_date: { _is_null: false } },
    },
    fetchPolicy: 'network-only',
  }),
});

export const coursesQuery = graphql(COURSE_WITH_THEMES_QUERY, {
  props: ({ data: { course = [{}], loading } }) => ({
    course: course[0],
    loadingCourses: loading,
  }),
  options: ({ match, currentPage }) => {
    const { courserId } = match.params;
    return {
      variables: {
        where: { id: { _eq: courserId } },
        limit: ITEMS_PER_PAGE,
        offset: ITEMS_PER_PAGE * currentPage,
      },
      fetchPolicy: 'network-only',
    };
  },
});

export const coursesTitleQuery = graphql(COURSE_WITH_TITLE_QUERY, {
  props: ({ data: { course = [{}], loading } }) => ({
    course: course[0],
    loadingCourses: loading,
  }),
  options: ({ match }) => {
    const { courserId } = match.params;
    return {
      variables: {
        where: { id: { _eq: courserId } },
      },
      fetchPolicy: 'network-only',
    };
  },
});

const themesQuery = graphql(THEMES_QUERY, {
  props: ({ data: { theme, loading, theme_aggregate = { aggregate: { count: 0 } } } }) => ({
    allThemes: theme,
    countThemes: theme_aggregate.aggregate.count,
    loadingAllThemes: loading,
  }),
  options: ({ match }) => {
    const { courserId } = match.params;
    return {
      variables: {
        where: { course_id: { _eq: courserId } },
      },
      fetchPolicy: 'network-only',
    };
  },
});

const updateThemeMutate = graphql(UPDATE_THEME_MUTATION, {
  props: ({ mutate }) => ({
    updateThemeMutation: async (id, set) =>
      mutate({
        variables: { id, set },
      }),
  }),
  options: {
    refetchQueries: ['CourseWithThemes', 'Themes'],
  },
});

const enhancer = compose(
  updateThemeMutate,
  themesQuery,
  coursesQuery,
  withTranslation()
);

export default enhancer;
