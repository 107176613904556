// Core
import styled from 'styled-components';
import { rem } from 'polished';

const MainWrapper = styled.div`
  font-size: ${rem(20)};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
`;

export default MainWrapper;
