// Core
import styled, { css } from 'styled-components';
import { rem } from 'polished';

// Other
import { colors } from '../../assets/styles';

export const WrapperEmpty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

/**
 * @property {string} gridColumns
 * @property {string} paddingBottom
 */
export const Table = styled.table`
  display: grid;
  border-collapse: collapse;
  ${({ paddingBottom, gridColumns }) => css`
    grid-template-columns: ${gridColumns || css`50px auto auto 50px 50px 50px`};
    ${paddingBottom &&
      css`
        padding-bottom: ${paddingBottom}px;
      `}
  `}
`;

export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Thead = styled.thead`
  display: contents;
`;

export const Tbody = styled.tbody`
  display: contents;
`;

export const TableRow = styled.tr`
  cursor: pointer;
  display: contents;
`;

/**
 * @property {boolean} withoutPadding
 */
export const TableHeadCell = styled.th`
  padding: 15px ${({ withoutPadding }) => (withoutPadding ? 0 : 10)}px;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: ${colors.gradient1};
  font-weight: bold;
  display: flex;
  font-size: ${rem(12)};
  color: ${colors.white};
`;

/**
 * @property {boolean} active // if Active is false do Table Cell hidden for this theme
 * @property {boolean} isOpenInTheme // use from Theme Screen
 * @property {boolean} isTextRight
 * @property {boolean} isViolet
 */
export const TableCell = styled.td`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid ${colors.theme4};
  font-size: ${rem(12)};
  padding: 15px 10px;
  &:hover {
    font-weight: bold;
  }
  ${({ isTextRight, isViolet }) => css`
    color: ${colors[isViolet ? 'theme3' : 'theme5']};
    text-align: ${isTextRight ? 'right' : 'left'};
  `}
`;
