// Core
import styled from 'styled-components';
import { rem } from 'polished';

// Other
import { colors, BREAKPOINTS } from '../../../assets/styles';

export const AwardsContainer = styled.div`
  margin-top: 14px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const AwardsRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (min-width: ${BREAKPOINTS.MEDIUM}) {
    flex-direction: row;
  }
`;

export const AwardItem = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  margin-bottom: 21px;
`;

export const AwardIcon = styled.img`
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
  margin-right: 15px;

  @media (min-width: ${BREAKPOINTS.SMALL}) {
    width: 84px;
    height: 84px;
    min-width: 84px;
    min-height: 84px;
  }
`;

export const AwardTitle = styled.p`
  font-weight: bolder;
  font-size: ${rem(14)};
  line-height: ${rem(26)};
  color: ${colors.grey6};
`;

export const AwardDescription = styled.p`
  font-size: ${rem(12)};
  line-height: ${rem(18)};
  color: ${colors.grey9};
`;
