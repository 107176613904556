import { getUnit } from '../../../utils/getUnit';

export const validation = (
  { name, duration, interns_count, knowledge_level, programming_langs },
  t
) => {
  const errorArray = {};
  if (!duration.unitsCount) {
    errorArray.durationOfProjectFrom = new Error(t('Error/emptyField'));
  }
  if (!duration.units) {
    errorArray.durationOfProjectUnits = new Error(t('Error/emptyField'));
  }
  if (!interns_count.quantity) {
    errorArray.amountOfPersons = new Error(t('Error/emptyField'));
  }
  if (!name) {
    errorArray.projectName = new Error(t('Error/emptyField'));
  }
  if (!knowledge_level.length) {
    errorArray.knowledgeLevel = new Error(t('Error/emptyField'));
  }
  if (!programming_langs.length) {
    errorArray.progLangs = new Error(t('Error/emptyField'));
  }
  return errorArray;
};

export const getUnitsOptions = (units = [], from, t) =>
  [...units].map(unit => ({
    ...unit,
    label: getUnit(from, unit.units, t),
  }));
