// Core
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import { Input, Label, Form, WrapperInput, FlagIcon } from './styles';

const RadioButton = ({ handleSubmit, withoutMargin, label, icon, isError, checked, type }) => (
  <Form withoutMargin={withoutMargin} onSubmit={handleSubmit} isError={isError} checked={checked}>
    <Label checked={checked}>
      <WrapperInput>
        <Input type={type} checked={checked} onChange={handleSubmit} />
      </WrapperInput>
      {icon && <FlagIcon src={icon} />}
      {label}
    </Label>
  </Form>
);

RadioButton.defaultProps = {
  type: 'radio',
};

RadioButton.propTypes = {
  handleSubmit: PropTypes.func,
  label: PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.string,
  isError: PropTypes.bool,
  withoutMargin: PropTypes.bool,
  checked: PropTypes.bool,
};

export default RadioButton;
