import i18n, { LOCALES } from '../../../external/i18n';

i18n.addResources(LOCALES.RU, 'translation', {
  'Questions/title': 'Вопросы',
  'Questions/createNew': 'Создать новый вопрос',
  'Questions/id': 'ID',
  'Questions/questionDescription': 'Описание',
  'Questions/questionDescriptionEn': 'Description',
  'Questions/datePublish': 'Дата публикации',
  'Questions/empty': 'Вопросов еще нет',
  'Questions/deleteTitle':
    'Это опасная операция и её невозможно будет отменить. Вы уверены, что хотите удалить этот вопрос?',
  'Questions/labelConfirmation': 'Введите delete для подтверждения.',
});

i18n.addResources(LOCALES.EN, 'translation', {
  'Questions/title': 'Questions',
  'Questions/createNew': 'Create new question',
  'Questions/id': 'ID',
  'Questions/questionDescription': 'Description',
  'Questions/questionDescriptionEn': 'Description En',
  'Questions/datePublish': 'Publication date',
  'Questions/empty': 'There are no questions',
  'Questions/deleteTitle':
    'This operation is dangerous and cannot be undone. Are you sure you want to delete this question?',
  'Questions/labelConfirmation': 'Type delete to confirm',
});
