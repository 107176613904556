import { API } from '../constants';

export const apiFetch = async ({ path, key, method, customHeaders, token, body }) => {
  const resp = await fetch(key ? API[key] : path, {
    method,
    headers: customHeaders || {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body,
  });

  const { status } = resp;
  const contentType = resp.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') !== -1) {
    const data = await resp.json();
    return { status, data };
  }
  return { status };
};
