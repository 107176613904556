import { observable, action } from 'mobx';

/**
 * User auth data store
 */
class UserStore {
  /**
   * User data
   */
  @observable userData = undefined;

  /**
   * Saves user data to the store
   */
  @action setUserData = data => {
    this.userData = {
      ...this.userData,
      ...data,
    };
  };

  /**
   * Clears store data
   */
  @action clearUserData = () => {
    this.userData = undefined;
  };
}

const userStore = new UserStore();

export default userStore;
