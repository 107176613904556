import firebase from 'firebase';

export default () =>
  new Promise((resolve, reject) => {
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(token => resolve(token))
      .catch(error => reject(error));
  });
