// Core
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { rem } from 'polished';
import { colors } from '../../assets/styles';

export const ContentWrapper = styled.div`
  flex: 1;
  padding: 30px;
`;

export const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
`;

export const Link = styled(NavLink)`
  text-decoration: none;
`;

export const ScreenName = styled.h1`
  font-weight: 700;
  font-size: ${rem(20)};
  line-height: 26px;
  color: ${colors.theme1};
`;

export const IconButton = styled.button`
  flex: 1;
`;

export const LinkAndButton = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
`;

export const Icon = styled.img`
  width: 11px;
  margin-right: 5px;
`;

export const IdOfInternshipProject = styled.p`
  display: inline-block;
  padding-right: 20px;
`;

export const NameOfInternshipProject = styled.p`
  display: inline-block;
`;

export const ButtonsTd = styled.td`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: 20px;
  font-size: ${rem(12)};
  padding-bottom: 10px;
  text-align: right;
  border-bottom: 1px solid ${colors.theme4};
`;
