import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import PropTypes from 'prop-types';

import { Wrapper, ValueContainer, OptionsWrapper, Option } from './styles';

export default function Select({ options, placeholder, width, onChange, defaultValue }) {
  const [isFocused, setIsFocused] = useState(false);

  const handleSelect = option => () => {
    onChange(option);
  };

  return (
    <Wrapper width={width} onClick={() => setIsFocused(!isFocused)}>
      <OutsideClickHandler
        display="flex"
        disabled={!isFocused}
        onOutsideClick={() => setIsFocused(false)}
      >
        <ValueContainer isFocused={isFocused}>
          {defaultValue?.label || placeholder}
          {/* <Icon iconClass={`icon-arrow-${isFocused ? 'up' : 'down'}`} /> */}
        </ValueContainer>
        {isFocused && (
          <OptionsWrapper>
            {options.map(({ value, label }) => (
              <Option
                key={`${label}-${value}`}
                onClick={handleSelect({ value, label })}
                selected={defaultValue?.value === value}
              >
                {label}
              </Option>
            ))}
          </OptionsWrapper>
        )}
      </OutsideClickHandler>
    </Wrapper>
  );
}

Select.propTypes = {
  options: PropTypes.array,
  placeholder: PropTypes.string,
  width: PropTypes.number,
  onChange: PropTypes.func,
  defaultValue: PropTypes.object,
};
