// Core
import React, { PureComponent } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';

// Screens
import NotFound from '../../screens/NotFound';
import Profile from '../../screens/Profile';
import SignIn from '../../screens/SignIn';
import Login from '../../screens/Login';
import Settings from '../../screens/Settings';
import Notification from '../../screens/Notification';
import Internship from '../../screens/Internship';
import InternshipPage from '../../screens/Internship/InternshipPage';
import News from '../../screens/News';
import NewsEdit from '../../screens/NewsEditScreen';
import Courses from '../../screens/Courses';
import Themes from '../../screens/Themes';
import Questions from '../../screens/Questions';
import QuestionsEditScreen from '../../screens/QuestionsEditScreen';
import UsersQuestions from '../../screens/UsersQuestions';
import RecruitingScreen from '../../screens/Recruiting';

// Components
import Header from '../Header';
import Footer from '../Footer';
import Modal from '../Modal';
import ScreenLoader from '../ScreenLoader';
import Sidebar from '../Sidebar';

// Other
import firebase from '../../external/firebase';
import readUserData from '../../utils/readUserData';
import { USER_STORE } from '../../stores/constants';
import bugsnag from '../../external/bugsnag';

// Styles and assets
import MainWrapper from '../MainWrapper';
import { ContentWrapper } from './styles';

import i18n from './i18n';
import {
  PROFILE_ROUTE,
  NOT_FOUND_ROUTE,
  SIGN_IN_ROUTE,
  COURSES_ROUTE,
  INTERNSHIP_ROUTE,
  INTERNSHIP_COURSE_ROUTE,
  INSERT_INTERNSHIP_ROUTE,
  NEWS_ROUTE,
  SETTINGS_ROUTE,
  ADD_ROUTE,
  EDIT_ROUTE,
  NOTIFICATION_ROUTE,
  LOGIN_ROUTE,
  ALLOWED_ROLES,
  USER_QUESTION,
  RECRUITING_ROUTE,
} from '../../constants';

const ADMIN_ROUTES = [
  {
    name: SIGN_IN_ROUTE,
    screen: SignIn,
    path: '/admin',
  },
  {
    name: COURSES_ROUTE,
    screen: Courses,
    path: `/admin/${COURSES_ROUTE}`,
  },
  {
    name: COURSES_ROUTE,
    screen: Themes,
    path: `/admin/${COURSES_ROUTE}/:courserId`,
  },
  {
    name: COURSES_ROUTE,
    screen: Questions,
    path: `/admin/${COURSES_ROUTE}/:courserId/:themeId`,
  },
  {
    name: COURSES_ROUTE,
    screen: QuestionsEditScreen,
    path: `/admin/${COURSES_ROUTE}/:courserId/:themeId/${ADD_ROUTE}`,
  },
  {
    name: COURSES_ROUTE,
    screen: QuestionsEditScreen,
    path: `/admin/${COURSES_ROUTE}/:courserId/:themeId/:questionId/${EDIT_ROUTE}`,
  },
  {
    name: INTERNSHIP_ROUTE,
    screen: Internship,
    path: `/admin/${INTERNSHIP_ROUTE}`,
  },
  {
    name: INSERT_INTERNSHIP_ROUTE,
    screen: InternshipPage,
    path: `/admin/${INTERNSHIP_ROUTE}/InsertInternship`,
  },
  {
    name: INTERNSHIP_COURSE_ROUTE,
    screen: InternshipPage,
    path: `/admin/${INTERNSHIP_ROUTE}/:id`,
  },
  {
    name: NEWS_ROUTE,
    screen: News,
    path: `/admin/${NEWS_ROUTE}`,
  },
  {
    name: NEWS_ROUTE,
    screen: NewsEdit,
    path: `/admin/${NEWS_ROUTE}/${ADD_ROUTE}`,
  },
  {
    name: USER_QUESTION,
    screen: UsersQuestions,
    path: `/admin/${USER_QUESTION}`,
  },
  {
    name: NEWS_ROUTE,
    screen: NewsEdit,
    path: `/admin/${NEWS_ROUTE}/${EDIT_ROUTE}/:id`,
  },
  {
    name: SETTINGS_ROUTE,
    screen: Settings,
    path: `/admin/${SETTINGS_ROUTE}`,
  },
  {
    name: NOTIFICATION_ROUTE,
    screen: Notification,
    path: `/admin/${NOTIFICATION_ROUTE}`,
  },
];

const PUBLIC_ROUTES = [SIGN_IN_ROUTE, PROFILE_ROUTE, NOT_FOUND_ROUTE, LOGIN_ROUTE];

@inject(USER_STORE)
@observer
class Router extends PureComponent {
  state = {
    currentRoute: undefined,
    loading: true,
  };

  componentDidMount() {
    const unsubscribe = firebase.auth().onAuthStateChanged(async user => {
      const {
        [USER_STORE]: { setUserData },
      } = this.props;

      unsubscribe();

      if (user) {
        try {
          // if there are user in auth state we then looking for the user in db
          const userData = await readUserData(user.uid);
          if (userData) {
            setUserData(userData);
            if (i18n.language !== userData.locale) {
              i18n.changeLanguage(userData.locale);
            }
          }
        } catch (err) {
          bugsnag.notify(err, false);
        }
      }
      this.setState({ loading: false });
    });
  }

  renderComponent = (Component, currentRoute, adminRoute) => props => {
    const {
      [USER_STORE]: { userData },
      t, // t for i18n library
    } = this.props;
    const { loading } = this.state;
    this.setState({ currentRoute });
    console.log(userData?.role);
    if (loading) {
      return <ScreenLoader />;
    }

    if (
      (!userData?.role && currentRoute === 'notFound') ||
      (!userData?.role && currentRoute === 'admin')
    ) {
      return <Redirect to={`/${LOGIN_ROUTE}`} />;
    }

    if (
      ALLOWED_ROLES.includes(userData?.role) &&
      [LOGIN_ROUTE, SIGN_IN_ROUTE].includes(currentRoute)
    ) {
      return <Redirect to={`/admin/${COURSES_ROUTE}`} />;
    }

    if (adminRoute) {
      if (!userData && currentRoute !== SIGN_IN_ROUTE) {
        return <Redirect to="/admin" />;
      }
      if (userData && currentRoute === SIGN_IN_ROUTE) {
        return <Redirect to={`/admin/${COURSES_ROUTE}`} />;
      }
    }

    if (!userData && currentRoute === PROFILE_ROUTE) {
      return (
        <Route
          path="/profile/:userId"
          render={({ match }) => (
            <Redirect to={{ pathname: '/login', state: { userId: match.params.userId } }} />
          )}
        />
      );
    }

    if (userData && currentRoute === LOGIN_ROUTE) {
      return (
        <Route
          path="/login"
          render={({ location: { state } }) => (
            <Redirect to={`/profile/${state?.userId || userData.id}`} />
          )}
        />
      );
    }
    return (
      <>
        {!PUBLIC_ROUTES.includes(currentRoute) && (
          <Helmet>
            <title>{t(`Router/${currentRoute}`)}</title>
          </Helmet>
        )}
        <Component {...props} />
      </>
    );
  };

  renderRouteAdmin = ({ name, screen, path }, index) => {
    return (
      <Route
        exact
        key={`${name}_${index}`}
        path={path}
        component={this.renderComponent(screen, name, true)}
      />
    );
  };

  render() {
    const {
      // eslint-disable-next-line no-unused-vars
      [USER_STORE]: { userData }, // we added unused variable here for component to re render on this property changes
    } = this.props;
    const { currentRoute } = this.state;

    return (
      <MainWrapper>
        {![LOGIN_ROUTE, RECRUITING_ROUTE].includes(currentRoute) && (
          <Header
            currentRoute={currentRoute}
            showProfile={!PUBLIC_ROUTES.includes(currentRoute)}
            stretch={currentRoute !== PROFILE_ROUTE}
          />
        )}
        <BrowserRouter>
          <ContentWrapper>
            {![...PUBLIC_ROUTES, RECRUITING_ROUTE].includes(currentRoute) && (
              <Sidebar activeScreen={currentRoute} />
            )}
            <Switch>
              {ALLOWED_ROLES.includes(userData?.role) && ADMIN_ROUTES.map(this.renderRouteAdmin)}
              {[...ALLOWED_ROLES, 'recruiter'].includes(userData?.role) && (
                <Route
                  exact
                  path="/recruiting"
                  component={this.renderComponent(RecruitingScreen, RECRUITING_ROUTE)}
                />
              )}
              <Route exact path="/">
                <Redirect from="/" to={`/${LOGIN_ROUTE}`} />
              </Route>
              <Route
                exact
                path={`/${PROFILE_ROUTE}/:id`}
                component={this.renderComponent(Profile, PROFILE_ROUTE)}
              />
              <Route
                exact
                path={`/${LOGIN_ROUTE}`}
                component={this.renderComponent(Login, LOGIN_ROUTE)}
              />
              <Route component={this.renderComponent(NotFound, NOT_FOUND_ROUTE)} />
            </Switch>
          </ContentWrapper>
        </BrowserRouter>
        {![LOGIN_ROUTE, RECRUITING_ROUTE].includes(currentRoute) && (
          <Footer stretch={currentRoute !== PROFILE_ROUTE} />
        )}
        <Modal />
      </MainWrapper>
    );
  }
}

export default withTranslation()(Router);
