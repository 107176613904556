// Core
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { graphql } from '@apollo/react-hoc';
import PropTypes from 'prop-types';

// Components
import GradientButton from '../../../components/Buttons/GradientButton';
import ImagePicker from '../../../components/ImagePicker';
import Input from '../../../components/Input';

// GraphQL
import INSERT_COURSE_MUTATION from '../../../gql/mutation/course/insertCourse';

// Other
import { uploadToS3 } from '../../../external/s3';
import compose from '../../../utils/compose';
import bugsnag from '../../../external/bugsnag';

// Constants
import { COURSE_IMAGE_UPLOAD } from '../../../constants';

// Styles and assets
import { ModalContentForm, FormRow } from './styles';

import './i18n';
import { getFieldsToUpdate } from '../../../utils/fieldsToUpdate';

class EditCourseModal extends PureComponent {
  _isMounted = false;

  constructor(props) {
    super(props);

    const {
      courseData: {
        title,
        picture_url,
        picture_url_placeholder,
        picture_url_dark,
        picture_url_placeholder_dark,
      } = { title: { ru: '', en: '' } },
    } = props;

    this.state = {
      title,
      pictureUrl: picture_url,
      pictureUrlPlaceholder: picture_url_placeholder,
      pictureUrlDark: picture_url_dark,
      pictureUrlPlaceholderDark: picture_url_placeholder_dark,
      chosenPicture: undefined,
      chosenPicturePlaceholder: undefined,
      loading: false,
      error: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * Changes input value for provided input name
   * @param {string} name - input name
   */
  handleChangeValue = name => ({ target: { value } }) =>
    this.setState(({ title }) => ({
      title: {
        ...title,
        [name]: value,
      },
      error: null,
    }));

  /**
   * Picks picture for provided picker name
   * @param {string} name - picker name
   */
  handlePictureChosen = name => pic => this.setState({ [name]: pic });

  /**
   * Submits form and creates/updates course
   */
  handleSubmit = async e => {
    e.preventDefault();

    const { t, insertCourseMutation, updateCourseMutation, closeModal, courseData } = this.props;
    const {
      chosenPicture,
      pictureUrl,
      chosenPicturePlaceholder,
      pictureUrlPlaceholder,
      chosenPictureDark,
      pictureUrlDark,
      chosenPicturePlaceholderDark,
      pictureUrlPlaceholderDark,
      title,
    } = this.state;

    if (!Object.values(title).filter(name => name.trim()).length) {
      return this.setState({ error: new Error(t('Error/emptyField')) });
    }
    this.setState({ loading: true });
    try {
      const newCourseData = {
        title,
      };

      if (chosenPicture) {
        newCourseData.picture_url = await uploadToS3(
          chosenPicture,
          pictureUrl,
          COURSE_IMAGE_UPLOAD
        );
      }
      if (chosenPicturePlaceholder) {
        newCourseData.picture_url_placeholder = await uploadToS3(
          chosenPicturePlaceholder,
          pictureUrlPlaceholder,
          COURSE_IMAGE_UPLOAD
        );
      }
      if (chosenPictureDark) {
        newCourseData.picture_url_dark = await uploadToS3(
          chosenPictureDark,
          pictureUrlDark,
          COURSE_IMAGE_UPLOAD
        );
      }
      if (chosenPicturePlaceholderDark) {
        newCourseData.picture_url_placeholder_dark = await uploadToS3(
          chosenPicturePlaceholderDark,
          pictureUrlPlaceholderDark,
          COURSE_IMAGE_UPLOAD
        );
      }
      if (!courseData) {
        // if new course
        await insertCourseMutation([newCourseData]);
      } else if (courseData?.id) {
        const dataToUpdate = getFieldsToUpdate(newCourseData, courseData);
        // if we updating existing course
        if (Object.keys(dataToUpdate).length) {
          await updateCourseMutation(courseData.id, dataToUpdate);
        }
      }

      return closeModal();
    } catch (error) {
      if (this._isMounted) {
        this.setState({ loading: false });
      }
      return bugsnag.notify(error);
    }
  };

  renderImagePicker = ({ loading, textLabel, imageLink, onImageChosen, key }) => (
    <ImagePicker
      disabled={loading}
      textLabel={textLabel}
      imageLink={imageLink}
      onImageChosen={onImageChosen}
      key={key}
    />
  );

  render() {
    const { t } = this.props;
    const {
      loading,
      error,
      title,
      pictureUrl,
      pictureUrlPlaceholder,
      pictureUrlDark,
      pictureUrlPlaceholderDark,
    } = this.state;

    const pickersLight = [
      {
        textLabel: t('NewCourseModal/pictureLabel'),
        imageLink: pictureUrl,
        onImageChosen: this.handlePictureChosen('chosenPicture'),
        key: 'image-picker-light',
      },
      {
        textLabel: t('NewCourseModal/picturePlaceholderLabel'),
        imageLink: pictureUrlPlaceholder,
        onImageChosen: this.handlePictureChosen('chosenPicturePlaceholder'),
        key: 'image-picker-light-placeholder',
      },
    ];

    const pickersDark = [
      {
        textLabel: t('NewCourseModal/pictureLabelDark'),
        imageLink: pictureUrlDark,
        onImageChosen: this.handlePictureChosen('chosenPictureDark'),
        key: 'image-picker-dark',
      },
      {
        textLabel: t('NewCourseModal/picturePlaceholderLabelDark'),
        imageLink: pictureUrlPlaceholderDark,
        onImageChosen: this.handlePictureChosen('chosenPicturePlaceholderDark'),
        key: 'image-picker-dark-placeholder',
      },
    ];

    return (
      <ModalContentForm onSubmit={this.handleSubmit}>
        <Input
          boldLabel
          disabled={loading}
          value={title.ru}
          error={error}
          label={t('NewCourseModal/nameLabel')}
          placeholder={t('NewCourseModal/namePlaceholderRu')}
          onChange={this.handleChangeValue('ru')}
        />
        <FormRow>
          <Input
            boldLabel
            value={title.en}
            disabled={loading}
            placeholder={t('NewCourseModal/namePlaceholderEn')}
            onChange={this.handleChangeValue('en')}
          />
        </FormRow>
        {[
          { pickers: pickersLight, key: 'pickers-light' },
          { pickers: pickersDark, key: 'pickers-dark' },
        ].map(({ pickers, key }) => (
          <FormRow key={key}>{pickers.map(this.renderImagePicker)}</FormRow>
        ))}
        <FormRow>
          <GradientButton loading={loading} width={200}>
            {t('App/save')}
          </GradientButton>
        </FormRow>
      </ModalContentForm>
    );
  }
}

const insertCourseMutate = graphql(INSERT_COURSE_MUTATION, {
  props: ({ mutate }) => ({
    insertCourseMutation: async objects =>
      mutate({
        variables: { objects },
      }),
  }),
  options: {
    refetchQueries: ['Courses'],
  },
});

const enhancer = compose(
  withTranslation(),
  insertCourseMutate
);

EditCourseModal.propTypes = {
  courseData: PropTypes.object,
  t: PropTypes.func, // i18n translate func
  insertCourseMutation: PropTypes.func, // creates new course item
  updateCourseMutation: PropTypes.func, // updates a course item
  closeModal: PropTypes.func, // closes the modal
};

export default enhancer(EditCourseModal);
