/* eslint-disable no-console */
// Core
import { ApolloClient, HttpLink, InMemoryCache } from 'apollo-boost';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import firebase from 'firebase';

const cache = new InMemoryCache();

const authMiddleware = setContext(async (req, { headers }) => {
  if (firebase.auth().currentUser) {
    const token = await firebase.auth().currentUser.getIdToken(true);
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    };
  }

  return {
    headers,
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.warn(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );
  }

  if (networkError) {
    console.warn(`[Network error]: ${networkError}`);
  }
});

const client = new ApolloClient({
  cache,
  link: ApolloLink.from([
    errorLink,
    authMiddleware,
    new HttpLink({
      uri: 'https://hasura.ru.coderslang.com/v1alpha1/graphql',
    }),
  ]),
});

export default client;
