// Core
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { rem } from 'polished';
import { TableCell } from '../../../components/Table';

// Other
import { colors } from '../../../assets/styles';

export const ContentWrapper = styled.div`
  flex: 1;
  padding: 30px;
`;

export const TriangleUp = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid ${colors.white};
  ${({ isOpacity, isUsedNow }) =>
    !isUsedNow &&
    !isOpacity &&
    css`
      opacity: 0.5;
    `}
`;

export const TriangleDown = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid ${colors.white};
  margin-top: 2px;
  ${({ isOpacity, isUsedNow }) =>
    !isUsedNow &&
    isOpacity &&
    css`
      opacity: 0.5;
    `}
`;

export const IconDefault = styled.img`
  margin-right: 5px;
`;

export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const BodySection = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
`;

export const WrapperEmpty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CreateBtnIcon = styled.img`
  width: 11px;
  margin-right: 10px;
`;

export const PageTitle = styled.h1`
  font-weight: 700;
  font-size: ${rem(20)};
  line-height: ${rem(26)};
  color: ${colors.theme1};
`;

export const ThemeBtn = styled.button`
  cursor: pointer;
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }
  &:disabled {
    pointer-events: none;
  }
`;

export const TextNumberTheme = styled.p`
  color: ${colors.theme1};
  font-weight: bold;
`;

export const Link = styled(NavLink)`
  text-decoration: none;
`;

export const TrianglesBlock = styled.div`
  align-self: center;
  margin-left: 5px;
`;

export const StyledTableCell = styled(TableCell)`
  padding: 15px 0;
  position: relative;
  ${({ visibleOverflow }) =>
    visibleOverflow &&
    css`
      overflow: visible;
    `}
`;

export const ExplanationMark = styled.div`
  position: absolute;
  color: ${({ check }) => (check ? 'red' : 'green')};
  font-size: 30px;
  top: 0px;
  left: -20px;
`;
