// Core
import styled from 'styled-components';

export const Wrapper = styled.div`
  flex-direction: row;
  display: flex;
  width: 100%;
  align-items: flex-start;
`;

export const Icon = styled.img`
  width: 24px;
  padding-right: 2px;
`;

export const CopyBtn = styled.button`
  margin: ${({ label }) => (label ? 28 : 16)}px 10px;
  cursor: pointer;
  z-index: 1;
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }
`;
