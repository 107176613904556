import { gql } from 'apollo-boost';

export default gql`
  query Themes($where: theme_bool_exp) {
    theme(where: $where, order_by: { sort_order: asc }) {
      id
      names
      active
      course_id
      sort_order
      picture_url
      picture_url_dark
    }
    theme_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;
