// Core
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/react-hoc';
import moment from 'moment';

// GraphQL
import UPDATE_QUESTION_MUTATION from '../../../gql/mutation/question/updateQuestion';

// Components
import AlertButton from '../../../components/Buttons/AlertButton';
import Input from '../../../components/Input';

// Other
import compose from '../../../utils/compose';
import bugsnag from '../../../external/bugsnag';

// Styles and assets
import { Wrapper, AlertButtonsRow } from './styles';

const DELETE = 'delete';

class DeleteQuestionModal extends PureComponent {
  state = {
    loading: false, // boolean
    commandCode: '', // string must be delete
    error: null, // null | Error
  };

  deleteQuestion = async () => {
    const { updateQuestionMutation, closeModal, question } = this.props;
    this.setState({ loading: true });
    try {
      const { id } = question;

      await updateQuestionMutation(id, { deleted_at: moment() });
      closeModal();
    } catch (error) {
      bugsnag.notify(error);
    }
  };

  /**
   * @description Change value for input and update error value
   */
  handleChange = ({ target: { value } }) => this.setState({ commandCode: value, error: null });

  /**
   * @description Method for delete question
   */
  handleSubmit = () => {
    const { t } = this.props;
    const { commandCode } = this.state;
    const preparationCod = commandCode.trim();

    // if command code is display view error
    if (!preparationCod) {
      this.setState({ error: new Error(t('Error/emptyField')) });
      // if commandCod === DELETE need delete question
    } else if (preparationCod === DELETE) {
      this.deleteQuestion();
      // if command code is not DELETE display error
    } else {
      this.setState({ error: new Error(t('Error/isNotCorrect')) });
    }
  };

  render() {
    const { t, closeModal } = this.props;
    const { loading, error, commandCode } = this.state;
    return (
      <Wrapper>
        <Input
          value={commandCode}
          error={error}
          label={t('Questions/labelConfirmation')}
          onChange={this.handleChange}
        />
        <AlertButtonsRow>
          <AlertButton loading={loading} onClick={this.handleSubmit} text={t('App/yes')} />
          <AlertButton transparent onClick={closeModal} text={t('App/cancel')} />
        </AlertButtonsRow>
      </Wrapper>
    );
  }
}

const updateQuestionMutate = graphql(UPDATE_QUESTION_MUTATION, {
  props: ({ mutate }) => ({
    updateQuestionMutation: async (id, set) =>
      mutate({
        variables: { id, set },
      }),
  }),
  options: {
    refetchQueries: ['Questions'],
  },
});

const enhancer = compose(
  withTranslation(),
  updateQuestionMutate
);

DeleteQuestionModal.propTypes = {
  t: PropTypes.func, // i18n translate func
  updateQuestionMutation: PropTypes.func, // deletes current question
  closeModal: PropTypes.func, // closes the modal
  question: PropTypes.object, // object with cur theme
};

export default enhancer(DeleteQuestionModal);
