/**
 * Formats error message
 * @param {string} t i18n translate func
 * @param {string} existentProvider existent provider name
 * @param {string} connectProvider provider name to connect
 * @returns {string} formatted error message
 */
export const formatErrorText = (t, existentProvider, connectProvider) => {
  const [formattedExistentProvider] = existentProvider.split('.');
  const [formattedConnectProvider] = connectProvider.split('.');
  return t('SignIn/authErrorText', { formattedExistentProvider, formattedConnectProvider });
};
