// Core
import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import Filter from './Filter';
import TableUsers from './TableUsers';
import logoSvg from '../../assets/images/recruiting/logoSvg.svg';
import imageFind from '../../assets/images/recruiting/headerSvg.svg';
import iconLogo from '../../assets/images/recruiting/iconLogo.svg';

// Other
import { USER_STORE } from '../../stores/constants';
import compose from '../../utils/compose';
import { BREAKPOINTS } from './constants';

import {
  Title,
  UserWrapper,
  Header,
  Logo,
  RecruitingWrapper,
  LogoAndUser,
  ImageFind,
  TitleBlock,
  ImageBlock,
  Body,
  TitleAndImageWrapper,
  Tabs,
  Tab,
  LogoWrapper,
  LogoText,
  IconLogo,
  UserName,
  Avatar,
} from './styles';

export const getCurrentSizeScreen = () =>
  Object.keys(BREAKPOINTS).find((key, index, array) => {
    const afterKey = array[index + 1];
    const currentWidth = window.innerWidth;
    if (BREAKPOINTS[afterKey] > currentWidth && BREAKPOINTS[key] <= currentWidth) {
      return true;
    }
    return false;
  }) || 'CIF';

const Recruiting = ({
  [USER_STORE]: {
    userData: { picture_url, name, locale },
  },
  t,
}) => {
  const [sizeScreen, setSizeScreen] = useState(getCurrentSizeScreen());
  const [filters, changeFilters] = useState({
    selectedCourse: [],
    selectedLevel: [],
    selectedActivity: [],
  });
  const [paramTable, changePage] = useState({
    page: 0,
    offset: 0,
    limit: 15,
    orderBy: {
      name: 'asc',
    },
  });

  const handleChangePage = type => value => {
    if (type === 'limit') {
      changePage({
        ...paramTable,
        [type]: value,
        page: 0,
        offset: 0,
      });
    }

    if (type === 'order') {
      const { orderBy } = paramTable;
      changePage({
        ...paramTable,
        orderBy: {
          [value]: orderBy[value] === 'asc' ? 'desc' : 'asc',
        },
        page: 0,
        offset: 0,
      });
    }

    if (type === 'page') {
      changePage({
        ...paramTable,
        offset: paramTable?.limit * value,
        page: value,
      });
    }
  };

  const handleChangeFilter = callBack => {
    changeFilters(callBack);
    changePage({
      page: 0,
      offset: 0,
      limit: 15,
      orderBy: {
        name: 'asc',
      },
    });
  };

  const resize = () => {
    const currentSizeScreen = getCurrentSizeScreen();
    setSizeScreen(currentSizeScreen);
  };

  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);
  return (
    <RecruitingWrapper>
      <Header>
        <LogoAndUser sizeScreen={sizeScreen}>
          <LogoWrapper>
            <Logo src={logoSvg} />
            <LogoText>{t('Recruitin')}</LogoText>
            <IconLogo src={iconLogo} />
          </LogoWrapper>
          <UserWrapper>
            <UserName>{name}</UserName>
            <Avatar src={picture_url} />
          </UserWrapper>
        </LogoAndUser>
        <TitleAndImageWrapper>
          <TitleBlock>
            <Title sizeScreen={sizeScreen}>{t('Recruiting/searchReport')}</Title>
          </TitleBlock>
          <ImageBlock>
            <ImageFind sizeScreen={sizeScreen} src={imageFind} />
          </ImageBlock>
        </TitleAndImageWrapper>
      </Header>
      <Body sizeScreen={sizeScreen}>
        <Tabs>
          <Tab>{t('Recruiting/findTalent')}</Tab>
        </Tabs>
        <Filter
          sizeScreen={sizeScreen}
          locale={locale}
          filters={filters}
          changeFilters={handleChangeFilter}
        />
        <TableUsers
          sizeScreen={sizeScreen}
          filters={filters}
          handleChangePage={handleChangePage}
          {...paramTable}
        />
      </Body>
    </RecruitingWrapper>
  );
};

Recruiting.propTypes = {
  [USER_STORE]: PropTypes.object,
  t: PropTypes.func, // i18n translation func
};

const enhancer = compose(
  inject(USER_STORE),
  observer,
  withTranslation()
);

export default enhancer(Recruiting);
