// Core
import styled, { css } from 'styled-components';
import SimpleMDE from 'react-simplemde-editor';
import { rem } from 'polished';

// Other
// eslint-disable-next-line import/no-extraneous-dependencies
import 'easymde/dist/easymde.min.css';
import { colors } from '../../assets/styles';

export const StyledSimpleMDE = styled(SimpleMDE)`
  font-size: ${rem(14)};
`;

export const Wrapper = styled.div`
  ${({ isCorrectAnswer }) =>
    isCorrectAnswer &&
    css`
      background-color: ${colors.theme4};
    `};
  border-radius: 8px;
`;

export const StyledLabel = styled.label`
  font-weight: ${({ boldLabel }) => (boldLabel ? 700 : 400)};
  font-size: ${rem(12)};
  color: ${colors.theme1};
  width: 100%;
  position: relative;
  & span {
    font-weight: 500;
  }
`;

export const ErrorTitle = styled.p`
  font-size: ${rem(12)};
  color: ${colors.error};
  position: absolute;
  right: 56px;
  bottom: -46px;
`;
