import i18n, { LOCALES } from '../../../external/i18n';

i18n.addResources(LOCALES.RU, 'translation', {
  'CopyAllQuestionModal/buttonTitle': 'Перезаписать',
  'CopyAllQuestionModal/title': 'Внимание, все данные будут перезаписаны',
  'CopyAllQuestionModal/description': 'Вы уверены, что хотите продолжить?',
});

i18n.addResources(LOCALES.EN, 'translation', {
  'CopyAllQuestionModal/buttonTitle': 'Overwrite',
  'CopyAllQuestionModal/title': 'Caution, all data will be overwritten.',
  'CopyAllQuestionModal/description': 'Are you sure you want to continue?',
});

export default i18n;
