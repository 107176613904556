// Core
import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';

// Styles and assets
import arrowLeftIcon from '../../assets/images/arrow/arrow-left.png';
import arrowRightIcon from '../../assets/images/arrow/arrow-right.png';
import { customStyles, Icon } from '../Input/styles';
import { IconArrow, StyledPaginate, Wrapper, WrapperRow, TextSelect, control } from './styles';
import arrowBottom from '../../assets/images/internship-screen/ic-expand-more-24-px.png';

import i18n from './i18n';

const indicatorSeparatorStyle = {
  alignSelf: 'stretch',
  backgroundColor: 'while',
};

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <Icon src={arrowBottom} />
  </components.DropdownIndicator>
);

const ClearIndicator = () => <div />;

const IndicatorSeparator = () => <span style={indicatorSeparatorStyle} />;

const PaginationTheme = ({
  countPage,
  currentPage,
  onClickPage,
  itemsCount,
  itemsPerPage,
  selectProps,
}) => {
  if (itemsCount <= itemsPerPage && countPage === 1 && !selectProps) return null;
  return (
    <Wrapper>
      {(itemsCount >= itemsPerPage || !selectProps) && (
        <StyledPaginate
          pageCount={countPage}
          onPageChange={onClickPage}
          forcePage={currentPage}
          previousLabel={<IconArrow src={arrowLeftIcon} />}
          nextLabel={<IconArrow src={arrowRightIcon} />}
          breakLabel={<p>...</p>}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
        />
      )}
      {selectProps && (
        <WrapperRow>
          <TextSelect>{i18n.t('Pagination/SelectTextStart')}</TextSelect>
          <Select
            menuPlacement="top"
            name="select-paginate"
            classNamePrefix="select"
            styles={{ ...customStyles, control }}
            onChange={selectProps.onChange}
            components={{ IndicatorSeparator, DropdownIndicator, ClearIndicator }}
            options={selectProps.options}
            value={selectProps.defaultValue}
          />
          <TextSelect>{i18n.t('Pagination/SelectTextEnd')}</TextSelect>
        </WrapperRow>
      )}
    </Wrapper>
  );
};

PaginationTheme.propTypes = {
  countPage: PropTypes.number, // number count of Pages
  itemsCount: PropTypes.number, // number count of Items
  onClickPage: PropTypes.func, // open page func
  itemsPerPage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // number count of Items need to show
  currentPage: PropTypes.number, // number current Items
  selectProps: PropTypes.object, // open all items page func
};

export default PaginationTheme;
