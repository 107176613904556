// Core
import styled from 'styled-components';

// Other
import { colors, BREAKPOINTS } from '../../assets/styles';

export const FooterWrapper = styled.header`
  display: flex;
  justify-content: center;
  padding: 5px 15px;
  width: 100%;
  background: linear-gradient(268deg, ${colors.gradient1} 38%, ${colors.gradient2});

  @media (min-width: ${BREAKPOINTS.SMALL}) {
    padding: 5px 30px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  ${({ stretch }) => !stretch && 'max-width: 1140px;'}
`;

export const FooterTitle = styled.h2`
  font-size: 10px;
  line-height: 11px;
  color: ${colors.white};
`;
