// Core
import React from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import PropTypes from 'prop-types';

// Styles and assets
import { LangIcon, ProgressWrapper } from './styles';
import { colors } from '../../assets/styles';

import 'react-circular-progressbar/dist/styles.css';

const CircularLangProgress = ({ progress, maxValue, courseImg, onlyIcon }) => {
  if (onlyIcon) {
    return <LangIcon src={courseImg} alt="lang icon" />;
  }
  return (
    <ProgressWrapper>
      <CircularProgressbarWithChildren
        strokeWidth={3}
        value={progress}
        maxValue={maxValue}
        styles={buildStyles({
          pathColor: colors.progressGreen,
          trailColor: colors.grey1,
        })}
      >
        <LangIcon src={courseImg} alt="lang icon" />
      </CircularProgressbarWithChildren>
    </ProgressWrapper>
  );
};

CircularLangProgress.propTypes = {
  onlyIcon: PropTypes.bool, // instead of progress icon return only lang icon
  courseImg: PropTypes.string, // course img url
  maxValue: PropTypes.number, // max value of progress
  progress: PropTypes.number, // progress float number (example: 0.68)
};

export default CircularLangProgress;
