// Core
import React from 'react';
import PropTypes from 'prop-types';

// Styles and assets
import { ProgressContainer, DescriptionText } from './styles';
import { BlockUserStatistic, BlockTitle } from '../styles';

const Progress = ({ isPremiumUser, children, title, flexCount, t }) => {
  return (
    <BlockUserStatistic flexCount={flexCount} isBlur={!isPremiumUser}>
      {!isPremiumUser && <DescriptionText>{t('Profile/descriptionPremium')}</DescriptionText>}
      <ProgressContainer isBlur={!isPremiumUser}>
        <BlockTitle>{title}</BlockTitle>
        {children}
      </ProgressContainer>
    </BlockUserStatistic>
  );
};

Progress.propTypes = {
  isPremiumUser: PropTypes.bool, // user's premium status
  children: PropTypes.any,
  flexCount: PropTypes.number,
  title: PropTypes.string,
  t: PropTypes.func, // i18n translation func
};

export default Progress;
