import { gql } from 'apollo-boost';

export default gql`
  query Internships {
    internship(order_by: { id: asc }) {
      id
      interns_count
      created_at
      knowledge_level
      name
      programming_langs
      duration
    }
  }
`;
