// Core
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { rem } from 'polished';

// Other
import { colors } from '../../assets/styles';

export const ContentWrapper = styled.div`
  flex: 1;
  padding: 30px;
`;

export const PageTitle = styled.h1`
  font-size: ${rem(20)};
  font-weight: bolder;
  line-height: 26px;
  color: ${colors.theme1};
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CreateBtnIcon = styled.img`
  width: 11px;
  margin-right: 5px;
`;

export const CourseItem = styled.div`
  width: 168px;
  height: 168px;
  border-radius: 17px;
  ${({ active }) =>
    active &&
    css`
      box-shadow: 0 1px 8px 0 ${colors.theme4};
    `};
  border: solid 1px ${({ active }) => (active ? colors.theme3 : colors.grey1)};
  margin-top: 17px;
  margin-right: 15px;
  padding: 25px;
  cursor: pointer;
  display: inline-block;
  position: relative;

  transition: transform 200ms;

  &:hover {
    transform: scale(1.02);
  }

  &:active {
    border: 2px solid ${({ active }) => (active ? colors.theme1 : colors.grey7)};
    background-color: ${({ active }) => (active ? colors.theme4 : colors.grey1)};
  }
`;

export const CourseIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const CourseBtn = styled.button`
  cursor: pointer;
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }
`;

export const CourseBtnIcon = styled.img`
  width: ${({ width }) => width || '12px'};
  margin-left: 5px;
`;

export const CourseBtnsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 10px;
`;
