import i18n, { LOCALES } from '../../external/i18n';

i18n.addResources(LOCALES.RU, 'translation', {
  'News/Add/title': 'Новости',
  'News/Add/newOne': 'Новая новость',
  'News/Add/save': 'Сохранить',
  'News/Add/name': 'Название:',
  'News/Add/namePlaceholder': 'Напишите название новости',
  'News/Add/description': 'Описание:',
  'News/Add/descriptionPlaceholder': 'Добавьте описание новости',
  'News/Add/preview': 'Превью:',
  'News/Add/previewPlaceholder': 'Вставьте ссылку',
  'News/Add/photoUpload': 'Загрузите фото',
  'News/Add/notifyUsers': 'Создайте рассылку',
  'News/Add/sendPush': 'Отправить уведомление',
});

i18n.addResources(LOCALES.EN, 'translation', {
  'News/Add/title': 'News',
  'News/Add/newOne': 'Create new one',
  'News/Add/save': 'Save',
  'News/Add/name': 'Title:',
  'News/Add/namePlaceholder': 'Type the name of the news',
  'News/Add/description': 'Description:',
  'News/Add/descriptionPlaceholder': 'Add description to the news',
  'News/Add/preview': 'Preview:',
  'News/Add/previewPlaceholder': 'Paste the link',
  'News/Add/photoUpload': 'Photo upload',
  'News/Add/notifyUsers': 'Notify users',
  'News/Add/sendPush': 'Send a notification',
});
