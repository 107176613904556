export const units = [
  'day',
  'week',
  'month',
  'year',
  'minute',
  'hour',
  'second',
  'wrong',
  'right',
  'answer',
];
