// Core
import styled from 'styled-components';

// Components
import { AlertBtn } from '../../../components/Buttons/AlertButton/styles';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 24px;
  ${AlertBtn} {
    margin-top: 24px;
  }
`;

export const AlertButtonsRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 24px;
  ${AlertBtn} {
    margin-top: 24px;
  }
`;
