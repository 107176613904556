// Core
import styled from 'styled-components';
import { rem } from 'polished';

// Other
import { colors } from '../../../assets/styles';

export const AlertBtnsRow = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  margin-top: 36px;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h1`
  font-weight: bold;
  font-size: ${rem(12)};
  line-height: ${rem(24)};
  color: ${colors.theme1};
`;

export const Description = styled.h2`
  font-size: ${rem(12)};
  line-height: ${rem(18)};
  color: ${colors.theme5};
  margin-top: 20px;
`;

export const TextCancel = styled.h2`
  font-size: ${rem(12)};
  line-height: ${rem(13)};
  color: ${colors.grey7};
`;
