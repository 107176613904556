import * as firebase from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyAC7ALPWnI2FPXwBS6xu4QloY63qNOdK2U',
  authDomain: 'pro1-2ce55.firebaseapp.com',
  databaseURL: 'https://pro1-2ce55.firebaseio.com',
  projectId: 'pro1-2ce55',
  storageBucket: 'pro1-2ce55.appspot.com',
  messagingSenderId: '876452002931',
  appId: '1:876452002931:web:cfdf71c05387997d',
};

firebase.initializeApp(firebaseConfig);

export default firebase;
