// Core
import React from 'react';
import PropTypes from 'prop-types';
import ClipLoader from 'react-spinners/ClipLoader';

// Styles and assets
import { AlertBtn } from './styles';
import { colors } from '../../../assets/styles';

const AlertButton = ({ text, loading, disabled, width, onClick, transparent }) => (
  <AlertBtn
    transparent={transparent}
    onClick={onClick}
    width={width}
    disabled={loading || disabled}
  >
    {loading ? <ClipLoader size={26} color={transparent ? colors.theme1 : colors.white} /> : text}
  </AlertBtn>
);

AlertButton.propTypes = {
  transparent: PropTypes.bool, // is btn transparent
  disabled: PropTypes.bool, // is btn disabled
  loading: PropTypes.bool, // show loader
  text: PropTypes.string, // btn text
  width: PropTypes.number, // custom width value
  onClick: PropTypes.func, // btn onclick handler
};

export default AlertButton;
