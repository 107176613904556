// Core
import { observable, action, runInAction } from 'mobx';

// Other
import bugsnag from '../external/bugsnag';
import { ITEMS_PER_PAGE, MODAL_CLOSE_TIMEOUT, THEME_LIST } from '../constants';

/**
 * App store
 */
class AppStore {
  constructor() {
    bugsnag.showModal = this.showModal;
  }

  /**
   * How many questions to show on one page
   */
  @observable countQuestionsShow = { currentPage: 0, value: ITEMS_PER_PAGE, label: ITEMS_PER_PAGE };

  /**
   * Change count
   * @param {string} [value] selected quantity value,
   */
  @action changeCountQuestionsShow = value => {
    this.countQuestionsShow = { ...this.countQuestionsShow, ...value };
  };

  @action changePage = page => {
    this.countQuestionsShow = { ...this.countQuestionsShow, currentPage: page.selected };
  };

  /**
   * Is modal component shown
   */
  @observable modalShown = false;

  @observable currentThemeTab = THEME_LIST;

  /**
   * Modal data
   * @param {string} [type] modal type,
   * @param {Element} content modal inner content,
   * @param {string} size ['small', 'medium', 'large']
   */
  @observable modalData = undefined;

  /**
   * Modal inner content
   */
  @observable modalContent = undefined;

  /**
   * Shows modal dialog
   */
  @action showModal = modalData => {
    this.modalShown = true;
    this.modalData = modalData;
  };

  @action handleChangeTab = type => () => {
    this.currentThemeTab = type;
  };

  /**
   * Clears store data
   */
  @action closeModal = async () => {
    this.modalShown = false;
    return new Promise(res =>
      setTimeout(() => {
        runInAction(() => {
          this.modalData = undefined;
          res();
        });
      }, MODAL_CLOSE_TIMEOUT)
    );
  };
}

const appStore = new AppStore();

export default appStore;
