// Core
import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { Provider } from 'mobx-react';

// Components
import Router from './components/Router';

// Other
import client from './external/apollo';
import bugsnagClient from './external/bugsnag';
import stores from './stores';

// Styles
import GlobalStyles from './assets/styles/globalStyles';

// include localization files to the bundle
import './external/i18n';

// wrap your entire app tree in the ErrorBoundary provided
const ErrorBoundary = bugsnagClient.client.getPlugin('react');

function App() {
  return (
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <GlobalStyles />
        <Provider {...stores}>
          <Router />
        </Provider>
      </ApolloProvider>
    </ErrorBoundary>
  );
}

export default App;
