// Core
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// Styles and assets
import { FooterWrapper, ContentWrapper, FooterTitle } from './styles';

import './i18n';

const Footer = ({ stretch, t }) => {
  return (
    <FooterWrapper>
      <ContentWrapper stretch={stretch}>
        <FooterTitle>© 2019. {t('Footer/title')}</FooterTitle>
      </ContentWrapper>
    </FooterWrapper>
  );
};

Footer.defaultProps = {
  stretch: true,
};

Footer.propTypes = {
  stretch: PropTypes.bool, // is header stretch in window or has max-width: 1140 px for content
  t: PropTypes.func, // i18n translation func
};

export default withTranslation()(Footer);
