// Core
import React, { PureComponent } from 'react';
import { graphql } from '@apollo/react-hoc';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import moment from 'moment';

// Components
import ScreenLoader from '../../components/ScreenLoader';
import Pagination from '../../components/Pagination';

// Mobx
import { APP_STORE } from '../../stores/constants';

// GraphQL
import USER_QUESTION from '../../gql/query/userQuestion';

// Other
import { ITEMS_PER_PAGE, COURSES_ROUTE, EDIT_ROUTE } from '../../constants';
import { gridColumns } from './helpers';
import compose from '../../utils/compose';

// Styles and assets
import {
  ContentWrapper,
  PageTitle,
  HeaderRow,
  Icon,
  LinkAndButton,
  ButtonsTd,
  Link,
  StyledTableCell,
} from './styles';
import { Table, Thead, TableHeadCell, Tbody, TableRow } from '../../components/Table';

import editIcon from '../../assets/images/courses/pencil-active.png';

import './i18n';

class UserQuestion extends PureComponent {
  handlePageChange = page => {
    const { refetch } = this.props;
    refetch({ limit: ITEMS_PER_PAGE, offset: page.selected * ITEMS_PER_PAGE });
  };

  handleChangeSelector = value => {
    const {
      [APP_STORE]: { changePage, changeCountQuestionsShow },
    } = this.props;
    changePage(0);
    changeCountQuestionsShow(value);
  };

  renderListItem = item => {
    const {
      details: { description },
      app_user: { name },
      id,
      course: { title, id: courseId },
      theme: { names, id: themeId },
      created_at,
    } = item;
    return (
      <TableRow key={`${name}_${id}`}>
        <StyledTableCell>{id}</StyledTableCell>
        <StyledTableCell>{title}</StyledTableCell>
        <StyledTableCell>{names}</StyledTableCell>
        <StyledTableCell>{description}</StyledTableCell>
        <StyledTableCell>{name}</StyledTableCell>
        <StyledTableCell isViolet>{moment(created_at).format('DD.MM.YYYY')}</StyledTableCell>
        <ButtonsTd>
          <LinkAndButton>
            <Link to={`/admin/${COURSES_ROUTE}/${courseId}/${themeId}/${id}/${EDIT_ROUTE}`}>
              <Icon src={editIcon} />
            </Link>
          </LinkAndButton>
        </ButtonsTd>
      </TableRow>
    );
  };

  render() {
    const {
      t,
      questions = [],
      [APP_STORE]: {
        countQuestionsShow: { value, currentPage },
        countQuestionsShow,
        changePage,
      },
      loading,
      questionCount,
    } = this.props;
    const namesOfCells = [
      t('UserQuestion/ID'),
      t('UserQuestion/course'),
      t('UserQuestion/theme'),
      t('UserQuestion/description'),
      t('UserQuestion/author'),
      t('UserQuestion/Date'),
    ];
    if (loading) {
      return <ScreenLoader />;
    }
    const countPage = Math.ceil(questionCount / value);
    const SELECT_OPTIONS = [
      { value: 10, label: '10' },
      { value: ITEMS_PER_PAGE, label: ITEMS_PER_PAGE },
      { value: 50, label: '50' },
      { value: 100, label: '100' },
      { value: questionCount, label: 'ALL' },
    ];

    return (
      <ContentWrapper>
        <HeaderRow>
          <PageTitle>{t('UserQuestion/title')}</PageTitle>
        </HeaderRow>
        <Table gridColumns={gridColumns}>
          <Thead>
            <TableRow>
              {namesOfCells.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableHeadCell key={`cell-${index}`}>{item}</TableHeadCell>
              ))}
              <TableHeadCell />
            </TableRow>
          </Thead>
          <Tbody>{questions.map(this.renderListItem)}</Tbody>
        </Table>
        {!loading && (
          <Pagination
            countPage={countPage}
            itemsPerPage={value}
            itemsCount={questionCount}
            onClickPage={changePage}
            currentPage={currentPage}
            selectProps={{
              options: SELECT_OPTIONS,
              onChange: this.handleChangeSelector,
              defaultValue: countQuestionsShow,
            }}
          />
        )}
      </ContentWrapper>
    );
  }
}

const questionQuery = graphql(USER_QUESTION, {
  props: ({ data: { questions, loading, question_aggregate } }) => ({
    questions,
    loading,
    questionCount: question_aggregate?.aggregate?.count,
  }),
  options: ({
    [APP_STORE]: {
      countQuestionsShow: { currentPage, value },
    },
  }) => {
    return {
      variables: {
        limit: value,
        offset: value * currentPage,
      },
    };
  },
});

const enhancer = compose(
  inject(APP_STORE),
  observer,
  questionQuery,
  withTranslation()
);

UserQuestion.propTypes = {
  refetch: PropTypes.func,
  loading: PropTypes.bool,
  questions: PropTypes.arrayOf(PropTypes.object),
  t: PropTypes.func,
};

export default enhancer(UserQuestion);
