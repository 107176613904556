// Core
import styled from 'styled-components';

// Other
import { colors, BREAKPOINTS } from '../../assets/styles';

const getSize = defaultSize => ({ size }) => size || defaultSize;

export const AvatarWrapper = styled.div`
  width: ${getSize(65)}px;
  height: ${getSize(65)}px;
  min-width: ${getSize(65)}px;
  min-height: ${getSize(65)}px;
  background-color: ${colors.white};
  border: ${({ borderColor, size, isBorder }) =>
    isBorder && `${size && size > 60 ? 2 : 1}px solid ${borderColor || colors.theme1}`};
  border-radius: ${({ borderRadius }) => (borderRadius ? `${borderRadius}px` : '50%')};
  overflow: hidden;
  box-shadow: 0 3px 6px 0 ${colors.whiteOpacity15};

  @media (min-width: ${BREAKPOINTS.SMALL}) {
    width: ${getSize(86)}px;
    height: ${getSize(86)}px;
    min-width: ${getSize(86)}px;
    min-height: ${getSize(86)}px;
  }
`;

export const AvatarImg = styled.img`
  height: 100%;
  width: 100%;
  object-fit: ${({ isPlaceholder }) => (isPlaceholder ? 'contain' : 'cover')};
  ${({ isPlaceholder }) => isPlaceholder && 'margin-top: 10%;'}
`;
