// Core
import React, { Component } from 'react';
import Select, { components } from 'react-select';
import { graphql } from '@apollo/react-hoc';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';

// Components
import ScreenLoader from '../../../components/ScreenLoader';
import Input from '../../../components/Input';
import GradientButton from '../../../components/Buttons/GradientButton';

// GraphQL
import UPDATE_INTERNSHIP_MUTATION from '../../../gql/mutation/internship/updateInternship';
import INTERNSHIP_QUERY from '../../../gql/query/internshipById';
import INSERT_INTERNSHIP_MUTATION from '../../../gql/mutation/internship/insertInternship';

// Other
import '../i18n';
import compose from '../../../utils/compose';
import { validation, getUnitsOptions } from './helpers';
import bugsnag from '../../../external/bugsnag';
import client from '../../../external/apollo';

// Styles and assets
import {
  ContentWrapper,
  FormArea,
  Icon,
  customStyles,
  ScreenName,
  HeaderSection,
  ScreenNameWrapper,
  FormText,
  IconButton,
} from './styles';

import arrowBackIcon from '../../../assets/images/arrow/arrow-back.png';
import arrowBottom from '../../../assets/images/internship-screen/ic-expand-more-24-px.png';

const knowledgeOptions = [
  { value: '0', label: 'Trainee' },
  { value: '1', label: 'Junior' },
  { value: '2', label: 'Middle' },
  { value: '3', label: 'Senior' },
];

const UNITS = [
  { value: 1, units: 'day' },
  { value: 2, units: 'week' },
  { value: 3, units: 'month' },
  { value: 4, units: 'year' },
];

// drop to styles
const indicatorSeparatorStyle = {
  alignSelf: 'stretch',
  backgroundColor: 'while',
};

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <Icon src={arrowBottom} />
  </components.DropdownIndicator>
);

const ClearIndicator = () => <div />;

const IndicatorSeparator = () => <span style={indicatorSeparatorStyle} />;

const SaveButton = withRouter(({ t, history, onClickFunction, isDisabled }) => {
  const handleSaveChanges = async () => {
    const status = await onClickFunction();
    if (status) {
      history.push('/admin/internship');
    }
  };

  return (
    <GradientButton disabled={isDisabled} type="button" onClick={handleSaveChanges} height={40}>
      {t('InternshipUpdate/Save')}
    </GradientButton>
  );
});

const INITIAL_SHIP = {
  projectName: '',
  durationOfProject: { unitsCount: '', units: '' },
  amountOfPersons: { quantity: '' },
  progLangs: [],
  knowledgeLevel: [],
};

const INITIAL_ERRORS = {
  projectName: null,
  durationOfProjectFrom: null,
  durationOfProjectUnits: null,
  amountOfPersons: null,
  progLangs: null,
  knowledgeLevel: null,
};

class InternshipPage extends Component {
  _isMount = false;

  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_SHIP,
      languageOptions: [],
      loading: false,
      errors: INITIAL_ERRORS,
    };
  }

  componentDidMount() {
    this._isMount = true;
    this.fetchTest();
  }

  componentWillUnmount() {
    this._isMount = false;
  }

  fetchTest = async () => {
    const {
      match: {
        params: { id },
      },
      i18n: { language },
      t,
    } = this.props;

    this.setState({ loading: true });

    const {
      data: { internship, courses },
    } = await client.query({
      query: INTERNSHIP_QUERY,
      variables: {
        where: {
          id: {
            _eq: id,
          },
        },
      },
    });

    const languageOptions = courses?.map(item => {
      return { value: item.id, label: item.title[language] };
    });

    let newState = { languageOptions, loading: false };

    if (id && internship.length) {
      const { name, programming_langs, knowledge_level, interns_count, duration } = internship[0];

      newState = {
        ...newState,
        progLangs: languageOptions?.filter(({ value }) => programming_langs?.includes(value)),
        knowledgeLevel: knowledgeOptions?.filter(({ value }) => knowledge_level?.includes(value)),
        projectName: name,
        amountOfPersons: { quantity: interns_count?.quantity || '' },
        durationOfProject: {
          unitsCount: duration.unitsCount,
          units: getUnitsOptions(
            UNITS.filter(({ units }) => units === duration.units),
            duration.unitsCount,
            t
          ),
        },
      };
    }

    if (this._isMount) {
      this.setState({ ...newState });
    }
  };

  /**
   * @param {string} key
   * @param {object} newValue
   * @description For change select values
   */
  handleChange = key => value => {
    const { [key]: oldValue } = this.state;

    let newValue = { [key]: value };

    if (key === 'durationOfProject') {
      newValue = {
        [key]: {
          ...oldValue,
          units: [value],
        },
      };
    }
    this.setState({ ...newValue, errors: INITIAL_ERRORS });
  };

  /**
   * Changes input value for provided input name
   * @param {string} name - input name
   */
  handleChangeValue = key => ({ target: { value } }) => {
    const { [key]: oldValue } = this.state;
    let newValue = { [key]: value };

    if (key === 'durationOfProject') {
      newValue = {
        [key]: {
          ...oldValue,
          unitsCount: value,
        },
      };
    }

    if (key === 'amountOfPersons') {
      newValue = {
        [key]: {
          quantity: value,
        },
      };
    }

    this.setState({ ...newValue, errors: INITIAL_ERRORS });
  };

  goBack = () => {
    const { history } = this.props;
    return history.goBack;
  };

  insertTheDataToDB = async () => {
    const {
      progLangs,
      knowledgeLevel,
      projectName,
      amountOfPersons,
      durationOfProject,
      errors,
    } = this.state;
    const {
      updateInternshipMutation,
      insertInternshipMutation,
      t,
      match: {
        params: { id: internshipCourseId },
      },
    } = this.props;

    const dataToUpdate = {
      name: projectName,

      // TODO: field from for duration must be delete later
      duration: {
        from: '',
        unitsCount: durationOfProject.unitsCount,
        units: durationOfProject.units[0]?.units,
      },
      // TODO: field from for interns_count must be delete later
      interns_count: { from: '', quantity: amountOfPersons.quantity },
      knowledge_level: knowledgeLevel.map(item => (isString(item) ? item : item.value)),
      programming_langs: progLangs.map(item => (isNumber(item) ? item : item.value)),
    };

    if (Object.values(validation(dataToUpdate, t)).length) {
      this.setState({ errors: { ...errors, ...validation(dataToUpdate, t) } });
      return false;
    }

    try {
      if (internshipCourseId) {
        return await updateInternshipMutation(internshipCourseId, dataToUpdate);
      }
      return await insertInternshipMutation([dataToUpdate]);
    } catch (error) {
      return bugsnag.notify(error);
    }
  };

  render() {
    const { t, loading: loadingCourse } = this.props;

    const {
      progLangs,
      knowledgeLevel,
      projectName,
      amountOfPersons,
      durationOfProject,
      loading,
      errors,
      languageOptions,
    } = this.state;

    return (
      <ContentWrapper>
        <HeaderSection>
          <ScreenNameWrapper>
            <IconButton onClick={this.goBack()}>
              <Icon src={arrowBackIcon} />
            </IconButton>
            <ScreenName>{t('InternshipUpdate/InternshipNewProject')}</ScreenName>
          </ScreenNameWrapper>
          <SaveButton
            isDisabled={loading || loadingCourse}
            t={t}
            onClickFunction={this.insertTheDataToDB}
          />
        </HeaderSection>
        {!loading || !loadingCourse ? (
          <FormArea>
            <Input
              boldLabel
              disabled={loading}
              error={errors.projectName}
              value={projectName}
              label={t('InternshipUpdate/Project')}
              placeholder={t('InternshipUpdate/NameProject')}
              onChange={this.handleChangeValue('projectName')}
            />
            <FormText>{t('InternshipUpdate/ProgrammingLanguages')}</FormText>
            <Select
              isMulti
              isClearable
              isSearchable
              name="programming-languages"
              classNamePrefix="select"
              className="basic-multi-select"
              styles={customStyles}
              error={errors.progLangs}
              onChange={this.handleChange('progLangs')}
              components={{ IndicatorSeparator, DropdownIndicator, ClearIndicator }}
              options={languageOptions}
              value={progLangs}
              placeholder={t('InternshipUpdate/ChooseLanguage')}
            />
            <FormText>{t('InternshipUpdate/KnowledgeLevel')}</FormText>
            <Select
              isMulti
              isClearable
              isSearchable
              name="knowledge"
              classNamePrefix="select"
              error={errors.knowledgeLevel}
              className="basic-multi-select"
              styles={customStyles}
              onChange={this.handleChange('knowledgeLevel')}
              components={{ IndicatorSeparator, DropdownIndicator, ClearIndicator }}
              options={knowledgeOptions}
              value={knowledgeLevel}
              placeholder={t('InternshipUpdate/ChooseLevelKnowledge')}
            />
            <FormText />
            <Input
              boldLabel
              disabled={loading}
              error={errors.amountOfPersons}
              value={amountOfPersons.quantity}
              label={t('InternshipUpdate/InternsCount')}
              placeholder={t('InternshipUpdate/WriteNumberPeople')}
              onChange={this.handleChangeValue('amountOfPersons')}
            />
            <FormText />
            <Input
              boldLabel
              disabled={loading}
              error={errors.durationOfProjectFrom}
              value={durationOfProject.unitsCount}
              label={t('InternshipUpdate/Duration')}
              placeholder={t('InternshipUpdate/WriteDurationProject')}
              onChange={this.handleChangeValue('durationOfProject')}
              selectProps={{
                onChange: this.handleChange('durationOfProject'),
                options: getUnitsOptions(UNITS, durationOfProject.unitsCount, t),
                error: errors.durationOfProjectUnits,
                defaultValue: getUnitsOptions(
                  durationOfProject.units,
                  durationOfProject.unitsCount,
                  t
                )[0],
              }}
            />
          </FormArea>
        ) : (
          <ScreenLoader />
        )}
      </ContentWrapper>
    );
  }
}

const updateInternshipMutate = graphql(UPDATE_INTERNSHIP_MUTATION, {
  props: ({ mutate }) => ({
    updateInternshipMutation: async (id, set) =>
      mutate({
        variables: { id, set },
      }),
  }),
  options: {
    refetchQueries: ['Internships'],
  },
});

const insertInternshipMutate = graphql(INSERT_INTERNSHIP_MUTATION, {
  props: ({ mutate }) => ({
    insertInternshipMutation: async objects =>
      mutate({
        variables: { objects },
      }),
  }),
  options: {
    refetchQueries: ['Internships'],
  },
});

InternshipPage.propTypes = {
  match: PropTypes.object,
  i18n: PropTypes.object,
  loading: PropTypes.bool, // is profile data loading
  history: PropTypes.object,
  t: PropTypes.func, // i18n translation func
  updateInternshipMutation: PropTypes.func, // updates internship item
  insertInternshipMutation: PropTypes.func, // updates internship item
};

const enhancer = compose(
  withTranslation(),
  updateInternshipMutate,
  insertInternshipMutate
);

export default enhancer(InternshipPage);
